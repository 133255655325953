module.exports = ['$urlRouterProvider', '$stateProvider',
  function ($urlRouterProvider, $stateProvider) {
    'use strict';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('schoolList', {
        url: '/?search&page',
        controller: 'schoolListController',
        templateUrl: 'js/screen/schoolList/schoolListTemplate.html',
        reloadOnSearch: false
      })
      .state('schoolList.school', {
        url: 'school/:id',
        controller: ['$scope', '$aside', '$state', function ($scope, $aside, $state) {
          $aside.open({
            templateUrl: 'js/screen/school/schoolTemplate.html',
            controller: 'schoolController',
            placement: 'right',
            size: 'lg'
          }).result.then(function (data) {
            $state.go('schoolList', {
              page: data !== false ? null : $state.params.page
            }, {
              reload: data !== false, inherit: true
            });
          }, function (data) {
            $state.go('schoolList', {}, {
              reload: data !== false, inherit: true
            });
          });
        }],
        reloadOnSearch: false
      });
  }];
