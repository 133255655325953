module.exports = {
  angularModuleName: 'Samenscholing',
  projectName: 'Samenscholing',
  environment: 'prod',
  apisAndUrls: {
    samenscholingApi: 'https://api.samenscholing.be'
  },
  logRocket: {
    enabled: true,
    token: 'samenscholing'
  },

  paginationSettings: {
    itemsPerPage: 10,
    itemsPerModal: 20
  },

  logging: {
    logentries: {
      enabled: false,
      debug: false,
      token: ''
    }
  },
  oauth: {
    oauthURL: "https://oauth.samenscholing.be",
    me: "https://oauth.samenscholing.be/me",
    login: "https://oauth.samenscholing.be/katholiekonderwijs_vlaanderen/",
    logOut: "https://oauth.samenscholing.be/logout",
    clientID: 'samenscholing-ui',
    scope: 'vsko',
    authenticate: "https://oauth.samenscholing.be/authenticate",
    redirectUri: "https://oauth.samenscholing.be/redirect"
  },
};
