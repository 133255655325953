module.exports = ['dateUtils', (dateUtils) => {
  'use strict';

  return (resources, period) => {

    // if resources is empty then no filter applied
    if (!resources) {
      return resources;
    }

    return resources.filter(resource => dateUtils.isCovering(period, resource));
  };
}];
