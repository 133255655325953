const epdUtils = require('@kathondvla/sam-utils/epd-utils');
const dateUtils = require('@kathondvla/sri-client/date-utils');
const commonUtils = require('@kathondvla/sri-client/common-utils');

const syncMsRelsWithEpds = (currentEpds, currentMsRels, batch) => {
  const agMap = Object.fromEntries(currentEpds.map((epd) => [epd.ag.href, epd.ag.$$expanded]));
  const expectedMsRels = epdUtils.getCorrespondingRelationsToMainstructures(currentEpds, agMap);

  expectedMsRels.forEach((expectedMsRel) => {
    const existingMsRels = currentMsRels.filter((existingMsRel) =>
      existingMsRel.mainstructure.href === expectedMsRel.mainstructureHref
      && dateUtils.isOverlapping(existingMsRel, expectedMsRel)
    );
    if (existingMsRels.length === 0) {
      const newMsRel = {
        key: commonUtils.generateUUID(),
        organisationalUnit: { href: currentEpds[0].organisationalUnit.href },
        mainstructure: { href: expectedMsRel.mainstructureHref },
        startDate: expectedMsRel.startDate,
        endDate: expectedMsRel.endDate
      }
      console.log(`[ms rels sync] create new ms rel`, newMsRel);
      batch.push({
        href: `/sam/educationalprogrammedetails/${newMsRel.key}`,
        verb: 'PUT',
        body: newMsRel
      });
    } else if (existingMsRels.length === 1) {
      const existingMsRel = existingMsRels[0];
      if (existingMsRel.startDate !== expectedMsRel.startDate || existingMsRel.endDate !== expectedMsRel.endDate) {
        console.log(`[ms rels sync] change period existing msRel from [${existingMsRel.startDate} - ${existingMsRel.endDate}] to [${expectedMsRel.startDate} - ${expectedMsRel.endDate}]`, existingMsRel);
        batch.push({
          href: `/sam/educationalprogrammedetails/${existingMsRel.key}`,
          verb: 'PUT',
          body: {
            ...existingMsRel,
            startDate: expectedMsRel.startDate,
            endDate: expectedMsRel.endDate
          }
        });
      }
    } else {
      // merge current ones to one rel
      const remainingMsRel = existingMsRels.reduce((lastMsRel, existingMsRel) => existingMsRel.startDate > lastMsRel.startDate ? existingMsRel : lastMsRel);
      console.log(`[ms rels sync] merge multiple ms rels into one msRel with period [${expectedMsRel.startDate} - ${expectedMsRel.endDate}]`, existingMsRels);
      batch.push({
        href: `/sam/educationalprogrammedetails/${remainingMsRel.key}`,
        verb: 'PUT',
        body: {
          ...remainingMsRel,
          startDate: expectedMsRel.startDate,
          endDate: expectedMsRel.endDate
        }
      });
      existingMsRels
        .filter((existingMsRel) => existingMsRel.key !== remainingMsRel.key)
        .forEach((mergedMsRel) => {
          batch.push({
            verb: 'DELETE',
            href: mergedMsRel.$$meta.permalink
          });
        });
    }
  });

  const msRelsToDelete = currentMsRels.filter((existingMsRel) =>
    !expectedMsRels.some((expectedMsRel) =>
      existingMsRel.mainstructure.href === expectedMsRel.mainstructureHref
      && dateUtils.isOverlapping(existingMsRel, expectedMsRel)
    )
  );
  msRelsToDelete.forEach((msRelToDelete) => {
    console.log(`[ms rels sync] There are mainstructure relations which need to be deleted`, msRelToDelete);
    batch.push({
      verb: 'DELETE',
      href: msRelToDelete.$$meta.permalink
    });
  });
};

module.exports = {
  syncMsRelsWithEpds
};
