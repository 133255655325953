module.exports = ['apiQueries', 'utils', 'dateUtils', 'pAll',
  (apiQueries, utils, dateUtils, pAll) => {
    'use strict';

    let that = {},
      startDateOfPreviousSchoolYear = dateUtils.getPreviousYear(dateUtils.getStartOfSchoolYear(dateUtils.getNow()));

    that.getSchools = async () => {

      const schools = await apiQueries.getOrganisationalUnits({
        type: 'SCHOOL'
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return schools;
    };

    that.getGoverningInstitutions = async (cache) => {

      const governingInstitutions = await apiQueries.getOrganisationalUnits({
        type: 'GOVERNINGINSTITUTION'
      }, {
        caching: {
          timeout: cache ? apiQueries.ONE_DAY : 0
        }
      });

      return governingInstitutions;
    };

    that.getCLBs = async () => {

      const clbs = await apiQueries.getOrganisationalUnits({
        type: 'CLB'
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return clbs;
    };

    that.getSchoolLocations = async () => {

      const schoolLocations = await apiQueries.getOrganisationalUnitsLocations({
        'organisationalUnit.type': 'SCHOOL'
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return schoolLocations;
    };

    that.getOUExternalIdentifiers = async () => {

      const schoolExternalIdentifiers = await apiQueries.getOrganisationalUnitsExternalIdentifiers({
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return schoolExternalIdentifiers;
    };

    that.getOfficialOffers = async () => {

      const officialOffers = await apiQueries.getEducationalProgrammeDetails({
        'organisationalUnit.type': 'SCHOOL',
        /*expand: 'SUMMARY',*/
        startDateBefore: startDateOfPreviousSchoolYear,
        endDateAfter: startDateOfPreviousSchoolYear
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return officialOffers;
    };

    that.getBuoTypes = async () => {

      const buoTypes = await apiQueries.getResources('/sam/commons/buotypes', {}, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return buoTypes;
    };

    that.getAgs = async () => {

      const ags = await apiQueries.getAgs({}, {
        caching: {timeout: apiQueries.ONE_DAY},
        expand: [{
          property: 'mainstructure'
        }, {
          property: 'leerjaar',
          required: false
        }, {
          property: 'graad',
          required: false
        }, {
          property: 'structuuronderdeel',
          required: false
        }, {
          property: 'onderwijsvorm',
          required: false
        }, {
          property: 'buoOpleidingsvorm',
          required: false
        }, {
          property: 'buoFase',
          required: false
        }, {
          property: 'buoOpleiding',
          required: false
        }, {
          property: 'finaliteit',
          required: false
        }]
      });

      return ags;
    };

    that.getSchoolGoverningInstitutions = async () => {

      const schoolGoverningInstitutionRelations = await apiQueries.getOrganisationalUnitsRelations({
        'to.type': 'SCHOOL',
        type: 'GOVERNS'
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return schoolGoverningInstitutionRelations;
    };

    that.getSchoolCLBs = async () => {

      const schoolCLBRelations = await apiQueries.getOrganisationalUnitsRelations({
        'to.type': 'SCHOOL',
        type: 'PROVIDES_SERVICES_TO'
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return schoolCLBRelations;
    };

    that.getSchoolEntities = async (cache) => {
      const schoolEntities = await apiQueries.getOrganisationalUnits({
        type: 'SCHOOLENTITY'
      }, {
        caching: {
          timeout: cache ? apiQueries.FIVE_MINUTES : 0
        }
      });
      return schoolEntities;
    };

    that.getSchoolEntityGoverningInstitutions = async (cache) => {

      const schoolEntityGoverningInstitutionRelations = await apiQueries.getOrganisationalUnitsRelations({
        'to.type': 'SCHOOLENTITY_CLUSTER',
        type: 'GOVERNS'
      }, {
        caching: {
          timeout: cache ? apiQueries.FIVE_MINUTES : 0
        }
      });

      return schoolEntityGoverningInstitutionRelations;
    };

    that.getCampuses = async (cache) => {

      const campuses = await apiQueries.getPhysicalLocations({
        type: 'DOMAIN'
      }, {
        caching: {
          timeout: cache ? apiQueries.FIVE_MINUTES : 0
        }
      });

      return campuses;
    };

    that.getSchoolEntityLocations = async (cache) => {

      const schoolEntityLocations = await apiQueries.getOrganisationalUnitsLocations({
        'organisationalUnit.type': 'SCHOOLENTITY'
      }, {
        caching: {
          timeout: cache ? apiQueries.ONE_DAY : 0
        }
      });

      return schoolEntityLocations;
    };

    that.getLocationExternalIdentifiers = async (cache) => {

      const locationExternalIdentifiers = await apiQueries.getOrganisationalUnitsLocationsExternalIdentifiers({
      }, {
        caching: {
          timeout: cache ? apiQueries.FIVE_MINUTES : 0
        }
      });

      return locationExternalIdentifiers;
    };

    that.getRealOffers = async (cache) => {

      const realOffers = await apiQueries.getEducationalProgrammeDetails({
        'organisationalUnit.type': 'SCHOOLENTITY',
        /*expand: 'SUMMARY',*/
        startDateBefore: startDateOfPreviousSchoolYear,
        endDateAfter: startDateOfPreviousSchoolYear
      }, {
        caching: {
          timeout: cache ? apiQueries.FIVE_MINUTES : 0
        }
      });

      return realOffers;
    };

    that.getRealOffersAtLocations = async (cache) => {

      const realOffersAtLocations = await apiQueries.getEducationalProgrammeDetailsLocations({
        'physicalLocation.type': 'DOMAIN',
        startDateBefore: startDateOfPreviousSchoolYear,
        endDateAfter: startDateOfPreviousSchoolYear
      }, {
        caching: {
          timeout: cache ? apiQueries.FIVE_MINUTES : 0
        }
      });

      return realOffersAtLocations;
    };

    that.getRealOfficialOffersRelations = async (cache) => {

      const realOfficialOffersRelations = await apiQueries.getEducationalProgrammeDetailsLocationsRelations({
      }, {
        caching: {
          timeout: cache ? apiQueries.FIVE_MINUTES : 0
        }
      });

      return realOfficialOffersRelations;
    };

    that.buildOrganisationalUnitsRelationsQuery = (referenceHref, type, when = dateUtils.getNow()) => {
      let href = '/sam/organisationalunits/relations';
      if (type === 'PROVIDES_SERVICES_TO' ||
        type === 'GOVERNS'
      ) {
        href += '?to=' + referenceHref;
      } else {
        href += '?from=' + referenceHref;
      }
      href += '&type=' + type;

      href += '&startDateBefore=' + when;
      href += '&endDateAfter=' + when;

      return href;
    };

    that.getSchool = async (href) => {
      const school = await apiQueries.getOrganisationalUnit(href, {}, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return school;
    };

    that.getGoverningInstitution = async (href, cache) => {

      const governingInstitution = await apiQueries.getOrganisationalUnit(href, {}, {
        caching: {
          timeout: cache ? apiQueries.ONE_DAY : 0
        }
      });

      return governingInstitution;
    };

    that.getGoverningInstitutionForSchool = async (governedHref, startDate) => {
      let governingInstitutionRelations =
        await that.getSchoolGoverningInstitution(governedHref);
      governingInstitutionRelations =
        dateUtils.getActiveResources(governingInstitutionRelations, startDate);

      return governingInstitutionRelations && governingInstitutionRelations.length > 0 ?
        await that.getGoverningInstitution(governingInstitutionRelations[0].from.href) : null;
    };

    that.getCLB = async (href) => {

      const clb = await apiQueries.getOrganisationalUnit(href, {}, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return clb;
    };

    that.getAg = async (href) => {

      const ag = await apiQueries.getAg(href, {}, {
        caching: {timeout: apiQueries.ONE_DAY},
        expand: [{
          property: 'mainstructure'
        }, {
          property: 'leerjaar',
          required: false
        }, {
          property: 'graad',
          required: false
        }, {
          property: 'structuuronderdeel',
          required: false
        }, {
          property: 'onderwijsvorm',
          required: false
        }, {
          property: 'buoOpleidingsvorm',
          required: false
        }, {
          property: 'buoFase',
          required: false
        }, {
          property: 'buoOpleiding',
          required: false
        }, {
          property: 'buoType',
          required: false
        }, {
          property: 'leerweg',
          required: false
        }, {
          property: 'stelselSo',
          required: false
        }, {
          property: 'finaliteit',
          required: false
        }]
      });

      return ag;
    };

    that.getBuoType = async (href) => {

      const buoType = await apiQueries.getResource(href, {}, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return buoType;
    };

    that.getOfficialDomain = async (href) => {

      const pl = await apiQueries.getPhysicalLocation(href, {}, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return pl;
    };

    that.getOUExternalIdentifier = async (schoolHref, type) => {

      const ouExternalIdentifiers = await apiQueries.getOrganisationalUnitsExternalIdentifiers({
        organisationalUnit: schoolHref,
        type: type
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return ouExternalIdentifiers && ouExternalIdentifiers.length > 0 ? ouExternalIdentifiers[0] : null;
    };

    that.getOfficialOffer = async (href) => {

      let officialOffer = await apiQueries.getEducationalProgrammeDetail(href, {}, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      if (officialOffer) {
        officialOffer.organisationalUnit.$$expanded = await that.getSchool(officialOffer.organisationalUnit.href);
        officialOffer.ag.$$expanded = await that.getAg(officialOffer.ag.href);

        if (officialOffer.buoType) {
          officialOffer.buoType.$$expanded = await that.getBuoType(officialOffer.buoType.href);
        }
      }

      return officialOffer;
    };

    that.getOfficialOffersAtLocation = async (href) => {

      let officialOffersAtLocation = await apiQueries.getEducationalProgrammeDetailLocation(href, {
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      if (officialOffersAtLocation) {
        await pAll([
          async () => officialOffersAtLocation.educationalProgrammeDetail.$$expanded =
            await that.getOfficialOffer(officialOffersAtLocation.educationalProgrammeDetail.href),
          async () => officialOffersAtLocation.physicalLocation.$$expanded =
            await that.getOfficialDomain(officialOffersAtLocation.physicalLocation.href)
        ]);
      }

      return officialOffersAtLocation;
    };

    that.attachAvatarInfo = async (officialOffersAtLocation) => {
      const school = officialOffersAtLocation.educationalProgrammeDetail.$$expanded.organisationalUnit;
      const schoolLocation = officialOffersAtLocation.physicalLocation;

      const institutionNumberRelation = await that.getOUExternalIdentifier(school.href, 'INSTITUTION_NUMBER');
      officialOffersAtLocation.$$institutionNumber =
        institutionNumberRelation ? institutionNumberRelation.value : undefined;

      let locations = await that.getLocationsAtSchool(school.href);

      let location;

      if (locations && locations.length > 0) {
        location = locations.find(
          loc => loc.physicalLocation.href === schoolLocation.href && loc.discimusSerialNumber);
      }

      officialOffersAtLocation.$$discimusSerialNumber =
        location ? location.discimusSerialNumber : undefined;

      if (officialOffersAtLocation.$$discimusSerialNumber) {
        const institutionNumberColor =
          utils.generateColorFromString(officialOffersAtLocation.$$institutionNumber);

        officialOffersAtLocation.$$avatarColor = utils.lightenDarkenColor(institutionNumberColor,
          officialOffersAtLocation.$$discimusSerialNumber / locations.length * 0.7);
      }
    };

    that.getLocationsAtSchool = async (schoolHref) => {
      const schoolLocationRelations = await apiQueries.getOrganisationalUnitsLocations({
        organisationalUnit: schoolHref
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return schoolLocationRelations;
    };

    that.getSchoolGoverningInstitution = async (schoolHref) => {
      const schoolGoverningInstitutionRelations = await apiQueries.getOrganisationalUnitsRelations({
        to: schoolHref,
        type: 'GOVERNS'
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return schoolGoverningInstitutionRelations;
    };

    that.getSchoolCLB = async (schoolHref) => {
      const schoolCLBRelations = await apiQueries.getOrganisationalUnitsRelations({
        to: schoolHref,
        type: 'PROVIDES_SERVICES_TO'
      }, {
        caching: {
          timeout: apiQueries.ONE_DAY
        }
      });

      return schoolCLBRelations;
    };

    that.getLocationsAtSchoolEntity = async (schoolHref) => {

      const schoolEntityLocations = await apiQueries.getOrganisationalUnitsLocations({
        organisationalUnit: schoolHref
      });

      return schoolEntityLocations;
    };

    that.getSchoolEntity = async (href, cache) => {

      const schoolEntity = await apiQueries.getOrganisationalUnit(href, {}, {
        caching: {
          timeout: cache ? apiQueries.ONE_DAY : 0
        }
      });

      return schoolEntity;
    };

    that.getCampus = async (plHref, cache) => {

      const campus = await apiQueries.getPhysicalLocation(plHref, {}, {
        caching: {
          timeout: cache ? apiQueries.ONE_DAY : 0
        }
      });

      return campus;
    };

    that.getLocationExternalIdentifier = async (locHref, type, cache) => {

      const locationExternalIdentifiers = await apiQueries.getOrganisationalUnitsLocationsExternalIdentifiers({
        location: locHref,
        type: type
      }, {
        caching: {
          timeout: cache ? apiQueries.ONE_DAY : 0
        }
      });

      return locationExternalIdentifiers && locationExternalIdentifiers.length > 0 ?
        locationExternalIdentifiers[0] : null;
    };

    that.getRealOffersAtLocation = async (href, cache) => {

      let realOffersAtLocation = await apiQueries.getEducationalProgrammeDetailLocation(href, {}, {
        caching: {
          timeout: cache ? apiQueries.ONE_DAY : 0
        }
      });

      if (realOffersAtLocation) {
        await pAll([
          async () => realOffersAtLocation.educationalProgrammeDetail.$$expanded =
            await that.getRealOffer(realOffersAtLocation.educationalProgrammeDetail.href, cache),
          async () => realOffersAtLocation.physicalLocation.$$expanded =
              await that.getCampus(realOffersAtLocation.physicalLocation.href, cache)
        ]);
      }

      return realOffersAtLocation;
    };

    that.getRealOffer = async (href, cache) => {

      let realOffer = await apiQueries.getEducationalProgrammeDetail(href, {}, {
        caching: {
          timeout: cache ? apiQueries.ONE_DAY : 0
        }
      });

      if (realOffer) {
        realOffer.organisationalUnit.$$expanded = await that.getSchoolEntity(realOffer.organisationalUnit.href, cache);
        realOffer.ag.$$expanded = await that.getAg(realOffer.ag.href);

        if (realOffer.buoType) {
          realOffer.buoType.$$expanded = await that.getBuoType(realOffer.buoType.href);
        }
      }

      return realOffer;
    };

    that.getSchoolEntityGoverningInstitution = async (schoolEntityHref, cache) => {

      const schoolEntityGoverningInstitutionRelations = await apiQueries.getOrganisationalUnitsRelations({
        to: schoolEntityHref,
        type: 'GOVERNS'
      }, {
        caching: {
          timeout: cache ? apiQueries.ONE_DAY : 0
        }
      });

      return schoolEntityGoverningInstitutionRelations;
    };

    that.getGoverningInstitutionForSchoolEntity = async (governedHref, startDate, cache) => {
      let governingInstitutionRelations =
        await that.getSchoolEntityGoverningInstitution(governedHref, cache);
      governingInstitutionRelations =
        dateUtils.getActiveResources(governingInstitutionRelations, startDate);

      return governingInstitutionRelations && governingInstitutionRelations.length > 0 ?
        await that.getGoverningInstitution(governingInstitutionRelations[0].from.href) : null;
    };

    return that;
  }
];
