module.exports = ['$translate', 'dateUtils', 'addressUtils', 'apiService', 'utils',
  ($translate, dateUtils, addressUtils, apiService, utils) => {
    'use strict';

    const sriClient = apiService.getApi();
    const SriClientError = apiService.SriClientError;
    const CACHE_ONE_DAY = {caching: {timeout: 60 * 60 * 24}};

    let me = {};

    const typeRegex = new RegExp(/^(\/.*)\/[0-9a-f\-]+$/);
    const getPermalinkType = function (permalink) {
      return permalink.replace(typeRegex, '$1');
    };

    me.CODE = {
      LOOSE_CLUSTER: 'no.enough.schools.for.cluster'
    };

    me.validatePeriod = (resource) => {
      if (!dateUtils.isAfterOrEqual(resource.endDate, resource.startDate)) {
        throw new SriClientError({
          body: [{
            body: {
              errors: [{
                status: 409,
                code: 'enddate.not.after.startdate'
              }]
            },
            status: 409
          }]
        });
      }
    };

    const getPropertyName = function (property) {
      if(property === 'endDate') {
        return 'einddatum';
      }
      return 'startdatum';
    };

    const getHref = function (href, batch, options) {
      if(!batch || !Array.isArray(batch)) {
        return sriClient.get(href, undefined, options);
      }
      const batchIndex = batch ? _.findIndex(batch, elem => elem.href === href) : -1;
      if (batchIndex !== -1) {
        return batch[batchIndex].body;
      }

      return sriClient.get(href, undefined, options);
    };

    const getTypeForResource = async function (resource, batch, context) {
      const permalink = resource.$$meta.permalink;
      if (permalink.match(/^\/sam\/organisationalunits\/locations\/externalidentifiers\/.*/)) {
        const location = resource.location.$$expanded ? resource.location.$$expanded :
          await sriClient.get(resource.location.href, {expand: 'results.physicalLocation'});
        return {
          name: 'campus',
          plural: 'campussen',
          parent: 'schoolentiteit',
          reference: addressUtils.printAddress(location.physicalLocation.$$expanded.address)
        };
      } else if (permalink.match(/^\/sam\/organisationalunits\/locations\/.*/)) {
        const pl = resource.physicalLocation.$$expanded ? resource.physicalLocation.$$expanded :
          await sriClient.get(resource.physicalLocation.href);
        return {
          name: 'campus',
          plural: 'campussen',
          parent: 'schoolentiteit',
          reference: addressUtils.printAddress(pl.address)
        };
      } else if (permalink.match(/^\/sam\/organisationalunits\/relations\/.*/)) {
        const from = resource.from.$$expanded ? resource.from.$$expanded : await sriClient.get(resource.from.href);
        if (resource.type === 'IS_PART_OF') {
          if (from.type === 'CLASS') {
            return {
              name: 'klas',
              plural: 'klassen',
              parent: 'schoolentiteit',
              reference: from.$$displayName
            };
          } else if (from.type === 'SCHOOLENTITY') {
            return {
              name: 'schoolentiteit',
              plural: 'schoolentiteiten',
              parent: 'schoolentiteit',
              reference: from.$$displayName
            };
          } else if (from.type === 'SCHOOLENTITY_CLUSTER') {
            return {
              name: 'cluster',
              plural: 'clusters',
              parent: 'cluster',
              reference: from.$$displayName
            };
          }
          console.warn('uncovered OU relation type');
        } else if(resource.type === 'GOVERNS') {
          return {
            name: 'bestuur',
            plural: 'relaties met een bestuur',
            parent: 'schoolentiteit',
            reference: from.$$displayName
          };
        } else if(resource.type === 'PROVIDES_SERVICES_TO') {
          return {
            name: 'CLB',
            plural: 'relaties met een CLB\'s',
            parent: 'schoolentiteit',
            reference: from.$$displayName
          };
        } else if(resource.type === 'CAN_ACT_AS') {
          const to = resource.to.$$expanded ? resource.to.$$expanded : await sriClient.get(resource.to.href);
          return {
            name: 'Officiële school',
            plural: 'relaties met een officiële school',
            parent: 'schoolentiteit',
            reference: to.$$displayName
          };
        }
        console.warn('uncovered OU relation type:', resource.type);
      } else if(permalink.match(/^\/sam\/organisationalunits\/.*/)) {
        if(resource.type === 'SCHOOLENTITY') {
          return {
            name: 'schoolentiteit',
            plural: 'schoolentiteiten',
            reference: resource.$$displayName
          };
        } else if(resource.type === 'CLASS') {
          return {
            name: 'klas',
            plural: 'klassen',
            reference: resource.$$displayName
          };
        } else if(resource.type === 'SCHOOLENTITY_CLUSTER') {
          return {
            name: 'cluster',
            plural: 'clusters',
            reference: resource.$$displayName
          };
        }
        console.warn('uncovered OU type');
      } else if (permalink.match(/^\/sam\/educationalprogrammedetails\/locations\/relations\/.*/)) {
        const epdLoc = resource.from.$$expanded ? resource.from.$$expanded : await getHref(resource.from.href, batch);
        const epd = epdLoc.educationalProgrammeDetail.$$expanded ? epdLoc.educationalProgrammeDetail.$$expanded : await getHref(epdLoc.educationalProgrammeDetail.href, batch);
        const ag = epd.ag.$$expanded ? epd.ag.$$expanded : await sriClient.get(epd.ag.href, undefined, CACHE_ONE_DAY);
        return {
          name: 'relatie met de officiele structuur',
          plural: 'relaties met de officiele structuur',
          parent: 'AG',
          reference: 'voor AG ' + ag.code + ' - ' + ag.name
        };
      } else if(permalink.match(/^\/sam\/educationalprogrammedetails\/locations\/.*/)) {
        const epd = resource.educationalProgrammeDetail.$$expanded ? resource.educationalProgrammeDetail.$$expanded : await sriClient.get(resource.educationalProgrammeDetail.href, {expand: 'organisationalUnit'});
        const ou = epd.organisationalUnit.$$expanded ? epd.organisationalUnit.$$expanded : await sriClient.get(epd.organisationalUnit.href);
        const ag = epd.ag.$$expanded ? epd.ag.$$expanded : await sriClient.get(epd.ag.href, undefined, CACHE_ONE_DAY);
        return {
          name: context && context.name === 'relatie met de officiele structuur' ? 'AG binnen de ' + (ou.type === 'SCHOOL' ? 'offici\u00eble' : 're\u00eble') + ' structuur' : 'AG',
          plural: context && context.name === 'relatie met de officiele structuur' ? 'AG\'s binnen de ' + (ou.type === 'SCHOOL' ? 'offici\u00eble' : 're\u00eble') + ' structuur' : 'AG\'s',
          parent: 'campus',
          reference: ag.code + ' - ' + ag.name
        };
      } else if(permalink.match(/^\/sam\/educationalprogrammedetails\/.*/)) {
        if(resource.ag) {
          const ag = resource.ag.$$expanded ? resource.ag.$$expanded : await sriClient.get(resource.ag.href);
          return {
            name: 'AG',
            plural: 'AG\'s',
            parent: 'schoolentiteit',
            reference: ag.code + ' - ' + ag.name
          };
        } else {
          const studyProgramme = resource.studyProgramme.$$expanded ? resource.studyProgramme.$$expanded : await sriClient.get(resource.studyProgramme.href);
          return {
            name: 'studieprogramma',
            plural: 'studieprogramma\'s',
            parent: 'schoolentiteit',
            reference: studyProgramme.title
          };
        }
      }
      console.warn('uncoverd type for ' + permalink);
    };

    const periodToString = (periodic) => {
      return periodic.endDate ? 'van ' + periodic.startDate + ' tot ' + periodic.endDate : 'sinds ' + periodic.startDate;
    };

    const getMessageForIdenticalRelation = async (error, resource, resourceType) => {
      const conflictingResources = await sriClient.getAll(getPermalinkType(resource.$$meta.permalink), {hrefs: error.hrefs.join(',')});
      return utils.capitalizeFirstLetter(resourceType.name) + ' ' + utils.quoteText(resourceType.reference) + ' - is al gekoppeld aan deze ' + resourceType.parent + ' ' +
        periodToString(conflictingResources[0]) + (conflictingResources.length > 1 ? ' en ' + periodToString(conflictingResources[1]) : '') +
        '. Dit overlapt met de opgegeven periode.';
    };

    const getMessageForInactiveResource = async (error, resource, resourceType) => {
      if(!error.hrefs || error.hrefs.length === 0) {
        throw error;
      }
      //hack because there is inconsistency in the hrefs objects that are coming back.
      if(error.hrefs[0].href) {
        error.hrefs[0] = error.hrefs[0].href;
      }
      const conflictingResource = await sriClient.get(error.hrefs[0]);
      const conflictingResourceType = await getTypeForResource(conflictingResource, undefined, resourceType);
      const conflictingStart = dateUtils.isBefore(resource.startDate, conflictingResource.startDate);
      const conflictingEnd = dateUtils.isAfter(resource.endDate, conflictingResource.endDate);
      // todo add startdatum van {naam van resourceType van de orginele resource, die hebben we pas als href wordt toegevoegd in de batch)
      const message = utils.capitalizeFirstLetter(conflictingResourceType.name) + ' ' + utils.quoteText(conflictingResourceType.reference) +
        (conflictingStart ? ' start pas vanaf ' + conflictingResource.startDate : '') + (conflictingStart && conflictingEnd ? ' en' : '') +
        (conflictingEnd ? ' heeft een einddatum op ' + conflictingResource.endDate : '') + '. De ' +
        (conflictingStart ? 'startdatum van de ' + resourceType.name + ' mag niet voor de startdatum van de ' + conflictingResourceType.name : '') + (conflictingStart && conflictingEnd ? ' en de ' : '') +
        (conflictingEnd ? 'einddatum van de ' + resourceType.name + ' mag niet na de einddatum van de ' + conflictingResourceType.name : '') + ' liggen.';
      return {
        message: message,
        conflictingResourceType: conflictingResourceType.name
      };
    };

    const getMessageForNonCoveringCampusses = async (error, resource) => {
      const campus = await sriClient.get(error.hrefs[0], {expand: 'physicalLocation'});
      const conflictingStart = dateUtils.isBefore(resource.startDate, campus.startDate);
      const conflictingEnd = dateUtils.isAfter(resource.endDate, campus.endDate);
      return 'Campus - ' + addressUtils.printAddress(campus.physicalLocation.$$expanded.address) +
        (conflictingStart ? ' start pas vanaf ' + campus.startDate : '') + (conflictingStart && conflictingEnd ? ' en' : '') +
        (conflictingEnd ? ' heeft een einddatum op ' + campus.endDate : '') + '. De ' +
        (conflictingStart ? 'startdatum van de AG mag niet voor de startdatum van de campus' : '') + (conflictingStart && conflictingEnd ? ' en de ' : '') +
        (conflictingEnd ? 'einddatum van de AG mat niet na de einddatum van de campus' : '') + ' liggen.';
    };

    const getMessageForHierarchicLoopRelation = async (error, resource, resourceType) => {
      const conflictingResources =
        await sriClient.getAll(getPermalinkType(resource.$$meta.permalink), {
          hrefs: error.hrefs.join(','),
          expand: 'results.to'});
      return utils.quoteText(utils.capitalizeFirstLetter(resourceType.parent)) + ' ' + ' ' +
        resourceType.reference + ' - is al gekoppeld aan ' + resourceType.name + ' ' +
        utils.quoteText(conflictingResources[0].to.$$expanded.$$displayName) + ' ' +
        periodToString(conflictingResources[0]) +
        '. Dit overlapt met de opgegeven periode, waardoor een cyclische clusterhiërarchie wordt gemaakt.';
    };

    const getMessageForOverlappingParentRelation = async (error, resource, resourceType) => {
      const conflictingResources =
        await sriClient.getAll(getPermalinkType(resource.$$meta.permalink), {
          hrefs: error.hrefs.join(','),
          expand: 'results.to'});
      return utils.quoteText(utils.capitalizeFirstLetter(resourceType.parent)) + ' ' +
        resourceType.reference + ' - is al gekoppeld aan ' + resourceType.name + ' ' +
        utils.quoteText(conflictingResources[0].to.$$expanded.$$displayName) + ' ' +
        periodToString(conflictingResources[0]) +
        '. Dit overlapt met de opgegeven periode, zodat de school in dezelfde periode tot twee groepen zou behoren.';
    };

    const getMessageForLooseCluster = async (error) => {
      const conflictingClusters = await sriClient.getAll('/sam/organisationalunits', {
        hrefs: error.hrefs.join(',')
      });

      return 'Deze actie is niet geldig. De volgende groepen zouden overblijven met minder dan 2 actieve schoolentiteiten: ' +
        conflictingClusters.map(cluster => utils.quoteText(cluster.$$displayName) + ' van ' +
        cluster.startDate + (cluster.endDate ? ' tot ' + cluster.endDate : '')).join(',') + '.';
    };

    const getDateErrorMessage = async function (mainResource, dateErrorObj) {
      const mainResourceType = await getTypeForResource(mainResource, undefined);
      const resourceType = await getTypeForResource(dateErrorObj.resource, undefined);
      //const periodicType = await getTypeForResource(dateErrorObj.periodic);
      const periodics = dateErrorObj.periodics;
      const propertyName = getPropertyName(dateErrorObj.property);
      let propertyToAdjust = propertyName;
      let message = 'De ' + propertyName + ' kan niet gewijzigd worden omdate er ' +
          (periodics.length > 1 ? periodics.length + ' ' + periodics[0].plural : 'een ' + periodics[0].name) +
          ' (' + periodics.map(p => p.reference).join(', ') + ')' + ' gekoppeld ' + (periodics.length > 1 ? 'zijn' : 'is') + ' aan de ' + mainResourceType.name +
          (resourceType.name !== mainResourceType.name && resourceType.name !== periodics[0].name ? ' (via zijn ' + resourceType.name + ')' : '');
      if(dateErrorObj.code === 'ends.inbetween' || dateErrorObj.code === 'starts.inbetween') {
         message += ' met een ' + propertyName + (dateErrorObj[dateErrorObj.property] !== -1 ? ' ' + dateErrorObj[dateErrorObj.property] : '') +
         ' die valt ' + (dateErrorObj.code === 'ends.inbetween' ? 'na de nieuwe einddatum' : 'voor de nieuwe startdatum');
      } else if(dateErrorObj.code === 'starts.after.new.end') {
        message += ' met een startdatum' + (dateErrorObj.startDate !== -1 ? ' ' + dateErrorObj.startDate : '') +
          ' die valt na de nieuwe einddatum van de ' + mainResourceType.name;
        propertyToAdjust = 'startdatum';
      } else if(dateErrorObj.code === 'ends.before.new.start') {
        message += ' met een einddatum' + (dateErrorObj.endDate !== -1 ? ' ' + dateErrorObj.endDate : '') +
          ' die valt voor de nieuwe startdatum van de ' + mainResourceType.name;
        propertyToAdjust = 'einddatum';
      }
      message += '. Kijk eerst deze ' + (periodics.length > 1 ? periodics[0].plural + ' na ' : periodics[0].name + ' na ') + ' en onderneem actie vooraleer je verder gaat met de ' + propertyName +
          ' van de ' + mainResourceType.name + ' aan te passen.';
      return message;
    };

    me.handleDateError = async function (mainResource, errors) {
      let errorObj;
      if (Array.isArray(errors)) {
        errorObj = errors[0].body;
        // We will only report the fist problem that occurs, but we gather all the
        // information of similar problems (same error code and same periodicType)
        if (Array.isArray(errorObj)) {
          errorObj = errorObj[0].body;
        }
        errorObj.periodics = [await getTypeForResource(errorObj.periodic)];
        errorObj.startDate = errorObj.periodic.startDate;
        errorObj.endDate = errorObj.periodic.endDate;
        for (let i = 1; i < errors.length; i++) {
          const other = errors[i].body;
          if (errorObj.code === other.code &&
                  getPermalinkType(errorObj.periodic.$$meta.permalink) === getPermalinkType(other.periodic.$$meta.permalink)) {
            errorObj.periodics.push(await getTypeForResource(other.periodic));
            if (errorObj.startDate !== other.periodic.startDate) {
              errorObj.startDate = -1;
            }
            if (errorObj.endDate !== other.periodic.endDate) {
              errorObj.endDate = -1;
            }
          }
        }
      } else {
        errorObj = errors;

        errorObj.periodics = [await getTypeForResource(errorObj.resource)];
        errorObj.startDate = errorObj.resource.startDate;
        errorObj.endDate = errorObj.resource.endDate;
      }

      return getDateErrorMessage(mainResource, errorObj);
    };

    me.getErrorsByCode = (error, code) => {
      if (!(error instanceof SriClientError)) {
        return [];
      }

      let errorArray = error.body.filter(body => body.status === 409);
      errorArray = errorArray.map(body => body.body.errors);
      errorArray = _.flatten(errorArray);

      return errorArray.filter(e => e.code === code);
    };

    me.handleApiError = async (error, payload) => {
      if (!(typeof error === 'object' && error.body && error instanceof SriClientError)) {
        return JSON.stringify(error);
      }
      //const originalPayload = error.body.document;
      let errorArray = error.body.filter(body => body.status === 409);
      //errorArray = errorArray.map(body => body.body.errors);
      //errorArray = _.flatten(errorArray);

      let errorMsgs = [];
      try {

        for(let batchElem of errorArray) {
          const elem = batchElem.body;
          let resourceType = null;
          if (elem.document) {
            elem.document.$$meta = {
              permalink: batchElem.href
            };
            resourceType = await getTypeForResource(elem.document, payload);
          }
          for(let e of elem.errors) {
            if (e.code === 'identical.relation.in.overlapping.period') {
              errorMsgs.push({
                message: await getMessageForIdenticalRelation(e, elem.document, resourceType),
                resourceType: resourceType.name
              });
            } else if(e.code.match(/^.*\.inactive/)) {
              errorMsgs.push(await getMessageForInactiveResource(e, elem.document, resourceType));
            } else if(e.code === 'organisationalunits_locations.not.covering') {
              errorMsgs.push({
                message: await getMessageForNonCoveringCampusses(e, elem.document),
                resourceType: resourceType.name
              });
            } else if(e.code === 'hierarchic.loop') {
              errorMsgs.push({
                message: await getMessageForHierarchicLoopRelation(e, elem.document, resourceType),
                resourceType: resourceType.name
              });
            } else if(e.code === 'overlapping.parent') {
              errorMsgs.push({
                message: await getMessageForOverlappingParentRelation(e, elem.document, resourceType),
                resourceType: resourceType.name
              });

            } else if (e.code === me.CODE.LOOSE_CLUSTER) {
              errorMsgs.push({
                message: await getMessageForLooseCluster(e)});
            } else {
              errorMsgs.push({
                message: $translate.instant(e.code) || e.message || e.msg,
                hrefs: e.hrefs && e.hrefs.length > 0 ? '. Hrefs: ' + e.hrefs.join(', ') : ''
              });
            }
          }
        }

        // filter errors about classes if there are errors about other resources as well.
        const errorMsgNotRegardingClasses = errorMsgs.filter(msg => msg.conflictingResourceType !== 'klas');
        if (errorMsgNotRegardingClasses.length > 0) {
          errorMsgs = errorMsgNotRegardingClasses;
        }

        errorMsgs = _.uniqWith(errorMsgs, _.isEqual);
        return errorMsgs.map(errorMsg => [errorMsg.message, errorMsg.hrefs].join('')).join(' | ');
      } catch (e) {
        console.error('unexpected error occured in error handling:');
        console.error(e);
      }
    };

    me.handleError = async function (error, payload) {

      if (error instanceof dateUtils.DateError) {
        return await me.handleDateError(payload[0].body, error.body);
      }

      return await me.handleApiError(error, payload);
    };


    me.getClusteringErrorMessage = (schoolEntitiesOrClusters, clusterMovements, conflictingClusters) => {

      if (conflictingClusters.length > 0) {
        return ['danger', 'danger',
          'Er bestaat reeds SchoolentiteitCluster ' + conflictingClusters.map(conflictingCluster =>
            utils.quoteText(conflictingCluster.$$displayName)).join(', ') +
          ' met dezelfde samenstelling die start ' + 'op dezelfde datum. Pas de naam aan indien nodig.'
        ];
      }
      return ['danger', 'warning', 'Opgelet: ' +
        clusterMovements.map(clusterRelation => {

          const schoolEntity = schoolEntitiesOrClusters.find(se => se.$$meta.permalink === clusterRelation.from.href);
          return 'De Schoolentiteit ' + utils.quoteText(schoolEntity.$$displayName) +
            ' behoort reeds ' +
            'tot de SchoolentiteitCluster ' + utils.quoteText(clusterRelation.to.$$expanded.$$displayName) + ' sinds ' +
            clusterRelation.startDate;
        }).join(', ') +
        '. Ben je zeker dat ' +
        'je deze relatie wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden. Selecteer \'Bevestigen\' ' +
        'om deze relatie definitief te verwijderen.'
      ];

    };

    return me;
  }];
