/* eslint-disable no-undef */

module.exports = ['$scope', '$log', '$state', '$location', 'settings', 'apiQueries', 'vskoModalService', 'dateUtils',
  ($scope, $log, $state, $location, settings, apiQueries, vskoModalService, dateUtils) => {
    'use strict';

    const defaultItemsPerPage = settings.paginationSettings.itemsPerPage;

    $scope.model = {
      searchValues: {
        name: '',
        street: '',
        zipCode: '',
        city: '',
        onderwijskiezer: '',
        institutionNumber: '',
        email: '',
        referenceDate: dateUtils.parse(dateUtils.getNow())
      },
      pager: {
        currentPage: angular.isDefined($location.search().page) ? parseInt($location.search().page, 10) : 1,
        noMore: false,
        noResults: false,
        itemsPerPage: defaultItemsPerPage
      },
      selectedRows: [],
      schools: [],
      treeRoots: true,
      clearOut: false
    };

    $scope.loadSchoolEntities = () => {

      if ($scope.model.clearOut) {
        $scope.model.pager.currentPage = 1;
      } else {
        $scope.model.pager.currentPage++;
      }

      if ($scope.model.pager.currentPage > 1) {
        $location.search('page', $scope.model.pager.currentPage.toString());
      } else {
        $location.search('page', null);
      }

      $scope.getSchoolList();
    };

    const retrieveSearchParams = () => {
      $scope.model.pager.currentPage
        = angular.isDefined($location.search().page) ? parseInt($location.search().page, 10) : 1;

      $scope.model.searchValues = {
        name: '',
        street: '',
        zipCode: '',
        city: '',
        onderwijskiezer: '',
        institutionNumber: '',
        email: '',
        referenceDate: dateUtils.parse(dateUtils.getNow())
      };

      ($location.search().search || '').split(';').forEach(entry => {
        $scope.model.searchValues[entry.split(':')[0]] =
          entry.split(':')[0] === 'referenceDate' ? new Date(entry.split(':')[1]) : entry.split(':')[1];
      });
    };

    $scope.load = () => {
      retrieveSearchParams();
      $scope.getSchoolList();
    };

    $scope.$watch(() => {
      return $location.search();
    }, () => {
      retrieveSearchParams();
    }, true);

    const isSchoolEntityLeafSearch = () => {
      const searchValues = $scope.model.searchValues;

      return searchValues.name ||
        searchValues.street ||
        searchValues.zipCode ||
        searchValues.city ||
        searchValues.onderwijskiezer ||
        searchValues.onderwijsniveau ||
        searchValues.code ||
        searchValues.institutionNumber ||
        searchValues.agCode ||
        searchValues.email;
    };

    $scope.getSchoolList = async () => {

      $scope.model.pager.loading = true;

      const limit = $scope.model.pager.itemsPerPage,
        offset = ($scope.model.pager.currentPage - 1) * $scope.model.pager.itemsPerPage;

      let schoolEntities = [];

      try {

        let schoolEntityNumber;

        $location.search('search',
          Object.entries($scope.model.searchValues)
            .filter(entry => entry[1]).map(entry =>
              entry[0] === 'referenceDate' ?
                [entry[0], dateUtils.toString(entry[1])].join(':') : entry.join(':')).join(';'));

        if (isSchoolEntityLeafSearch()) {
          $scope.model.treeRoots = false;
          schoolEntities = await apiQueries.getSchoolEntityTreeLeaves($scope.model.searchValues, limit, offset);
          schoolEntityNumber = schoolEntities.length;
        } else {
          $scope.model.treeRoots = true;
          schoolEntities = await apiQueries.getSchoolEntityTreeRoots(
            dateUtils.toString($scope.model.searchValues.referenceDate), limit, offset);
          schoolEntityNumber = schoolEntities ? schoolEntities.length : 0;
        }

        $scope.$applyAsync(() => {
          if ($scope.model.clearOut) {
            $scope.model.schools = schoolEntities;
            $scope.model.selectedRows = [];
          } else {
            $scope.model.schools = [... $scope.model.schools, ... schoolEntities];
          }
          $scope.model.pager.noResults = $scope.model.schools.length === 0 && schoolEntityNumber === 0;
          $scope.model.pager.noMore = schoolEntityNumber < limit;

          $scope.model.pager.loading = false;
        });
      } catch (error) {
        $log.error(error + '. Can\'t get organisational units data.');
        $scope.model.pager.loading = false;
      }
    };

    $scope.searchFunction = async () => {
      $scope.model.clearOut = true;
      $scope.model.pager.currentPage = 1;
      await $scope.getSchoolList();
      $location.search('page', null);
    };

    $scope.clearSearch = () => {
      $location.search('search', null);
      $location.search('page', null);
    };

    $scope.controlListSelector = () => {
      vskoModalService.open({
        template: require('./controlListSelector.html'),
        controller: require('./controlListSelectorController'),
        animation: true,
        size: 'medium'
      });
    };

    $scope.addSchoolEntity = () => {
      vskoModalService.open({
        template: require('./addSchoolEntity.html'),
        controller: require('./addSchoolEntityController'),
        animation: true
      }).result.then((schoolEntityKey) => {
        if (schoolEntityKey) {
          $state.go('schoolList.school', {id: schoolEntityKey});
          $scope.getSchoolList();
        }
      });
    };

    $scope.addToExistingCluster = (schoolEntities) => {
      vskoModalService.open({
        resolve: {
          clusterInfo: {
            schoolEntities: schoolEntities
          }
        },
        template: require('./cluster/addToExistingCluster.html'),
        controller: require('./cluster/addToExistingClusterController'),
        animation: true
      }).result.then((data) => {
        if (data !== false) {
          $scope.$applyAsync(() => {
            $scope.model.clearOut = true;
            $scope.getSchoolList();
            $scope.model.selectedRows = [];
          });
        }
      });
    };

    $scope.addToNewCluster = (schoolEntities) => {
      vskoModalService.open({
        resolve: {
          clusterInfo: {
            schoolEntities: schoolEntities
          }
        },
        template: require('./cluster/addToNewCluster.html'),
        controller: require('./cluster/addToNewClusterController'),
        animation: true
      }).result.then((data) => {
        if (data !== false) {
          $scope.$applyAsync(() => {
            $scope.model.clearOut = true;
            $scope.getSchoolList();
            $scope.model.selectedRows = [];
          });
        }
      });
    };

    $scope.editCluster = (cluster) => {
      vskoModalService.open({
        resolve: {
          clusterInfo: {
            cluster: cluster
          }
        },
        template: require('./cluster/editCluster.html'),
        controller: require('./cluster/editClusterController'),
        animation: true
      }).result.then((data) => {
        if (data !== false) {
          $scope.$applyAsync(() => {
            $scope.model.clearOut = true;
            $scope.getSchoolList();
            $scope.model.selectedRows = [];
          });
        }
      });
    };

    $scope.closeCluster = (cluster) => {
      vskoModalService.open({
        resolve: {
          clusterInfo: {
            cluster: cluster,
            closeCluster: true
          }
        },
        template: require('./cluster/editCluster.html'),
        controller: require('./cluster/editClusterController'),
        animation: true
      }).result.then((data) => {
        if (data !== false) {
          $scope.$applyAsync(() => {
            $scope.model.clearOut = true;
            $scope.getSchoolList();
            $scope.model.selectedRows = [];
          });
        }
      });
    };

    $scope.removeFromCluster = (schoolEntity) => {
      vskoModalService.open({
        resolve: {
          schoolEntityInfo: {
            schoolEntity: schoolEntity
          }
        },
        template: require('./cluster/removeFromCluster.html'),
        controller: require('./cluster/removeFromClusterController'),
        animation: true
      }).result.then((data) => {
        if (data !== false) {
          $scope.$applyAsync(() => {
            $scope.model.clearOut = true;
            $scope.getSchoolList();
            $scope.model.selectedRows = [];
          });
        }
      });
    };
  }];
