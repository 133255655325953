/**
 * Created by guntherclaes on 03/03/2017.
 */
module.exports = exports = ['$location', 'settings', 'apiQueries', function ($location, settings, apiQueries) {
  'use strict';

  return {
    restrict: 'E',
    template: require('./header.html'),
    replace: true,
    transclude: true,
    scope: {
      apps: '=',
      fluid: '=',
      user: '=',
      title: '@',
      icon: '@'
    },
    link: function ($scope, element, attrs) {

      $scope.profile = 'https://mijn.katholiekonderwijs.vlaanderen/!#/profiel';
      $scope.goToProfile = () => {
        window.open($scope.profile, '_blank');
      };
      $scope.loginUrl = '#';
      $scope.logOut = () => {
        apiQueries.logOut();
      };

      $scope.getUser = async () => {
        const user = await apiQueries.getUser();
        $scope.user = user;
        $scope.username = user.username;
      };
      $scope.getUser();

    }
  };
} ];
