/**
 * The index of the application (where it all starts) for webpack
 */

window.$ = window.jQuery = require('jquery'); //TODO Has to be removed in the future
const settings = require('../config/settings.js');
/* if (settings.environment === '#{env}') {
  settings = require('../config/local.settings.js');
} */
console.log('settings are', settings)
const angular = require('angular'); // eslint-disable-line
const app = require('./app.js');

const pMap = require('p-map');
const pAll = require('p-all');

// also add the window.settings as a constant to our angular module
app.constant('settings', settings);
app.constant('_', require('lodash'));
app.constant('dateUtils', require('@kathondvla/sri-client/date-utils'));
app.constant('contactDetailUtils', require('@kathondvla/sri-client/contactdetail-utils'));
app.constant('addressUtils', require('@kathondvla/sri-client/address-utils'));
app.constant('epdUtils', require('@kathondvla/sam-utils/epd-utils'));
app.constant('commonUtils', require('@kathondvla/sri-client/common-utils'));
app.constant('pMap', pMap);
app.constant('pAll', pAll);
app.constant('Handsontable', require('handsontable'));

app.config(require('./config.js'));
app.config(require('./routes.js'));

if (settings.logRocket.enabled) {
  const LogRocket = require('logrocket');
  LogRocket.init('ldaday/' + settings.logRocket.token);
}

module.exports = app;
