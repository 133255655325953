/* eslint-disable no-undef */
/*gobal angular, _*/

require('./schoolEntityTreeItem');
const schoolEntityTree = angular.module('schoolEntityTree', ['schoolEntityTreeItem']);
schoolEntityTree.directive('schoolEntityTree', ['apiService', '$log', 'dateUtils', 'utils',
  (apiService, $log, dateUtils, utils) => {
    'use strict';

    const api = apiService.getApi();

    return {
      restrict: 'E',
      template: require('./schoolEntityTree.html'),
      replace: true,
      transclude: true,
      scope: {
        selectedRows: '=',
        schoolEntities: '=',
        closeCluster: '=',
        editCluster: '=',
        addToCluster: '=',
        removeFromCluster: '=',
        topBottomTraversal: '=?',
        clearOut: '=?',
        referenceDate: '='
      },
      link: function ($scope) {

        $scope.schoolEntities = [];
        $scope.expandedNodes = [];

        let referenceDate = dateUtils.toString($scope.referenceDate);

        const expandOneLevel = async (nodes) => {
          let schoolEntities = [],
            schoolEntitiesPromise;

          nodes.forEach(schoolEntity => {
            schoolEntities.push(schoolEntity);
          });

          schoolEntities = _.uniqBy(schoolEntities, 'key');
          const hrefs = _.map(schoolEntities, '$$meta.permalink').join();

          if ($scope.topBottomTraversal) {
            schoolEntitiesPromise =
              api.getAll('/sam/organisationalunits', {
                  hrefs: hrefs
                }, {
                  inBatch: '/batch',
                  include: [{
                    alias: '$$parents',
                    href: '/sam/organisationalunits/relations',
                    filters: {
                      type: 'IS_PART_OF',
                      'from.type': 'SCHOOLENTITY,SCHOOLENTITY_CLUSTER',
                      'to.type': 'SCHOOLENTITY_CLUSTER'/*,
                      startDateBefore: referenceDate,
                      endDateAfter: dateUtils.getNextDay(referenceDate)*/
                    },
                    reference: 'from',
                    expand: {
                      property: 'to'
                    }
                  }, {
                    alias: '$$children',
                    href: '/sam/organisationalunits/relations',
                    filters: {
                      type: 'IS_PART_OF',
                      'from.type': 'SCHOOLENTITY,SCHOOLENTITY_CLUSTER',
                      'to.type': 'SCHOOLENTITY_CLUSTER',
                      startDateBefore: referenceDate,
                      endDateAfter: dateUtils.getNextDay(referenceDate)
                    },
                    reference: 'to',
                    expand: {
                      property: 'from'
                    }
                  }, {
                    alias: '$$locations',
                    href: '/sam/organisationalunits/locations',
                    filters: {
                      type: 'CAMPUS',
                      startDateBefore: referenceDate,
                      endDateAfter: dateUtils.getNextDay(referenceDate)
                    },
                    reference: 'organisationalUnit',
                    expand: {
                      property: 'physicalLocation'
                    }
                  }]
                }
              );
          } else {
            schoolEntitiesPromise =
              api.getAll('/sam/organisationalunits', {
                  hrefs: hrefs
                }, {
                  inBatch: '/batch',
                  include: [{
                    alias: '$$parents',
                    href: '/sam/organisationalunits/relations',
                    filters: {
                      type: 'IS_PART_OF',
                      'from.type': 'SCHOOLENTITY,SCHOOLENTITY_CLUSTER',
                      'to.type': 'SCHOOLENTITY_CLUSTER'/*,
                      startDateBefore: referenceDate,
                      endDateAfter: dateUtils.getNextDay(referenceDate)*/
                    },
                    reference: 'from',
                    expand: {
                      property: 'to'
                    }
                  }, {
                    alias: '$$children',
                    href: '/sam/organisationalunits/relations',
                    filters: {
                      type: 'IS_PART_OF',
                      'from.type': 'SCHOOLENTITY,SCHOOLENTITY_CLUSTER',
                      'to.type': 'SCHOOLENTITY_CLUSTER',
                      startDateBefore: referenceDate,
                      endDateAfter: dateUtils.getNextDay(referenceDate)
                    },
                    reference: 'to',
                    expand: 'from'
                  }, {
                    alias: '$$locations',
                    href: '/sam/organisationalunits/locations',
                    filters: {
                      type: 'CAMPUS',
                      startDateBefore: referenceDate,
                      endDateAfter: dateUtils.getNextDay(referenceDate)
                    },
                    reference: 'organisationalUnit',
                    expand: {
                      property: 'physicalLocation'
                    }
                  }]
                }
              );
          }

          try {
          // process all nodes
            const response = await schoolEntitiesPromise;
            for (const result of response) {
              const schoolEntity = _.find(nodes, {key: result.key});

              if ($scope.topBottomTraversal) {

                _.assign(schoolEntity, {
                  $$children: result.$$children.map(childRelation => {
                    const leaf = childRelation.from.$$expanded;
                    if (!_.find(schoolEntities, {key: leaf.key}) && !$scope.expandedNodes.includes(leaf.key)) {
                      nodes.push(leaf);
                    }
                    return childRelation;
                  }),
                  $$parents: result.$$parents,
                  $$locations: _.map(_.filter(result.$$locations,
                    locationRelation =>
                      locationRelation.physicalLocation.$$expanded.type === 'DOMAIN'),
                  locationRelation => locationRelation.physicalLocation.$$expanded)
                });
              } else {
                _.assign(schoolEntity, {
                  $$parents: _.map(result.$$parents, parentRelation => {
                    const parent = parentRelation.to.$$expanded;
                    if (dateUtils.isCovering(parent, {startDate: referenceDate}) &&
                      !_.find(schoolEntities, {key: parent.key}) && !$scope.expandedNodes.includes(parent.key)) {
                      nodes.push(parent);
                    }
                    return parentRelation;
                  }),
                  
                  $$locations: _.map(_.filter(result.$$locations,
                    locationRelation =>
                      locationRelation.physicalLocation.$$expanded.type === 'DOMAIN'),
                  locationRelation => locationRelation.physicalLocation.$$expanded),
                  $$children: _.map(result.$$children, childRelation => {
                    const leaf = childRelation.from.$$expanded;
                    if (!_.find(schoolEntities, {key: leaf.key}) && !$scope.expandedNodes.includes(leaf.key)) {
                      nodes.push(leaf);
                    }
                    return childRelation;
                  })
                });
              }

              _.remove(nodes, {key: result.key});
              $scope.expandedNodes.push(result.key);
            }

            schoolEntities = _.uniqBy(schoolEntities, 'key');

            $scope.$applyAsync(() => {
              schoolEntities.forEach(schoolEntity => $scope.schoolEntities.push(schoolEntity));
              $scope.schoolEntities = _.uniqBy($scope.schoolEntities, 'key');
            });

          } catch (e) {
            $log.error(e);
          }

        };

        const buildTree = async (schoolEntities) => {

          if ($scope.clearOut) {
            $scope.schoolEntities = [];
            $scope.clearOut = false;
            $scope.expandedNodes = [];
          }

          referenceDate = dateUtils.toString($scope.referenceDate);

          const nodes = _.clone(schoolEntities.filter(
            schoolEntity => !$scope.expandedNodes.includes(schoolEntity.key)), true);
          $scope.foundKeys = _.map(nodes, n => n.key);

          while (nodes && nodes.length > 0) {
            await expandOneLevel(nodes);
          }

          schoolEntities.forEach(schoolEntity => {

            if (schoolEntity.$$children) {
              schoolEntity.$$children = schoolEntity.$$children.map(childRelation => {
                const child = schoolEntities.find(se => se.key === childRelation.from.$$expanded.key);
                if (child && $scope.expandedNodes.includes(child.key) && !childRelation.from.$$expanded.$$locations) {
                  childRelation.from.$$expanded = child;
                }
                return childRelation;
              });
            }

            if (schoolEntity.$$parents) {
              schoolEntity.$$parents = schoolEntity.$$parents.map(parentRelation => {
                const parent = schoolEntities.find(se => se.key === parentRelation.to.$$expanded.key);
                if (parent && $scope.expandedNodes.includes(parent.key) && !parentRelation.to.$$expanded.$$locations) {
                  parentRelation.to.$$expanded = parent;
                }
                return parentRelation;
              });
  
              const activeParent = utils.getActiveParent(schoolEntity, dateUtils.toString($scope.referenceDate));
  
              if (activeParent) {
                schoolEntity.$$activeParent = activeParent;
              }
            }
          });
        };

        $scope.$watch('schoolEntities', (n) => {
          $scope.$applyAsync(() => {
            buildTree(n);
          });
        });

        $scope.allowedToSelect = (item) => {
          if ($scope.selectedRows.length > 0) {
            return $scope.selectedRows.reduce((selectable, selectedItem) =>
              selectable && utils.canBeClusteredWith(selectedItem, item), true);
          }
          return true;
        };

        if (!$scope.selectedRows) {
          $scope.selectedRows = [];
        }

        $scope.selectRow = (item, value) => {
          var keyIndex = _.findIndex($scope.selectedRows, {key: item.key});
          if (keyIndex === -1 && value) {
            $scope.selectedRows.push(item);
          } else if (!value) {
            $scope.selectedRows.splice(keyIndex, 1);
          }
        };
      }
    };
  }]);

module.exports = schoolEntityTree;
