const { syncMsRelsWithEpds } = require("./helpers");

module.exports = ['$scope', '$uibModalInstance', 'educationalProgrammeDetailLocation', '$log',
  'dateUtils', 'apiService', 'apiQueries', '$q', 'errorManager', 'utils', 'school',
  ($scope, $uibModalInstance, educationalProgrammeDetailLocation, $log, dateUtils, apiService, apiQueries,
    $q, errorManager, utils, school) => {
    'use strict';

    const dateManager = apiService.getDateManager();

    $scope.ag = educationalProgrammeDetailLocation.educationalProgrammeDetail.$$expanded.ag.$$expanded;
    $scope.buoType = educationalProgrammeDetailLocation.educationalProgrammeDetail.$$expanded.buoType;
    $scope.educationalProgrammeDetailLocation = educationalProgrammeDetailLocation;

    $scope.model = {
      startDate: utils.isoToDate(educationalProgrammeDetailLocation.startDate),
      endDate: utils.isoToDate(educationalProgrammeDetailLocation.endDate)
    };

    $scope.warning = false;
    $scope.dateChanged = false;

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => {
      if ($scope.notificationOptions.enabled) {
        $scope.notificationOptions.enabled = false;
        $scope.warning = false;
      } else {
        $uibModalInstance.close(false);
      }
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };

    // dateChanged if model.startDate !== agPLRel.startDate || model.endDate != agPLRel.endDate
    $scope.$watch('model', (newModel) => {
      $scope.dateChanged = utils.dateToIso(newModel.startDate) &&
        utils.dateToIso(newModel.startDate) !== educationalProgrammeDetailLocation.startDate ||
        utils.dateToIso(newModel.endDate) !== educationalProgrammeDetailLocation.endDate;
    }, true);

    $scope.update = async () => {

      // clean batch
      const batch = [];

      let period = {
        startDate: utils.dateToIso($scope.model.startDate),
        endDate: utils.dateToIso($scope.model.endDate) || undefined
      };

      try {

        errorManager.validatePeriod(period);

        const educationalProgrammeDetailLocationToUpdate = _.extend({},
          educationalProgrammeDetailLocation,
          period);

        batch.push({
          href: '/sam/educationalprogrammedetails/locations/' + educationalProgrammeDetailLocationToUpdate.key,
          verb: 'PUT',
          body: educationalProgrammeDetailLocationToUpdate
        });

        $scope.loadingSave = true;

        try {
          const changedRelatedResources = await dateManager.manageDatesForEducationalProgrammeDetailLocation(educationalProgrammeDetailLocationToUpdate, batch, educationalProgrammeDetailLocation.startDate, educationalProgrammeDetailLocation.endDate, true);
          if (changedRelatedResources.educationalProgrammeDetail) {
            // denormalized direct relation with mainstructures needs to be kept in sync with indirect relations (epds pointing to ags and ag has a mainstructure)
            const newEpdsArray = school.$$educationalProgrammeDetails
              .filter((epd) => epd.key !== changedRelatedResources.educationalProgrammeDetail.key);
            newEpdsArray.push(changedRelatedResources.educationalProgrammeDetail);
            syncMsRelsWithEpds(newEpdsArray, school.$$epdsForMainstructures, batch);
          }
        } catch(error) {
          if(error instanceof dateUtils.DateError) {
            utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
            return;
          }
          throw error;
        }

        await apiQueries.postBatch('/batch', batch);
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      } finally {
        $scope.loadingSave = false;
      }
    };

    const deleteBatch = [];
    const deleteAll = async (batch) => {
      $scope.notificationOptions.enabled = false;
      $scope.loading = true;
      try {
        await apiQueries.postBatch('/sam/educationalprogrammedetails/batch', batch);
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      } finally {
        $scope.loading = false;
      }
    };

    // proceed after notifying the user about some deletions will be done.
    $scope.proceed = () => {
      deleteAll(deleteBatch);
    };

    $scope.warn = async () => {
      deleteBatch.splice(0, deleteBatch.length);

      deleteBatch.push({
        href: educationalProgrammeDetailLocation.$$meta.permalink,
        verb: 'DELETE'
      });
      $scope.loading = true;
      const dependencies = await dateManager.manageDeletesForEducationalProgrammeDetailLocation(educationalProgrammeDetailLocation, deleteBatch, true);
      $scope.loading = false;
      if (dependencies.educationalProgrammeDetail) {
        // denormalized direct relation with mainstructures needs to be kept in sync with indirect relations (epds pointing to ags and ag has a mainstructure)
        const newEpdsArray = school.$$educationalProgrammeDetails
          .filter((epd) => epd.$$meta.permalink !== educationalProgrammeDetailLocation.educationalProgrammeDetail.href);
        syncMsRelsWithEpds(newEpdsArray, school.$$epdsForMainstructures, deleteBatch);
      }
      const numberOfDeps = dependencies ? dependencies.relationsTo.length : 0;
      utils.notify($scope, 'warning', 'warning',
        (numberOfDeps > 0 ? 'Opgelet, er ' + (numberOfDeps > 1 ? 'zijn ' : 'is ') + numberOfDeps + ' koppeling' + (numberOfDeps > 1 ? 'en' : '') + ' met de offici\u00eble structuur gelinkt aan deze AG die ook verwijderd ' + (numberOfDeps > 1 ? 'zullen' : 'zal') + ' worden. ' : '') +
        'Ben je zeker dat je deze AG wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden. ' +
        'Selecteer \'Bevestigen\' om de AG ' + (numberOfDeps > 0 ? ' en zijn relaties ' : '') + ' definitief te verwijderen.');
      $scope.warning = true;
    };
  }
];
