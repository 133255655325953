module.exports = ['$scope', '$uibModalInstance', '$log', 'apiService', 'dateUtils', 'apiQueries',
  'utils', 'school', 'errorManager',
  ($scope, $uibModalInstance, $log, apiService, dateUtils, apiQueries, utils, school, errorManager) => {

    'use strict';

    const dateManager = apiService.getDateManager();

    $scope.model = {
      key: school.key,
      startDate: dateUtils.parse(school.startDate),
      endDate: dateUtils.parse(school.endDate),
      name: school.$$displayName
    };
    
    $scope.isYoungerThanOneMonth = dateUtils.isAfterOrEqual(school.$$meta.created, dateUtils.getPreviousMonth(dateUtils.getNow()));

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => {
      if ($scope.notificationOptions.enabled) {
        $scope.notificationOptions.enabled = false;
        $scope.warning = false;
      } else {
        $uibModalInstance.close(false);
      }
    };

    $scope.close = (data) => $uibModalInstance.close(data);

    // process user update request
    $scope.update = async () => {
      const batch = [];

      const period = {
          startDate: dateUtils.toString($scope.model.startDate),
          endDate: dateUtils.toString($scope.model.endDate) || undefined
        },
        schoolToUpdate = _.extend({}, school, {
          names: [{
            type: "OFFICIAL",
            value: $scope.model.name,
            startDate: period.startDate,
            endDate: period.endDate
          }]
        },
        period, {
          $$contactDetails: null,
          $$locations: null,
          $$parents: null});

      try {
        $scope.loadingSave = true;
        errorManager.validatePeriod(period);

        batch.push({
          href: schoolToUpdate.$$meta.permalink,
          verb: 'PUT',
          body: _.omit(schoolToUpdate)
        });

        if (period.startDate !== school.startDate || period.endDate !== school.endDate) {
          try {
            await dateManager.manageDatesForSchool(schoolToUpdate, batch, school.startDate, school.endDate);
          } catch (error) {
            if(error instanceof dateUtils.DateError) {
              console.log(error);
              utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
              return;
            }
            throw error;
          }
        }

        await apiQueries.postBatch('/batch', batch);

        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      } finally {
        $scope.loadingSave = false;
      }
    };

    $scope.enableSave = () =>
      $scope.model.name !== school.$$displayName ||
      utils.dateToIso($scope.model.startDate) !== school.startDate ||
      utils.dateToIso($scope.model.endDate) !== school.endDate;

    // proceed after notifying the user about some deletions will be done.
    const deleteBatch = [];
    $scope.proceed = async () => {
      $scope.loading = true;
      $scope.notificationOptions.enabled = false;
      try {
        await apiQueries.postBatch('/batch', deleteBatch);
        $scope.close('deleted');
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, deleteBatch));
      } finally {
        $scope.loading = false;
      }
    };

    $scope.warn = async () => {
      $scope.loading = true;
      deleteBatch.splice(0, deleteBatch.length);

      deleteBatch.push({
        href: school.$$meta.permalink,
        verb: 'DELETE'
      });
      const dependencies = await dateManager.manageDeletesForSchool(school, deleteBatch);
      const numberOfCampuses = dependencies ? dependencies.locations.length : 0;
      const numberOfAgs = dependencies ? dependencies.epds.length : 0;
      const numberOfClasses = dependencies ? dependencies.classes.length : 0;
      utils.notify($scope, 'warning', 'warning',
        (numberOfCampuses + numberOfAgs + numberOfClasses > 0 ? 'Opgelet, er '+ (numberOfCampuses + numberOfAgs + numberOfClasses > 1 ? 'zijn ' : 'is ') + numberOfCampuses + ' campus' + (numberOfCampuses > 1 ? 'sen ' : ' ') +
          (numberOfClasses > 0 ? ', ' : 'en ') + (numberOfAgs > 0 ? numberOfAgs + ' AG' + (numberOfAgs > 1 ? '\'s ' : ' ') : '') +
          (numberOfClasses > 0 ? 'en ' + numberOfClasses + ' klas' + (numberOfClasses > 1 ? 'sen ' : ' ') : '') +
          'gelinkt aan deze schoolentiteit die ook verwijderd ' + (numberOfCampuses + numberOfAgs > 1 ? 'zullen' : 'zal') + ' worden. ' : '') +
        'Ben je zeker dat je deze schoolentiteit wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.' +
        'Selecteer \'Bevestigen\' om de schoolentiteit ' + (numberOfCampuses > 0 ? 'met zijn campus' + (numberOfCampuses > 1 ? 'sen ' : ' ') : '') + (numberOfAgs > 0 ? 'en zijn aanbod ' : '') + (numberOfClasses > 0 ? 'en zijn klassen ' : '') + 'definitief te verwijderen.');
      $scope.warning = true;
      $scope.loading = false;
    };
  }
];
