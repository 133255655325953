const loadMore = angular.module('loadMore', []);

loadMore.directive('loadMore', [() => {
  'use strict';
  return {
    restrict: 'E',
    template: require('./loadMore.html'),
    transclude: true,
    scope: {
      model: '=ngModel',
      more: '=loadFunction',
      container: '=?container'
    },
    link: ($scope) => {
      $scope.loadMore = async () => {

        if ($scope.container) {
          const ulElement = angular.element('#' + $scope.container)[0].querySelector('ul');
          ulElement.scrollTop = ulElement.scrollHeight;
        }
        await $scope.more.call();
      };
    }
  };
}]);
