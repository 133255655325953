let physicalLocation = angular.module('physicalLocation', []);

physicalLocation.directive('physicalLocation', ['apiQueries', '$timeout', 'dateUtils',
  (apiQueries, $timeout, dateUtils) => {
    'use strict';
    return {
      restrict: 'E',
      template: require('./physicalLocation.html'),
      transclude: true,
      scope: {
        model: '=ngModel',
        externalStartDate: '=?',
        datepickerOptions: '=?'
      },

      link: async ($scope) => {

        $scope.physicalLocationForm = {
        };

        $scope.showStartDate = $scope.externalStartDate ? false : true;

        if (!$scope.model.address) {
          $scope.model.address = {};
        }

        // set 'Belgium' default country
        $scope.model.countryName = 'Belgium';
        $scope.model.address.country = 'be';
        $scope.model.address.countryHref = '/sam/commons/countries/be';

        if (!$scope.model.startDate) {
          $scope.model.startDate = new Date();
        }

        const getCityKey = async () => {
          if (!$scope.model.address.zipCode) {
            return undefined;
          }

          const subcities = await apiQueries.getResources('/sam/commons/subcities', {
            zipCodesContains: $scope.model.address.zipCode}, {
            expand: 'city'
          });

          if (subcities.length > 0) {
            $scope.model.address.cityHref = subcities[0].city.$$expanded.$$meta.permalink;
            return subcities[0].city.href.split('/sam/commons/cities/').pop();
          }

          return undefined;
        };

        let cityKey = await getCityKey(),
          zipCode = $scope.model.address.zipCode,
          zipCodeTimeout;

        // observe changes in zipCode
        const refreshSelectableInputs = async () => {
          $scope.model.address.houseNumber = undefined;
          $scope.model.address.street = undefined;
          $scope.model.address.streetHref = undefined;

          const subcities = await apiQueries.getResources('/sam/commons/subcities', {
            zipCodesContains: $scope.model.address.zipCode}, {
            expand: 'city'
          });

          if (subcities.length > 0) {
            cityKey = subcities[0].city.href.split('/sam/commons/cities/').pop();
            $scope.model.address.city = subcities[0].city.$$expanded.name;
            $scope.model.address.cityHref = subcities[0].city.$$expanded.$$meta.permalink;
            if (subcities.length === 1) {
              $scope.model.address.subCity = subcities[0].name;
            }
          } else {
            $scope.model.address.city = undefined;
            $scope.model.address.cityHref = undefined;
            $scope.model.address.subCity = undefined;
            $scope.model.address.subCityHref = undefined;
          }

          await $scope.refreshSubCities();
          await $scope.refreshCities();
        };

        // selectable inputs for addresses
        $scope.refreshStreets = async (street) => {
          let params = {
            nameContains: street,
            endDateAfter: dateUtils.getNow()
          };

          if (cityKey) {
            params.city = '/sam/commons/cities/' + cityKey;
          }

          const streets = await apiQueries.getResources('/sam/commons/streets', params);

          return streets.length === 0 ? [{name: street, href: null}] :
            streets.map(s => {
              return {name: s.name, href: '/sam/commons/streets/' + s.key};
            });
        };

        $scope.refreshSubCities = async (subCity) => {

          let params = {};

          if (subCity) {
            params.nameContains = subCity;
          }

          if (zipCode) {
            params.zipCodesContains = zipCode;
          }

          const subcities = await apiQueries.getResources('/sam/commons/subcities', params);
          return subcities.length === 0 ? [{name: subCity, href: null}] :
            subcities.map(s => {
              return {name: s.name, href: '/sam/commons/subcities/' + s.key};
            });
        };

        $scope.refreshCities = async (city) => {

          let params = {
            languagesContains: 'nl'
          };

          if (city) {
            params.nameContains = city;
          }
          if (cityKey) {
            params.key = cityKey;
          }
          const cities = await apiQueries.getResources('/sam/commons/cities', params);
          return cities.length === 0 ? [city] : cities.map(c => c.name);
        };

        $scope.refreshCountries = async (country) => {
          var params = {
            nameContains: country
          };

          const countries = await apiQueries.getResources('/sam/commons/countries', params);
          return countries.length === 0 ? [{name: country, href: null}] :
            countries.map(c => {
              return {name: c.name, href: '/sam/commons/countries' + c.key};
            });
        };

        // watch zipcode changes after initialization
        $scope.$watch('model.address.zipCode', (newZipCode, oldZipCode) => {
          if (newZipCode !== oldZipCode) {
            cityKey = zipCode = undefined;
            if (zipCodeTimeout) {
              $timeout.cancel(zipCodeTimeout);
            }

            zipCodeTimeout = $timeout(async () => {

              if (/^\d+$/.test(newZipCode)) {
                zipCode = newZipCode;
                refreshSelectableInputs();
              }
            }, 250); // delay 250 ms
          }
        });

        $scope.onStreetSelect = async ($item) => {
          if ($item.href) {

            const params = {
              name: $item.name
            };

            if (cityKey) {
              params.city = '/sam/commons/cities/' + cityKey;
            }

            const streets = await apiQueries.getResources('/sam/commons/streets', params);

            $scope.model.address.streetHref = streets.length === 1 ? $item.href : undefined;
          } else {
            $scope.model.address.streetHref = undefined;
          }
        };

        $scope.onSubCitySelect = ($item) =>
          $scope.model.address.subCityHref = $item.href;
        
        $scope.onCitySelect = ($item) =>
          $scope.model.address.cityHref = $item.href;

        $scope.onCountrySelect = ($item) => {
          if ($item.name !== 'Belgium') {
            refreshSelectableInputs();
            $scope.model.zipCode = undefined;
            $scope.model.address.countryHref = $item.href;
          }
        };

      }
    };
  }]);
