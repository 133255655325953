const organisationalunitNames = angular.module('organisationalunitNames', []);

organisationalunitNames.directive('organisationalunitNames', [
  '$timeout', 'Handsontable', ($timeout, Handsontable) => {
    'use strict';
    return {
      restrict: 'E',
      template: require('./organisationalunitNames.html'),
      transclude: true,
      scope: {
        model: '=ngModel'
      },

      link: ($scope) => {

        const init = () => {
          const nameListElement = document.getElementById('nameList');
          const nameListSettings = {
            data: _.cloneDeep($scope.model.names),
            columns: [
              {
                data: 'type',
                type: 'autocomplete',
                source: ['OFFICIAL', 'SHORT', 'CALL', 'OTHER']
              },
              {
                data: 'value',
                type: 'text'
              }, {
                data: 'startDate',
                type: 'date',
                dateFormat: 'YYYY-MM-DD'
              }, {
                data: 'endDate',
                type: 'date',
                dateFormat: 'YYYY-MM-DD',
                allowEmpty: true
              }
            ],
            contextMenu: true,
            stretchH: 'all',
            rowHeaders: false,
            allowInsertRow: true,
            allowRemoveRow: true,
            maxRows: 5,
            removeRowPlugin: true,
            colHeaders: [
              'Type',
              'Name',
              'startDate',
              'endDate'
            ]
          };

          $scope.model.nameTable = new Handsontable(nameListElement, nameListSettings);

          $scope.model.nameTable.updateSettings({
            afterChange: (changes) => {
              const previousValue = changes[0][2],
                newValue = changes[0][3];

              if (previousValue === newValue) {
                return;
              }

              const sourceNames = $scope.model.names,
                modifiedNames = $scope.model.nameTable.getSourceData();

              const difference = _.differenceWith(_.orderBy(modifiedNames, 'startDate'),
                _.orderBy(sourceNames, 'startDate'), _.isEqual);

              $scope.$applyAsync(() => $scope.model.namesChanged = difference && difference.length > 0);
            }

          });
        };

        $timeout(() => {
          init();
        });
      }
    };
  }
]);
