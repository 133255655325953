/* eslint-disable no-undef */
/* gobal angular, _ */

const schoolEntityTree = angular.module('schoolEntityTreeItem', []);
schoolEntityTree.directive('schoolEntityTreeItem', ['utils', 'dateUtils', function (utils, dateUtils) {
  'use strict';

  return {
    restrict: 'A',
    template: require('./schoolEntityTreeItem.html'),
    replace: true,
    transclude: true,
    scope: {
      select: '=',
      item: '=',
      foundKeys: '=',
      allowSelect: '=',
      closeCluster: '=',
      removeFromCluster: '=',
      editCluster: '=',
      addToCluster: '=',
      referenceDate: '='
    },
    link: ($scope) => {
      $scope.isFound = (key) => $scope.foundKeys.indexOf(key) > -1;

      $scope.formatAddress = (addresses) => {
        var formattedAddresses = [];
        _.each(_.uniqBy(addresses, 'key'), (addressItem) => {
          if (addressItem) {
            formattedAddresses.push(
              addressItem.address.street +
              (addressItem.address.houseNumber ? ' ' + addressItem.address.houseNumber : '') +
              (addressItem.address.mailboxNumber ? ' bus ' + addressItem.address.mailboxNumber : '') + ', '
              + addressItem.address.zipCode + ' ' + addressItem.address.city +
              (addressItem.address.subCity ? ' (' + addressItem.address.subCity + ')' : ''));
          }
        });
        return formattedAddresses.join(' - ');
      };

      $scope.getAvatarColor = (physicalLocationKey) => utils.generateColorFromString(physicalLocationKey);

      $scope.formatAddresses = (children) => {
        let locations = [];
        _.each(children, (child) => {
          locations = locations.concat(child.locations);
        });
        return $scope.formatAddress(locations);
      };

      $scope.$watch('item.isSelected', (n, o) => {
        if (n !== o) {
          $scope.select($scope.item, n);
        }
      });
    }
  };
}]);

module.exports = schoolEntityTree;
