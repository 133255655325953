/**
 * This defines your own module for this application. If you have other dependencies on other modules, add them here.
 *
 * Remark that we get the modulename from the settings object,
 * which makes it easier to update the modulename in one place !
 *
 * This configuration will be added to the application module as a constant also, so it can be used 'the angular way'
 * later on in your modules, controllers, directives (both the OAuth module and the RemoteErrorLogger
 * need some info from the 'configuration' ! )
 */

require('../config/settings.js');

//NPM Modules
require('@kathondvla/style');
require('angular-sanitize');
require('angular-animate');
require('@uirouter/angularjs');
require('angular-md5');
require('angulartics');
require('angulartics-google-analytics');
require('angular-uuid');
require('../../css/custom.css');
require('angular-aside');
require('angular-aside/dist/css/angular-aside.min.css');
require('handsontable/dist/handsontable.full.css');
require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('angular-ui-sortable');
require('angular-translate');
require('angular-translate-loader-static-files');

//VSKO Modules
require('../module/header');

//Custom Modules
require('../module/schoolEntityTree/schoolEntityTree');
require('../module/customFilter/customFilter');
require('../module/physicalLocation/physicalLocation');
require('../module/contactDetails/contactDetails');
require('../module/organisationalunitNames/organisationalunitNames');
require('../module/loadMore/loadMore');
require('../module/pager/pager');
require('../module/schoolLocations/schoolLocations');

//create the module
const app = angular.module(
  'VskoApplication',
  [
    'ng',
    'ngAnimate',
    'ui.router',
    'angular-md5',
    'angulartics',
    'angulartics.google.analytics',
    '@kathondvla/style',
    'angular-uuid',
    'ngAside',
    'header',
    'schoolEntityTree',
    'customFilter',
    'physicalLocation',
    'contactDetails',
    'organisationalunitNames',
    'loadMore',
    'pager',
    'schoolLocations',
    'ui.sortable',
    'pascalprecht.translate'
  ]
);

app.controller('HeadController', require('./HeadController.js'));
app.factory('errorManager', require('../screen/common/errorManager.js'));
app.factory('utils', require('../screen/common/utils.js'));
app.factory('apiQueries', require('../screen/common/apiQueries.js'));
app.factory('apiService', require('../screen/common/apiService.js'));
app.factory('dataService', require('../screen/common/dataService.js'));

//Custom filters for screens
app.filter('addressFilter', require('../screen/common/filters/addressFilter.js'));
app.filter('escapeFilter', require('../screen/common/filters/escapeFilter.js'));
app.filter('abolishedFilter', require('../screen/common/filters/abolishedFilter.js'));
app.filter('formatPhoneNumberFilter', require('../screen/common/filters/formatPhoneNumberFilter.js'));
app.filter('endDateFilter', require('../screen/common/filters/endDateFilter.js'));
app.filter('periodRangeFilter', require('../screen/common/filters/periodRangeFilter.js'));
app.filter('objectKeysLengthFilter', require('../screen/common/filters/objectKeysLengthFilter.js'));

//Controllers for screens
app.controller('schoolListController', require('../screen/schoolList/schoolListController.js'));
app.controller('schoolController', require('../screen/school/schoolController.js'));

module.exports = app;
