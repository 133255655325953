module.exports = ['$scope', '$uibModalInstance', '$log', 'apiService',
  'dateUtils', 'apiQueries', 'utils', 'location', 'uuid', 'errorManager',
  ($scope, $uibModalInstance, $log, apiService, dateUtils, apiQueries, utils,
    location, uuid, errorManager) => {
    'use strict';

    const dateManager = apiService.getDateManager();

    $scope.model = {
      plType: 'building',
      startDate: utils.isoToDate(location.startDate),
      endDate: utils.isoToDate(location.endDate),
      externalIds: '',
      name: location.name
    };

    $scope.warning = false;

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => {
      if ($scope.notificationOptions.enabled) {
        $scope.notificationOptions.enabled = false;
        $scope.warning = false;
      } else {
        $uibModalInstance.close(false);
      }
    };

    $scope.close = () => $uibModalInstance.close();

    $scope.getInternalIdentifier = async () => {
      $scope.model.externalIdValue = '';

      try {
        const onderwijskiezerIds = await apiQueries.getResources(
          '/sam/organisationalunits/locations/externalidentifiers', {
            location: location.$$meta.permalink,
            type: 'ONDERWIJSKIEZER_ID'});

        $scope.externalId = onderwijskiezerIds.length > 0 ? onderwijskiezerIds[0] : null;
        $scope.model.externalIdValue = $scope.externalId ? $scope.externalId.value : '';

      } catch (error) {
        utils.notify($scope, 'danger', 'warning', 'Can\'t get external identifier for given location. Error: ' + error);
      }
    };

    $scope.getInternalIdentifier();

    // update the proposed changes
    $scope.update = async () => {
      const batch = [],
        period = {
          startDate: dateUtils.toString($scope.model.startDate),
          endDate: dateUtils.toString($scope.model.endDate)
        };

      const locationToUpdate = _.extend({},
        location, {
          name: $scope.model.name,
          startDate: utils.dateToIso($scope.model.startDate),
          endDate: utils.dateToIso($scope.model.endDate)
        });

      try {

        errorManager.validatePeriod(period);

        if ($scope.model.externalIdValue !== ($scope.externalId ? $scope.externalId.value : '')) {
          const externalIdentifierKey = $scope.externalId ? $scope.externalId.key : uuid.v4();
          batch.push({
            href: '/sam/organisationalunits/locations/externalidentifiers/' + externalIdentifierKey,
            verb: 'PUT',
            body: {
              key: externalIdentifierKey,
              type: 'ONDERWIJSKIEZER_ID',
              value: $scope.model.externalIdValue,
              location: {href: location.$$meta.permalink}
            }
          });
        }

        if (period.startDate !== location.startDate ||
        period.endDate !== location.endDate ||
        $scope.model.name !== location.name || '') {
          batch.push({
            href: location.$$meta.permalink,
            verb: 'PUT',
            body: locationToUpdate
          });
        }

        if (period.startDate !== location.startDate ||
          period.endDate !== location.endDate) {
          await dateManager.manageDatesForSchoolLocation(locationToUpdate,
            batch,
            location.startDate,
            location.endDate, false);
        }

        await apiQueries.postBatch('/batch', batch);
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', errorManager.handleDateError(locationToUpdate, error.body));
      }
    };

    $scope.confirmDelete = () => {
      utils.notify($scope, 'danger', 'warning',
        'Are you sure you want to delete this building? This action cannot be undone. Press \'Proceed\' to continue,' +
        ' \'Cancel\' otherwise.');
      $scope.warning = true;
    };

    $scope.delete = async () => {
      try {
        await apiQueries.deleteResource(location.$$meta.permalink);
        $scope.close();
      } catch (error) {
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, [location.$$meta.permalink]));
      }
    };

    $scope.enableSave = () => {
      return utils.dateToIso($scope.model.startDate) &&
        utils.dateToIso($scope.model.startDate) !== location.startDate ||
        utils.dateToIso($scope.model.endDate) !== location.endDate ||
        $scope.model.externalIdValue !== ($scope.externalId ? $scope.externalId.value : '') ||
        $scope.model.name !== location.name || '';
    };
  }
];
