const customFilter = angular.module('customFilter', []);

customFilter.directive('customFilter', [
  '$q', '$timeout', '$log', 'apiQueries', ($q, $timeout, $log, apiQueries) => {
    'use strict';
    return {
      restrict: 'E',
      template: require('./customFilter.html'),
      transclude: true,
      scope: {
        model: '=ngModel',
        search: '=searchFunction',
        clear: '=?clearFunction',
        educationalLevelFilter: '=?',
        institutionNumberFilter: '=?',
        emailFilter: '=?',
        agFilter: '=?',
        referenceDateFilter: '=?'
      },
      link: ($scope) => {
        let cityKey;

        $scope.model.loadingSearch = false;

        $scope.showEducationalLevelFilter = $scope.educationalLevelFilter ? true : false;
        $scope.showEmailFilter = $scope.emailFilter ? true : false;
        $scope.showInstitutionNumberFilter = $scope.institutionNumberFilter ? true : false;
        $scope.showAgFilter = $scope.agFilter ? true : false;
        $scope.showReferenceDateFilter = $scope.referenceDateFilter ? true : false;

        $scope.clearFilter = async () => {
          $scope.model.searchValues = {
            name: '',
            street: '',
            zipCode: '',
            city: '',
            onderwijskiezer: '',
            institutionNumber: '',
            onderwijsniveau: '',
            email: '',
            agCode: '',
            referenceDate: new Date()
          };

          if ($scope.clear) {
            await $scope.clear.call();
          }
          await $scope.search.call();
        };

        $scope.searchCampus = async () => {
          $scope.model.loadingSearch = true;
          await $scope.search.call();
          $scope.model.loadingSearch = false;
        };

        $scope.isSearchEnabled = () => {
          return !$scope.model.loadingSearch &&
            Object.values($scope.model.searchValues).filter(value => value).length > 0;
        };

        var filterZipCodeTimeout;
        $scope.$watch('model.searchValues.zipCode', (newValue, oldValue) => {
          if (newValue !== oldValue) {
            if (filterZipCodeTimeout) {
              $timeout.cancel(filterZipCodeTimeout);
            }

            filterZipCodeTimeout = $timeout(() => {
              $scope.model.searchValues.city = '';
              $scope.model.searchValues.street = '';
            }, 250); // delay 250 ms
          }
        });

        $scope.refreshStreets = async (street) => {
          const params = {
            nameContains: street
          };

          if (cityKey) {
            params.city = '/sam/commons/cities/' + cityKey;
          }

          try {
            const matchedStreets = await apiQueries.getResources('/sam/commons/streets', params, {expand: 'city'});
            return matchedStreets.length === 0 ? [{name: street}] :
              matchedStreets.map(s => {
                return {name: s.name, city: s.city.$$expanded ? s.city.$$expanded.name : ''};
              });
          } catch(e) {
            $log.error(e);
          }

          return [{name: street}];
        };

        $scope.refreshCities = async (cityFilter) => {
          let params = {
            q: cityFilter
          };

          if ($scope.model.searchValues.zipCode) {
            params.zipCodesRegEx = '^' + $scope.model.searchValues.zipCode;
          }

          try {
            let subCitiesList = await apiQueries.getResources('/sam/commons/subcities', params, {expand: 'city'});

            let subCitiesAndCities = [],
              pattern = new RegExp(cityFilter, 'i');

            subCitiesAndCities = subCitiesList.map(s => {
            // if the city name is appended to the subcity name, then remove it
              const subCityName = s.name/*.split('(').shift().trim()*/,
                result = [];

              if (subCityName.match(pattern)) {
                result.push({name: subCityName, cityKey: s.city.href.split('/').pop()});
              }

              if (s.city.$$expanded.name.match(pattern)) {
                result.push({
                  name: s.city.$$expanded.name,
                  cityKey: s.city.href.split('/').pop()
                });
              }

              return result;
            });

            return subCitiesAndCities.length === 0 ?
              [{name: cityFilter, cityKey: ''}] :
              _.uniqBy([].concat.apply([], subCitiesAndCities), 'name');
          } catch(e) {
            $log.error(e);
          }

          return [{name: cityFilter, cityKey: ''}];
        };

        $scope.refreshCode = async (id) => {
          const matchedCodes =
            await apiQueries.getResources('/sam/organisationalunits', {
              codeRegEx: '^.*' + id + '.*$',
            });

          return matchedCodes.length === 0 ? [id] : matchedCodes.map(i => i.code).sort();
        };

        $scope.refreshOnderwijskiezer = async (id) => {
          const matchedIdentifiers =
            await apiQueries.getResources('/sam/organisationalunits/locations/externalidentifiers', {
              valueRegEx: '^' + id,
              type: 'ONDERWIJSKIEZER_ID'
            });

          return matchedIdentifiers.length === 0 ? [id] : matchedIdentifiers.map(i => i.value).sort();
        };

        $scope.refreshInstitutionNumbers = async (id) => {
          const matchedIdentifiers =
            await apiQueries.getResources('/sam/organisationalunits/externalidentifiers', {
              valueRegEx: '^' + id,
              type: 'INSTITUTION_NUMBER'
            });

          return matchedIdentifiers.length === 0 ? [id] : matchedIdentifiers.map(i => i.value).sort();
        };

        $scope.refreshEmails = async (id) => {
          const matchedContactDetails = await apiQueries.getResources('/sam/organisationalunits/contactdetails', {
            'value.emailRegEx': '^' + id,
            type: 'EMAIL'
          });
          return matchedContactDetails.length === 0 ? [id] : matchedContactDetails.map(i => i.value.email).sort();
        };

        $scope.onCitySelect = ($item) => {
          cityKey = $item.cityKey || '';
          $scope.model.searchValues.street = '';
        };

        $scope.onStreetSelect = ($item) => {
          $scope.model.searchValues.city = $item.city || '';
        };
      }
    };
  }]);
