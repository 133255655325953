/* eslint-disable no-undef */
const fetchSriClient = require('@kathondvla/sri-client/fetch-sri-client');

module.exports = ['apiService', 'pMap', 'dateUtils', 'settings', (apiService, pMap, dateUtils, settings) => {

  const ONE_DAY = 60 * 60 * 24;
  const FIVE_MINUTES = 60 * 5;
  const MAX_LIMIT = 5000;

  const api = apiService.getApi();

  const getResource = async (href, parameters, options) =>
    await api.get(href, parameters, options);

  const putResource = async (href, params, options) =>
    await api.put(href, params, options);

  const getAll = async (href, parameters, options) =>
    await api.getAll(href, parameters, options);

  const getResources = async (href, params, options) => {
    if (params && 'limit' in params) {
      return await api.getList(href, params, options);
    }

    return await api.getAll(href, _.assignIn(params, {
      limit: MAX_LIMIT
    }),
      options);
  };

  const getAg = async (href, params, options) =>
    await getResource(href, params, options);

  const getAgs = async (params, options) =>
    await getResources('/sam/commons/ags', params, options);

  const getBuoType = async (href, params, options) =>
    await getResource(href, params, options);

  const getBuoTypes = async (params, options) =>
    await getResources('/sam/commons/buoTypes', params, options);

  const postBatch = async (href, batch, options = {}) =>
    await api.post(href, batch, options);

  const deleteResource = async (href, options = {}) =>
    await api.delete(href, options);

  const getAllHrefs = async (hrefs, batchHref, parameters, options) =>
    await api.getAllHrefs(hrefs, batchHref, parameters, options);

  const getAllReferencesTo = async (baseHref, params, referencingParameterName, hrefsArray, options) =>
    await api.getAllReferencesTo(baseHref, params, referencingParameterName, hrefsArray, options);

  const getPhysicalLocation = async (href, params, options) =>
    await getResource(href, params, options);

  const getPhysicalLocations = async (params, options) =>
    await getResources('/sam/physicallocations', params, options);

  const putPhysicalLocation = async (params, options) =>
    await api.put('/sam/physicallocations/' + params.key, params, options);

  const getOrganisationalUnit = async (href, params, options) =>
    await getResource(href, params, options);

  const putOrganisationalUnit = async (params, options) =>
    await api.put('/sam/organisationalunits/' + params.key, params, options);

  const getOrganisationalUnits = async (params, options) =>
    await getResources('/sam/organisationalunits', params, options);

  const getOrganisationalUnitsContactDetails = async (params, options) =>
    await getResources('/sam/organisationalunits/contactdetails', params, options);

  const putOrganisationalUnitsRelation = async (params, options) =>
    await api.put('/sam/organisationalunits/relations/' + params.key, params, options);

  const getOrganisationalUnitsRelations = async (params, options) =>
    await getResources('/sam/organisationalunits/relations', params, options);

  const getOrganisationalUnitsExternalIdentifiers = async (params, options) =>
    await getResources('/sam/organisationalunits/externalidentifiers', params, options);

  const getOrganisationalUnitsLocations = async (params, options) =>
    await getResources('/sam/organisationalunits/locations', params, options);

  const getOrganisationalUnitsLocationsExternalIdentifiers = async (params, options) =>
    await getResources('/sam/organisationalunits/locations/externalidentifiers', params, options);

  const getEducationalProgrammeDetail = async (href, params, options) =>
    await getResource(href, params, options);

  const getEducationalProgrammeDetailsForAgs = async (params, options) =>
    await getResources('/sam/educationalprogrammedetails', Object.assign({}, params, { hasAg: true }), options);

  const getEducationalProgrammeDetailsForMainstructures = async (params, options) =>
    await getResources('/sam/educationalprogrammedetails', Object.assign({}, params, { hasMainstructure: true }), options);

  const getEducationalProgrammeDetailLocation = async (href, params, options) =>
    await getResource(href, params, options);

  const getEducationalProgrammeDetailsLocations = async (params, options) =>
    await getResources('/sam/educationalprogrammedetails/locations', params, options);

  const getEducationalProgrammeDetailsLocationsRelations = async (params, options) =>
    await getResources('/sam/educationalprogrammedetails/locations/relations', params, options);

  const getParentRelations = async (children, referenceDate) => {
    const parentRelations = await getOrganisationalUnitsRelations({
      startDateBefore: referenceDate,
      endDateAfter: dateUtils.getNextDay(referenceDate),
      from: children.join(','),
      type: 'IS_PART_OF',
      'to.type': 'SCHOOLENTITY_CLUSTER',
      expand: 'results.to'
    }, {
      inBatch: '/batch'
    });

    return parentRelations;
  };

  const getRootParent = (childHref, parentMap) => {
    if (!(childHref in parentMap)) {
      return childHref;
    }

    return getRootParent(parentMap[childHref], parentMap);
  };

  const getRootParents = async (children, referenceDate) => {
    const parentMap = {};

    let parentRelations = await getParentRelations(children.map(child => child.$$meta.permalink), referenceDate);
    parentRelations.forEach(parentRelation => parentMap[parentRelation.from.href] = parentRelation.to.href);

    let newChildren = [... new Set(Object.values(parentMap))];

    while (parentRelations.length > 0) {
      parentRelations = await getParentRelations(newChildren, referenceDate);

      newChildren = [];
      parentRelations.forEach(parentRelation => {
        parentMap[parentRelation.from.href] = parentRelation.to.href;

        if (!newChildren.includes(parentRelation.to.href)) {
          newChildren.push(parentRelation.to.href);
        }
      });
    }

    return children.map(child => getRootParent(child.$$meta.permalink, parentMap));
  };

  const getSchoolEntityTreeRoots = async (referenceDate, limit, offset) => {
    let schoolEntities = await getOrganisationalUnits({
      startDateBefore: referenceDate,
      endDateAfter: dateUtils.getNextDay(referenceDate),
      typeIn: 'SCHOOLENTITY,SCHOOLENTITY_CLUSTER',
      hierarchyLevel: 'TOP',
      _date: referenceDate,
      limit: limit,
      offset: offset
    }, {
        inBatch: '/sam/organisationalunits/batch',
        include: [{
          alias: '$$parents',
          href: '/sam/organisationalunits/relations',
          filters: {
            type: 'IS_PART_OF',
            startDateBefore: referenceDate,
            endDateAfter: dateUtils.getNextDay(referenceDate),
            'to.type': 'SCHOOLENTITY_CLUSTER'
          },
          reference: 'from',
          expand: 'to'
        }]
      });

    schoolEntities =
      schoolEntities.filter(schoolEntity => schoolEntity.$$parents.length === 0);

    return schoolEntities;
  };

  const filterSchoolsByNameOrCodeOrEducationalLevel = async (ouTypes, searchValues, referenceDate) => {

    let filter = {
      startDateBefore: referenceDate,
      endDateAfter: dateUtils.getNextDay(referenceDate),
      typeIn: ouTypes.join(',')
    };

    if (searchValues.name) {
      filter['names.value'] = searchValues.name;
    }

    if (searchValues.code) {
      filter.code = searchValues.code;
    }

    if (searchValues.onderwijsniveau) {
      filter.havingMainstructure = searchValues.onderwijsniveau.toLowerCase() === 'basisonderwijs' ?
        '111,121,211,221' : '311,312,314,316,321';
      filter._date = referenceDate;
      filter.typeIn = ouTypes.join(',');
    }

    const schoolEntitiesByName = await getOrganisationalUnits(filter);

    return schoolEntitiesByName.map(schoolEntity => schoolEntity.key);
  };

  const filterLocationsByExternalIdentifiers = async (onderwijskiezer, referenceDate) => {

    let campusesByExternalIdentifiers = await getOrganisationalUnitsLocationsExternalIdentifiers({
      value: onderwijskiezer,
      type: 'ONDERWIJSKIEZER_ID',
      expand: 'results.location'
    });

    if (campusesByExternalIdentifiers.length === 0) {
      return [];
    }

    campusesByExternalIdentifiers = campusesByExternalIdentifiers.filter(
      campusExternalIdentifier => campusExternalIdentifier.location.$$expanded.startDate <= referenceDate &&
      dateUtils.isAfter(campusExternalIdentifier.location.$$expanded.endDate, referenceDate));

    return campusesByExternalIdentifiers.length === 0 ? undefined :
    campusesByExternalIdentifiers[0].location.href.split('/').pop();
  };

  const filterSchoolEntitiesByInstitutionNumber = async (institutionNumber, referenceDate) => {

    const matchingGovernors = await getOrganisationalUnitsExternalIdentifiers({
      value: institutionNumber,
      type: 'INSTITUTION_NUMBER'
    });

    const governorRelations = await getOrganisationalUnitsRelations({
      fromIn: matchingGovernors.map(governor => governor.organisationalUnit.href.split('/').pop()).join(','),
      type: 'GOVERNS',
      startDateBefore: referenceDate,
      endDateAfter: dateUtils.getNextDay(referenceDate),
      'to.type': 'SCHOOLENTITY'
    });

    return governorRelations.map(relation => relation.to.href.split('/').pop());
  };

  const filterSchoolEntitiesByEmail = async (email) => {

    const schoolEntitiesByEmail = await getOrganisationalUnitsContactDetails({
      'value.emailContains': email,
      type: 'EMAIL'
    });

    return schoolEntitiesByEmail.map(contact => contact.organisationalUnit.href.split('/').pop());
  };

  const filterLocationsByAddress = async (locationType, addressFilter) => {

    let physicalLocations;

    let params = {
      type: locationType
    };

    for (let key of Object.keys(addressFilter)) {
      params['address.' + key + 'Contains'] = addressFilter[key];
    }

    physicalLocations = await getPhysicalLocations(params);

    if (physicalLocations.length === 0 && addressFilter.city) {
      const matchedSubCities = await getResources('/sam/commons/subcities', {
        nameContains: addressFilter.city
      });

      if (matchedSubCities.length > 0) {
        params['address.subCityHref'] = matchedSubCities.map(matchedSubCity => matchedSubCity.$$meta.permalink);
        delete params['address.cityContains'];
        physicalLocations = await getPhysicalLocations(params);
      }
    }

    return physicalLocations;
  };

  const filterSchoolEntitiesByAgCode = async (agCode, referenceDate) => {
    const agHrefs = await getAgs({
      code: agCode
    });

    if (agHrefs.length === 0) {
      return [];
    }

    let schoolEntitiesByAg = await getEducationalProgrammeDetailsForAgs({
      'organisationalUnit.type': 'SCHOOLENTITY', 
      ag: agHrefs[0].$$meta.permalink,
    });

    if (schoolEntitiesByAg.length === 0) {
      return [];
    }

    schoolEntitiesByAg = schoolEntitiesByAg.filter(
      educationProgrammeDetail => educationProgrammeDetail.startDate <= referenceDate &&
      dateUtils.isAfter(educationProgrammeDetail.endDate, referenceDate));

    return schoolEntitiesByAg.length === 0 ? undefined :
      schoolEntitiesByAg.map(educationProgrammeDetail => educationProgrammeDetail.organisationalUnit.href.split('/').pop());
  };

  const filterLocations = async (oyType, seKeys, physicalLocations, locationKey, addressFilter, referenceDate) => {

    let locationParams = {
        startDateBefore: referenceDate,
        endDateAfter: dateUtils.getNextDay(referenceDate),
        'organisationalUnit.type': oyType,
        expand: 'NONE'
      };

    if (locationKey) {
      locationParams.key = locationKey;
    }

    if (Object.keys(addressFilter).length === 0 &&
      locationParams.length === 0 &&
      seKeys.length === 0) {
      return [];
    }

    if (seKeys.length > 0) {
      locationParams.organisationalUnitIn = seKeys.join(',');
    }

    if (physicalLocations.length > 0) {
      locationParams.physicalLocationIn = physicalLocations.map(pl => pl.key).join(',');
    }

    return await getOrganisationalUnitsLocations(locationParams, {
      inBatch: '/batch'
    });
  };

  const getSchoolEntityTreeLeaves = async (searchValues, limit, offset) => {

    let referenceDate = dateUtils.toString(searchValues.referenceDate),
      physicalLocations = [],
      seKeys = [],
      locationKey,
      addressFilter = _.pickBy(_.pick(searchValues, ['street', 'zipCode', 'city']));


    if (searchValues.name || searchValues.code || searchValues.onderwijsniveau) {
      seKeys = await filterSchoolsByNameOrCodeOrEducationalLevel(['SCHOOLENTITY', 'SCHOOLENTITY_CLUSTER'], searchValues, referenceDate);

      if (seKeys.length === 0) {
        return [];
      }
    }

    seKeys = [...new Set(seKeys)];

    if (searchValues.onderwijskiezer) {
      locationKey = await filterLocationsByExternalIdentifiers(searchValues.onderwijskiezer, referenceDate);

      if (!locationKey) {
        return [];
      }
    }

    if (searchValues.institutionNumber) {
      const governedSchoolEntities = await filterSchoolEntitiesByInstitutionNumber(searchValues.institutionNumber, referenceDate);

      if (seKeys.length > 0) {
        seKeys = seKeys.filter(seKey => governedSchoolEntities.includes(seKey));
      } else {
        seKeys = governedSchoolEntities;
      }

      if (governedSchoolEntities.length === 0 || seKeys.length === 0) {
        return [];
      }
    }

    if (searchValues.email) {
      const schoolEntitiesByEmail = await filterSchoolEntitiesByEmail(searchValues.email);

      if (seKeys.length > 0) {
        seKeys = seKeys.filter(seKey => schoolEntitiesByEmail.includes(seKey));
      } else {
        seKeys = schoolEntitiesByEmail;
      }

      if (schoolEntitiesByEmail.length === 0 || seKeys.length === 0) {
        return [];
      }
    }

    if (Object.keys(addressFilter).length > 0) {
      physicalLocations = await filterLocationsByAddress('DOMAIN', addressFilter, searchValues);

      if (physicalLocations.length === 0) {
        return [];
      }
    }

    if (searchValues.agCode) {
      const schoolEntitiesWithAg = await filterSchoolEntitiesByAgCode(searchValues.agCode, referenceDate);

      if (seKeys.length > 0) {
        seKeys = seKeys.filter(seKey => schoolEntitiesWithAg.includes(seKey));
      } else {
        seKeys = schoolEntitiesWithAg;
      }

      if (schoolEntitiesWithAg.length === 0 || seKeys.length === 0) {
        return [];
      }
    }

    let campuses = await filterLocations('SCHOOLENTITY', seKeys, physicalLocations, locationKey, addressFilter, referenceDate);
    const campusHrefs = campuses.map(campus => campus.href);
    campuses = await getAllHrefs(campusHrefs, '/sam/organisationalunits/locations/batch', {
      expand: 'results.organisationalUnit,results.physicalLocation'
    });

    let schoolEntitiesWithDoubles = campuses.map(campus => campus.$$expanded.organisationalUnit.$$expanded);
    schoolEntitiesWithDoubles = _.uniqBy(schoolEntitiesWithDoubles, 'key');

    if (physicalLocations.length === 0 && !searchValues.onderwijskiezer) {
      for (let seKey of seKeys) {
        if (!schoolEntitiesWithDoubles.find(schoolEntity => schoolEntity.key === seKey)) {
          schoolEntitiesWithDoubles.push(await getOrganisationalUnit('/sam/organisationalunits/' + seKey));
        }
      }
    }

    const rootParents = await getRootParents(schoolEntitiesWithDoubles, referenceDate);

    schoolEntitiesWithDoubles.forEach((schoolEntity, i) => schoolEntity.$$rootParent = rootParents[i]);
    schoolEntitiesWithDoubles = _.sortBy(schoolEntitiesWithDoubles, schoolEntity => schoolEntity.$$rootParent);

    schoolEntitiesWithDoubles = schoolEntitiesWithDoubles.slice(offset, offset + limit);

    return schoolEntitiesWithDoubles;
  };

  const getRelatedSchoolRelations = async (schoolEntityHref, referenceDate) => {
    return api.getAll(
      '/sam/organisationalunits/relations',
      {
        from: schoolEntityHref,
        type: 'CAN_ACT_AS',
        endDateAfter: referenceDate,
        startDateBefore: referenceDate
      }
    );
  };

  const getClbs = async (schoolEntityHref, referenceDate) => {
    const relatedSchoolRelations = await getRelatedSchoolRelations(schoolEntityHref, referenceDate);
    if (relatedSchoolRelations.length === 0) {
      return [];
    }
    const relatedClbRels = await api.getAll('/sam/organisationalunits/relations', {
      to: relatedSchoolRelations.map(rel => rel.to.href),
      type: 'PROVIDES_SERVICES_TO',
      endDateAfter: referenceDate,
      startDateBefore: referenceDate
    });
    if (relatedClbRels.length === 0) {
      return [];
    }
    const clbs = await api.getAll('/sam/organisationalunits', { hrefs: relatedClbRels.map(rel => rel.from.href) });
    return clbs;
  };

  function getLoginUrl() {
    const url = new URL(settings.oauth.authenticate);
    const params = new URLSearchParams({
      scope: settings.oauth.scope,
      response_type: 'none',
      client_id: settings.oauth.clientID,
      redirect_uri: settings.oauth.redirectUri,
      state: window.location.origin
    });
    url.search = params.toString();
    return url;
  }

  const oauthClient = fetchSriClient({ baseUrl: settings.oauth.oauthURL });
  const getUser = async () => {
    try {
      const user = await oauthClient.get('/me', undefined, { credentials: 'include' });
      return user;
    } catch (e) {
      console.log('not logged in', e);
      // window.location.href = getLoginUrl().href;
      return null;
    }
  };

  const logOut = async () => {
    await oauthClient.get('', undefined, { baseUrl: settings.oauth.logOut, credentials: 'include' });
    window.location.href = getLoginUrl().href;
  };

  return {
    MAX_LIMIT,
    ONE_DAY,
    FIVE_MINUTES,

    getUser,
    logOut,

    getResource,
    putResource,
    getResources,

    postBatch,
    deleteResource,

    getAll,
    getAllHrefs,
    getAllReferencesTo,

    getAg,
    getAgs,

    getBuoType,
    getBuoTypes,

    getPhysicalLocation,
    getPhysicalLocations,
    putPhysicalLocation,

    getOrganisationalUnit,
    putOrganisationalUnit,
    getOrganisationalUnits,

    getOrganisationalUnitsContactDetails,

    getOrganisationalUnitsRelations,
    putOrganisationalUnitsRelation,

    getOrganisationalUnitsExternalIdentifiers,

    getOrganisationalUnitsLocations,

    getOrganisationalUnitsLocationsExternalIdentifiers,

    getEducationalProgrammeDetail,
    getEducationalProgrammeDetails: getEducationalProgrammeDetailsForAgs,
    getEducationalProgrammeDetailsForMainstructures,

    getEducationalProgrammeDetailLocation,
    getEducationalProgrammeDetailsLocations,

    getEducationalProgrammeDetailsLocationsRelations,

    getRelatedSchoolRelations,
    getClbs,

    getSchoolEntityTreeRoots,
    getSchoolEntityTreeLeaves,

    filterSchoolsByNameOrEducationalLevel: filterSchoolsByNameOrCodeOrEducationalLevel,
    filterLocationsByExternalIdentifiers,
    filterSchoolEntitiesByInstitutionNumber,
    filterSchoolEntitiesByEmail,
    filterLocationsByAddress,
    filterLocations
  };
}];
