module.exports = ['$scope', '$uibModalInstance', 'educationalProgrammeDetailLocation', '$log',
  'apiQueries', '$timeout', 'Handsontable', 'errorManager', 'utils',
  ($scope, $uibModalInstance, educationalProgrammeDetailLocation, $log, apiQueries, $timeout, Handsontable,
    errorManager, utils) => {
    'use strict';

    $scope.model = {
      educationalProgrammeDetailLocation: educationalProgrammeDetailLocation,
      buoType: educationalProgrammeDetailLocation.educationalProgrammeDetail.$$expanded.buoType,
      ag: educationalProgrammeDetailLocation.educationalProgrammeDetail.$$expanded.ag.$$expanded,
      relationListSettings: {
        rowHeaders: false,
        colHeaders: true,
        stretchH: 'all'
      },
      relationList: educationalProgrammeDetailLocation.$$relations
    };

    const addressAsString = (instance, td, row, col, prop, value) => {
      const displayName = value.educationalProgrammeDetail.$$expanded.organisationalUnit.$$expanded.$$displayName;
      const address = value.physicalLocation.$$expanded.address;
      td.textContent = utils.physicalLocationToString(address, displayName + ' -');
      return td;
    };

    const init = () => {
      const relationListElement = document.getElementById('relationList');
      const relationListSettings = {
        data: _.cloneDeep($scope.model.relationList),
        columns: [{
          data: 'to.$$expanded',
          type: 'text',
          readOnly: true,
          renderer: addressAsString
        }, {
          data: 'startDate',
          type: 'date',
          dateFormat: 'YYYY-MM-DD',
          className: "htCenter htMiddle"
        }, {
          data: 'endDate',
          type: 'date',
          dateFormat: 'YYYY-MM-DD',
          allowEmpty: true,
          className: "htCenter htMiddle"
        },
        {
          type: 'checkbox',
          allowEmpty: true,
          className: "htCenter htMiddle"
        }],
        colWidths: ['60%', '15%', '15%', '10%'],
        manualColumnResize: false,
        stretchH: 'all',
        rowHeaders: false,
        allowInsertRow: false,
        allowRemoveRow: false,
        autoColumnSize: true,
        colHeaders: [
          'School - Location',
          'startDate',
          'endDate',
          'Delete'
        ]
      };

      $scope.model.relationTable = new Handsontable(relationListElement, relationListSettings);

      $scope.model.relationTable.updateSettings({
        afterChange: (changes) => {
          const row = changes[0][0],
            column = changes[0][1],
            previousValue = changes[0][2],
            newValue = changes[0][3];

          if (previousValue === newValue) {
            return;
          }

          $scope.$applyAsync(() => {
            $scope.model.modified =
              column === 3 && newValue === true ||
              column !== 3 && $scope.model.relationList[row][column] !==
                (newValue === '' ? undefined : newValue);
          });
        }
      });
    };

    $timeout(() => {
      init();
    });

    $scope.warning = false;
    $scope.modified = false;
    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => {
      if ($scope.notificationOptions.enabled) {
        $scope.notificationOptions.enabled = false;
        $scope.warning = false;
      } else {
        $uibModalInstance.close(false);
      }
    };

    $scope.close = () => $uibModalInstance.close();

    $scope.save = async () => {

      const dataTable = $scope.model.relationTable.getSourceData();

      const batch = [];

      try {

      // if some deletion should be perfomed, warn the user before proceeding
        if (_.some(dataTable, relation => relation[3]) && !$scope.warning) {
          $scope.warn();
          return;
        }

        for(let row = 0; row < dataTable.length; row++) {
          const relation = $scope.model.relationList[row],
            newStartDate = dataTable[row].startDate,
            newEndDate = dataTable[row].endDate || undefined;

          if (dataTable[row][3]) {
            batch.push({
              href: relation.$$meta.permalink,
              verb: 'DELETE'
            });
          } else if (newStartDate !== relation.startDate || newEndDate !== relation.endDate) {
            const relationToUpdate = _.extend({}, relation, {startDate: newStartDate, endDate: newEndDate});

            errorManager.validatePeriod(relationToUpdate);
            batch.push({
              href: relation.$$meta.permalink,
              verb: 'PUT',
              body: relationToUpdate
            });
          }
        }

        $scope.loadingSave = true;
        await apiQueries.postBatch('/sam/educationalprogrammedetails/batch', batch);
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      } finally {
        $scope.loadingSave = false;
      }
    };

    $scope.warn = () => {
      utils.notify($scope, 'warning', 'warning', 'Ben je zeker dat je de geselecteerde relaties met ' +
        'de offici\u00eble structuur wilt verwijderen? Selecteer \'Bevestigen\' om verder te gaan.');
      $scope.warning = true;
    };
  }
];
