module.exports = ['utils', (utils) => {
  'use strict';
  return (locations, prefix) => {

    if (!locations) {
      return '';
    }

    if (Array.isArray(locations)) {
      return locations.map(location =>
        utils.physicalLocationToString(location.addresss || location.value, prefix)).join('; ');
    }

    return utils.physicalLocationToString(locations.zipCode ? locations : locations.addresss, prefix);
  };
}];
