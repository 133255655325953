let schoolLocations = angular.module('schoolLocations', []);

schoolLocations.directive('schoolLocations', [() => {
  'use strict';
  return {
    restrict: 'E',
    template: require('./schoolLocations.html'),
    transclude: true,
    scope: {
      model: '=ngModel',
      more: '=loadFunction',
      onCheckboxClick: '=?checkboxOnClickFunction'
    }
  };
}]);
