module.exports = ['$scope', '$uibModalInstance', '$log', 'apiService',
  'utils', 'schoolEntityInfo', 'dateUtils', 'errorManager',
  ($scope, $uibModalInstance, $log, apiService, utils, schoolEntityInfo, dateUtils, errorManager) => {
    'use strict';

    const dateManager = apiService.getDateManager();

    const activeParent = schoolEntityInfo.schoolEntity.$$activeParent;

    $scope.model = {
      schoolEntityName: schoolEntityInfo.schoolEntity.$$displayName,
      startDate: dateUtils.parse(activeParent.startDate),
      endDate: dateUtils.parse(activeParent.endDate)
    };

    $scope.warned = false;

    $scope.cancel = () => $uibModalInstance.close(false);
    $scope.close = () => $uibModalInstance.close();

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.enableSave = () =>
      dateUtils.toString($scope.model.startDate) !== activeParent.startDate ||
      dateUtils.toString($scope.model.endDate) !== activeParent.endDate;

    $scope.save = async () => {
      $scope.notificationOptions.enabled = false;
      $scope.optionChosen = 'close';

      const parent = activeParent.to.$$expanded;

      const batch = apiService.createBatch();

      try {

        const newStartDate = dateUtils.toString($scope.model.startDate);
        const newEndDate = dateUtils.toString($scope.model.endDate) || undefined;

        const newParentRelation = {
          key: activeParent.key,
          from: {
            href: activeParent.from.href
          },
          to: {
            href: activeParent.to.href
          },
          type: activeParent.type,
          startDate: newStartDate,
          endDate: newEndDate
        };

        batch.put(activeParent.$$meta.permalink, newParentRelation);
        await batch.send('/sam/organisationalunits/batch');
        $scope.close();
      } catch (error) {
        const looseClusterErrors = errorManager.getErrorsByCode(error, errorManager.CODE.LOOSE_CLUSTER);
        if (looseClusterErrors.length > 0) {
          let conflictingChildren = looseClusterErrors[0].hrefs
            .filter(clusterResource => clusterResource.startsWith('/sam/organisationalunits/relations'))
            .map(clusterResource => parent.$$children.find(childRelation => childRelation.$$meta.permalink === clusterResource));
          utils.notify($scope, 'warning', 'warning', 'Opgelet: de cluster ' + utils.quoteText(parent.$$displayName) +
          ' en de overblijvende relatie met ' + utils.quoteText(conflictingChildren[0].from.$$expanded.$$displayName) +
          ' zullen ook worden beëindigd. Een cluster moet immers minimaal ' +
          'uit 2 onderdelen bestaan. Selecteer \'Bevestigen\' om verder te gaan.');
          $scope.warned = true;
          $scope.optionChosen = 'close';
        } else {
          utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch.array));
        }
      }
    };

    const deleteClusterBelonging = async () => {
      $scope.notificationOptions.enabled = false;

      const batch = apiService.createBatch();
      const clusterRelation = _.cloneDeep(activeParent);

      try {

        batch.delete('/sam/organisationalunits/relations/' + clusterRelation.key);
        await batch.send('/sam/organisationalunits/relations/batch');
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch.array));
      }
    };

    const closeCluster = async () => {
      $scope.notificationOptions.enabled = false;

      const batch = apiService.createBatch();
      const cluster = _.cloneDeep(activeParent.to.$$expanded);

      try {
        cluster.startDate = dateUtils.toString($scope.model.startDate);
        cluster.endDate = dateUtils.toString($scope.model.endDate) || undefined;

        batch.put('/sam/organisationalunits/' + cluster.key, cluster);
        await dateManager.manageDatesForCluster(cluster,
          batch.array,
          activeParent.to.$$expanded.startDate,
          activeParent.to.$$expanded.endDate);

        await batch.send('/sam/organisationalunits/batch');
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch.array));
      }
    };

    const deleteCluster = async () => {
      $scope.notificationOptions.enabled = false;

      const batch = apiService.createBatch();
      const cluster = _.cloneDeep(activeParent.to.$$expanded);

      try {

        batch.delete(cluster.$$meta.permalink);
        await dateManager.manageDeletesForCluster(cluster,
          batch.array);

        await batch.send('/sam/organisationalunits/batch');
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch.array));
      }
    };

    $scope.proceed = async () => {

      switch ($scope.optionChosen) {
      case 'close':
        await closeCluster();
        break;
      case 'deleteClusterBelonging':
        await deleteClusterBelonging();
        break;
      case 'deleteCluster':
        await deleteCluster();
        break;
      default:
      }
    };

    $scope.warn = async () => {

      $scope.notificationOptions.enabled = true;
      const schoolEntity = schoolEntityInfo.schoolEntity;
      $scope.warned = true;

      if (schoolEntity.$$parents && schoolEntity.$$parents.length > 0) {
        const parent = schoolEntity.$$parents[0].to.$$expanded;

        if (parent.$$children && parent.$$children.length > 2) {
          utils.notify($scope, 'warning', 'warning', 'Opgelet: ben je zeker dat je de relatie van de SE met deze cluster  wilt ' +
            ' verwijderen? Deze actie kan niet ongedaan gemaakt worden. Selecteer \'Bevestigen\' om deze relatie ' +
            ' definitief te verwijderen.');
          $scope.optionChosen = 'deleteClusterBelonging';
        } else {
          const otherChildRelation = parent.$$children.filter(child => child.from.href !== schoolEntity.$$meta.permalink);
          utils.notify($scope, 'warning', 'warning', 'Opgelet: door het verwijderen van deze relatie met de cluster ' +
          utils.quoteText(parent.$$displayName) + ' zullen ook deze cluster en de overblijvende relatie met ' +
          utils.quoteText(otherChildRelation[0].from.$$expanded.$$displayName) + ' ' +
          'definitief worden verwijderd. Deze actie kan niet ongedaan gemaakt worden. Selecteer \'Bevestigen\' ' +
          'om deze relatie en de cluster definitief te verwijderen.');
          $scope.optionChosen = 'deleteCluster';
        }
      }
    };
  }
];