module.exports = ['$scope', '$uibModalInstance', '$log', 'apiService',
  'dateUtils', 'apiQueries', 'location', 'utils', 'uuid', 'errorManager',
  ($scope, $uibModalInstance, $log, apiService,
    dateUtils, apiQueries, location, utils, uuid, errorManager) => {
    'use strict';

    const dateManager = apiService.getDateManager();

    $scope.campus = location.physicalLocation.$$expanded;
    $scope.warning = false;

    $scope.model = {
      plType: 'DOMAIN',
      startDate: utils.isoToDate(location.startDate),
      endDate: utils.isoToDate(location.endDate),
      externalIds: '',
      name: location.name
    };
    
    $scope.isYoungerThanOneMonth = dateUtils.isAfterOrEqual(location.$$meta.created, dateUtils.getPreviousMonth(dateUtils.getNow()));

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => {
      if ($scope.notificationOptions.enabled) {
        $scope.notificationOptions.enabled = false;
        $scope.warning = false;
      } else {
        $uibModalInstance.close(false);
      }
    };

    $scope.close = () => $uibModalInstance.close();

    $scope.getExternalIdentifier = async () => {
      $scope.model.externalIdValue = '';

      try {
        const onderwijskiezerIds = await apiQueries.getResources(
          '/sam/organisationalunits/locations/externalidentifiers', {
            location: location.$$meta.permalink,
            type: 'ONDERWIJSKIEZER_ID'});

        $scope.$applyAsync(() => {
          $scope.externalId = onderwijskiezerIds.length > 0 ? onderwijskiezerIds[0] : null;
          $scope.model.externalIdValue = $scope.externalId ? $scope.externalId.value : '';
        });

      } catch (error) {
        utils.notify($scope, 'danger', 'warning', 'Can\'t get external identifier for given location. Error: ' + error);
      }
    };

    $scope.getExternalIdentifier();

    // update the proposed changes
    $scope.update = async () => {
      const batch = [],
        period = {
          startDate: dateUtils.toString($scope.model.startDate),
          endDate: dateUtils.toString($scope.model.endDate)
        };

      try {
        errorManager.validatePeriod(period);

        if ($scope.model.externalIdValue !== ($scope.externalId ? $scope.externalId.value : '')) {
          const externalIdentifierKey = $scope.externalId ? $scope.externalId.key : uuid.v4();
          batch.push({
            href: '/sam/organisationalunits/locations/externalidentifiers/' + externalIdentifierKey,
            verb: 'PUT',
            body: {
              key: externalIdentifierKey,
              type: 'ONDERWIJSKIEZER_ID',
              value: parseInt($scope.model.externalIdValue, 10),
              location: {href: location.$$meta.permalink}
            }
          });
        }

        const locationToUpdate = _.extend({},
          location, {
            name: $scope.model.name,
            startDate: utils.dateToIso($scope.model.startDate),
            endDate: utils.dateToIso($scope.model.endDate)
          });

        errorManager.validatePeriod(locationToUpdate);

        if (period.startDate !== location.startDate ||
        period.endDate !== location.endDate ||
        $scope.model.name !== location.name || '') {
          batch.push({
            href: location.$$meta.permalink,
            verb: 'PUT',
            body: locationToUpdate
          });
        }
        $scope.loadingSave = true;

        if (period.startDate !== location.startDate || period.endDate !== location.endDate) {
          try {
            await dateManager.manageDatesForSchoolLocation(locationToUpdate, batch, location.startDate, location.endDate, true);
          } catch(error) {
            if(error instanceof dateUtils.DateError) {
              console.log(error);
              utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
              return;
            }
            throw error;
          }
        }

        await apiQueries.postBatch('/batch', batch);
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      } finally {
        $scope.loadingSave = false;
      }
    };

    const deleteBatch = [];
    $scope.confirmDelete = async () => {
      deleteBatch.splice(0, deleteBatch.length);

      deleteBatch.push({
        href: location.$$meta.permalink,
        verb: 'DELETE'
      });

      try {
        $scope.loading = true;
        const dependencies = await dateManager.manageDeletesForSchoolLocation(location, deleteBatch);
        $scope.loading = false;
        const numbOfEpdLocs = dependencies ? dependencies.epdLocations.length : 0;
        const numbOfClasses = dependencies ? dependencies.classes.length : 0;
        utils.notify($scope, 'warning', 'warning',
          (numbOfEpdLocs > 0 ? 'Opgelet, er ' + (numbOfClasses + numbOfEpdLocs > 1 ? 'zijn ' : 'is ') +
          (numbOfEpdLocs > 0 ? numbOfEpdLocs + ' AG' + (numbOfEpdLocs > 1 ? '\'s' : '') : '') + (numbOfEpdLocs > 0 && numbOfClasses > 0 ? ' en ' : '') +
          (numbOfClasses > 0 ? numbOfClasses + ' klas' + (numbOfClasses > 1 ? 'sen' : '') : '') + ' gekoppeld aan deze campus die ook verwijderd ' + (numbOfEpdLocs > 1 ? 'zullen' : 'zal') + ' worden. ' : '') +
          'Ben je zeker dat je deze campus ' + (numbOfEpdLocs > 0 ? 'met zijn aanbod' : '') + (numbOfEpdLocs > 0 && numbOfClasses > 0 ? ' en ' : '') + (numbOfClasses > 0 ? 'met zijn klassen' : '') +
          ' wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden. Selecteer \'bevestigen\' om verder te gaan');
        $scope.warning = true;
      } catch (error) {
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, deleteBatch));
      } finally {
        $scope.loading = false;
      }
    };

    $scope.delete = async () => {
      $scope.loading = true;
      try {
        await apiQueries.postBatch('/batch', deleteBatch);
        $scope.close();
      } catch (error) {
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, deleteBatch));
      } finally {
        $scope.loading = false;
      }
    };

    $scope.enableSave = () => {
      return utils.dateToIso($scope.model.startDate) &&
        utils.dateToIso($scope.model.startDate) !== location.startDate ||
        utils.dateToIso($scope.model.endDate) !== location.endDate ||
        $scope.model.externalIdValue !== ($scope.externalId ? $scope.externalId.value : '') ||
        $scope.model.name !== location.name || '';
    };
  }
];
