module.exports = ['$scope', '$log', '$uibModalInstance', 'utils', '$q', '$timeout', '$stateParams',
  'settings', 'uuid', 'apiQueries', 'school', 'location', 'dateUtils', 'errorManager',
  ($scope, $log, $uibModalInstance, utils, $q, $timeout, $stateParams,
    settings, uuid, apiQueries, school, location, dateUtils, errorManager) => {

    'use strict';

    const selectedSchoolKey = $stateParams.id,
      campus = location.physicalLocation.$$expanded;

    /* Modal controller and notifications */

    $scope.paginationSettings = {
      itemsPerPage: settings.paginationSettings.itemsPerModal
    };

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => $uibModalInstance.close(false);

    $scope.close = () => $uibModalInstance.close();

    $scope.model = {
      newBuilding: {
        name: '',
        address: {}
      },
      buildings: {},
      checkbox: {},
      currentPage: 1,
      searchValue: '',
      totalItems: 1,
      offset: 0,
      selectedBuildings: [],
      alreadyRelated: {}
    };

    $scope.navModel = {
      active: 0
    };

    $scope.datepickerOptions = {
      minDate: utils.isoToDate(location.startDate),
      maxDate: utils.isoToDate(location.endDate)
    };

    $scope.forms = {
      buildingCreation: {}
    };

    let tempFilterText = '', filterTextTimeout = null;

    $scope.$watch('model.searchValue', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        if (filterTextTimeout) {
          $timeout.cancel(filterTextTimeout);
        }

        tempFilterText = newValue;
        filterTextTimeout = $timeout(() => {
          $scope.model.searchValue = tempFilterText;
          $scope.getExistingBuildings();
        }, 250); // delay 250 ms
      }
    });

    $scope.pageChanged = () => {
      $scope.model.offset = ($scope.model.currentPage - 1) * $scope.paginationSettings.itemsPerPage;
      $scope.getExistingBuildings();
    };

    $scope.toggleBuildingCheckbox = buildingRelation => {
      if ($scope.model.checkbox[buildingRelation.key]) {
        $scope.model.selectedBuildings.push(buildingRelation);
      } else {
        _.pull($scope.model.selectedBuilding, buildingRelation);
      }
    };

    const clearSelectedBuildings = () => {

      $scope.model.selectedBuildings = [];
      $scope.model.alreadyRelated = {};
    };

    const updateSelectedBuildings = () => {

      $scope.model.checkbox = _.pickBy($scope.model.checkbox, (selected) => selected);

      Object.keys($scope.model.checkbox).forEach(key => {
        if (!$scope.model.alreadyRelated[key]) {
          $scope.model.selectedBuildings.push($scope.model.buildings[key]);
        }
      });
    };

    // each time startDate changes, shown data is updated
    $scope.$watch('model.startDate', () => {

      clearSelectedBuildings();

      if (location.physicalLocation.$$expanded.$$buildings.length > 0) {
        location.physicalLocation.$$expanded.$$buildings.forEach(relatedBuilding => {

          if (dateUtils.isOverlapping(
            {startDate: $scope.model.startDate, endDate: null},
            {startDate: relatedBuilding.startDate, endDate: relatedBuilding.endDate}
          )) {
            $scope.model.checkbox[relatedBuilding.key] = true;
            $scope.model.alreadyRelated[relatedBuilding.key] = true;
          }
        });
      }

      updateSelectedBuildings();
    });

    $scope.model.startDate = new Date();

    // get existings buildings to be shown on the first tab
    $scope.getExistingBuildings = async () => {
      let params = {
        to: campus.$$meta.permalink,
        status: 'ACTIVE,FUTURE',
        type: 'IS_PART_OF'
      };

      if (!$scope.model.searchValue) {
        params.limit = $scope.paginationSettings.itemsPerPage;
        params.offset = $scope.model.offset;
      }

      try {
        let existingBuildings = await apiQueries.getResources('/sam/physicallocations/relations',
          params, {
            expand: 'from'
          });

        let buildings = {};
        $scope.model.dataLength = 0;

        $scope.$applyAsync(() => {

          $scope.model.totalItems = existingBuildings.length;

          if ($scope.model.searchValue) {
            existingBuildings = existingBuildings.filter(existingBuilding =>
              utils.textPartialMatch(existingBuilding.from.$$expanded.name, $scope.model.searchValue));

            $scope.model.totalItems = existingBuildings.length;

            existingBuildings = _.slice(existingBuildings,
              ($scope.model.currentPage - 1) * $scope.paginationSettings.itemsPerPage,
              ($scope.model.currentPage - 1) * $scope.paginationSettings.itemsPerPage
                + $scope.paginationSettings.itemsPerPage);
          }

          $scope.model.dataLength = existingBuildings.length;
          $scope.navModel.active = $scope.model.searchValue || $scope.model.dataLength > 0 ? 0 : 1;

          existingBuildings.forEach(buildingRel => {
            buildings[buildingRel.key] = buildingRel;
          });

          $scope.model.buildings = buildings;

        });
      } catch (error) {
        $log.error('Can\'t get building beloging to campus: ' + campus.key + '. ' + error);
      }
    };

    // observe changes in zipCode

    const refreshSelectableInputs = () => {
      $scope.refreshSubCities();
      $scope.refreshCities();
      $scope.refreshStreets();

      $scope.model.newBuilding.address.street = null;
      $scope.model.newBuilding.address.subCity = null;
      $scope.model.newBuilding.address.city = null;
    };

    let cityKey, zipCode,
      zipCodeTimeout = null;

    // selectable inputs for addresses
    $scope.refreshStreets = async (street) => {
      const params = {
        nameContains: street,
        //expand: 'results.city',
        limit: 10
      };

      if (cityKey) {
        params.city = cityKey;
      }

      try {
        const matchedStreets = await apiQueries.getResources('/sam/commons/streets', params, {expand: 'city'});
        return matchedStreets.length === 0 ? [{name: street}] :
          matchedStreets.map(s => s.name);
      } catch(e) {
        $log.error('Can\'t get the list of matched streets.');
      }

      return [{name: street}];
    };

    $scope.refreshSubCities = async (subCity) => {
      let params = {
        nameContains: subCity,
        limit: 10
      };

      if (zipCode) {
        params.zipCodesContains = zipCode;
      }

      try {
        const matchedSubcities = await apiQueries.getResources('/sam/commons/subcities', params);
        return matchedSubcities.length === 0 ? [{name: subCity}] :
          matchedSubcities.map(s => s.name);
      } catch(e) {
        $log.error('Can\'t get the list of matched subcities.');
      }

      return [{name: subCity}];
    };

    $scope.refreshCities = async (city) => {
      let params = {
        nameContains: city,
        limit: 10,
        languagesContains: 'nl'
      };

      if (cityKey) {
        params.key = cityKey;
      }

      try {
        const matchedCities = await apiQueries.getResources('/sam/commons/cities', params);
        return matchedCities.length === 0 ? [{name: city}] :
          matchedCities.map(s => s.name);
      } catch(e) {
        $log.error('Can\'t get the list of matched cities.');
      }

      return [{name: city}];
    };

    $scope.refreshCountries = async (country) => {
      let params = {
        nameContains: country,
        limit: 10
      };

      try {
        const matchedCountries = await apiQueries.getResources('/sam/commons/countries', params);
        return matchedCountries.length === 0 ? [{name: country}] :
          matchedCountries.map(s => s.name);
      } catch(e) {
        $log.error('Can\'t get the list of matched countries.');
      }

      return [{name: country}];
    };

    // init default data for new building
    $scope.initNewBuilding = () => {

      $scope.model.newBuilding.startDate = new Date();
      $scope.model.newBuilding.minDate = utils.dateToIso($scope.model.newBuilding.startDate);

      $scope.model.newBuilding.address = _.extend({}, campus.address || {});

      // watch zipcode changes after initialization
      $scope.$watch('model.newBuilding.address.zipCode', (newZipCode, oldZipCode) => {
        cityKey = zipCode = null;

        if (newZipCode !== oldZipCode) {
          if (zipCodeTimeout) {
            $timeout.cancel(zipCodeTimeout);
          }

          zipCodeTimeout = $timeout(() => {

            if (/^\d+$/.test(newZipCode)) {

              const subCities = apiQueries.getResources('/sam/commons/subcities', {zipCodesContains: newZipCode});
              if (subCities.length > 0) {
                cityKey = subCities[0].city.href.split('/sam/commons/cities/')[1];
                zipCode = newZipCode;
                refreshSelectableInputs();
              }
            }
          }, 250); // delay 250 ms
        }
      });
    };

    $scope.isValidBuilding = () => $scope.forms.buildingCreation.$valid;

    $scope.save = () => {
      if ($scope.navModel.active === 0) {
        $scope.saveExistingBuilding();
      } else {
        $scope.saveNewBuilding();
      }
    };

    $scope.saveExistingBuilding = async () => {
      let batch = [];

      $scope.model.selectedBuildings.forEach(buildingCampusRelation => {

        let buildingOURelationKey = uuid.v4();

        batch.push({
          href: '/sam/organisationalunits/locations/' + buildingOURelationKey,
          verb: 'PUT',
          body: {
            key: buildingOURelationKey,
            organisationalUnit: {href: '/sam/organisationalunits/' + selectedSchoolKey},
            physicalLocation: {href: '/sam/physicallocations/' + buildingCampusRelation.from.$$expanded.key},
            type: 'CAMPUS',
            startDate: utils.dateToIso($scope.model.startDate)
          }
        });
      });

      try {
        await apiQueries.postBatch('/sam/organisationalunits/locations/batch', batch);
        $scope.close();
      } catch (error) {
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      }
    };

    $scope.saveNewBuilding = async () => {
      const batch = [];

      // create building as physicallocation
      let buildingKey = uuid.v4();
      let building = {
        key: buildingKey,
        type: 'BUILDING',
        name: $scope.model.newBuilding.name,
        description: $scope.model.newBuilding.description,
        code: $scope.model.newBuilding.code
      };

      building.address = {};

      Object.keys($scope.model.newBuilding.address).forEach((key) => {
        if (!$scope.model.newBuilding.address[key]) {
          return;
        }
        building.address[key] = $scope.model.newBuilding.address[key];
      });

      batch.push({
        href: '/sam/physicallocations/' + buildingKey,
        verb: 'PUT',
        body: building
      });

      //create relation between selected campus and new building
      let campusBuildingRelationKey = uuid.v4();
      batch.push({
        href: '/sam/physicallocations/relations/' + campusBuildingRelationKey,
        verb: 'PUT',
        body: {
          key: campusBuildingRelationKey,
          from: {href: '/sam/physicallocations/' + buildingKey},
          to: {href: '/sam/physicallocations/' + campus.key},
          type: 'IS_PART_OF',
          startDate: utils.dateToIso($scope.model.newBuilding.startDate)
        }
      });

      try {

        await apiQueries.postBatch('/sam/physicallocations/batch', [batch]);

        //create relation between selected school entity and new building
        let schoolEntityBuildingRelationKey = uuid.v4();

        await apiQueries.putResource('/sam/organisationalunits/locations/' + schoolEntityBuildingRelationKey, {
          key: schoolEntityBuildingRelationKey,
          physicalLocation: {href: '/sam/physicallocations/' + buildingKey},
          organisationalUnit: {href: '/sam/organisationalunits/' + selectedSchoolKey},
          type: 'CAMPUS',
          startDate: utils.dateToIso($scope.model.newBuilding.startDate)
        });

        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      }
    };

  }];
