const ALL_RELATIONS = '',
  NON_OBSOLETE_RELATIONS = 'ACTIVE,FUTURE';
  
const STEM_CATEGORIE_CODES = {
  '/sam/commons/stemcategorieen/a77f1eff-abf9-11eb-a294-bab24c68247b': 'S', // STEM,
  '/sam/commons/stemcategorieen/a7885c69-abf9-11eb-a294-bab24c68247b': 'ZS', // Zorg-Stem
  '/sam/commons/stemcategorieen/a78c8f95-abf9-11eb-a294-bab24c68247b': 'LS', // Lichte Stem
  '/sam/commons/stemcategorieen/a78d9873-abf9-11eb-a294-bab24c68247b': 'NS' // Niet Stem
};

module.exports = ['$scope', '$log', 'apiQueries', 'vskoModalService',
  '$uibModalInstance', '$stateParams', 'utils', 'dateUtils', 'epdUtils', 'dataService', 'apiService',
  ($scope, $log, apiQueries, vskoModalService,
    $uibModalInstance, $stateParams, utils, dateUtils, epdUtils, dataService, apiService) => {
    'use strict';

    $scope.ouId = $stateParams.id;

    const relationGetters = apiService.getRelationGetters();

    const searchParams = $stateParams.search.split(';');
    const referenceDateParam = searchParams.find(param => param.startsWith('referenceDate'));
    const referenceDate = referenceDateParam ? referenceDateParam.split(':')[1] : dateUtils.getNow();

    $scope.model = {
      schoolDeadline: referenceDate,
      plDeadlines: {},
      plIsExpanded: {},
      referenceDate: referenceDate,
      statusCampusMessages: {},
      linkedOfficialSchoolMap: {},
      hasBeenModified: false,
      popoverIsOpen: {}
    };

    $scope.getPhysicalLocationTooltip = (locationRelation, location) => {
      let tooltip = '';

      if (locationRelation.name) {
        tooltip += locationRelation.name;
      } else {
        tooltip += '&lt;onbekend&gt;';
      }

      tooltip += '<br>';

      tooltip += utils.physicalLocationToString(location.address) + '<br>';

      if (location.name) {
        tooltip += '&#91;' + location.name + '&#93;';
      }

      return tooltip;
    };

    const attachContactDetails = async (ous) => {
      const contactDetails = await apiQueries.getOrganisationalUnitsContactDetails({
        organisationalUnit: ous.map(ou => ou.$$meta.permalink).join(',')
      });

      ous.forEach(ou => {
        const ouContacts = contactDetails.filter(contactDetail => ou.$$meta.permalink === contactDetail.organisationalUnit.href);

        let mails = ouContacts.filter(contactDetail => contactDetail.type === 'MAIL');

        if (mails.length > 0) {
          const sortedMails = mails.sort((mail1, mail2) => mail2.priority - mail1.priority);
          ou.$$mail = utils.physicalLocationToString(sortedMails[0].value);
        }

        let emails = ouContacts.filter(contactDetail => contactDetail.type === 'EMAIL');
        
        if (emails.length > 0) {
          const sortedEmails = emails.sort((email1, email2) => email2.priority - email1.priority);
          ou.$$email = sortedEmails[0].value.email;
        }

        let phones = ouContacts.filter(contactDetail => contactDetail.type === 'PHONE');
        
        if (phones.length > 0) {
          const sortedPhones = phones.sort((phone1, phone2) => phone2.priority - phone1.priority);
          ou.$$phone = sortedPhones[0].value.phone;
        }

      });
    };

    const attachExternalIdentifiers = async (ous) => {
      const externalIdentifiers = await apiQueries.getOrganisationalUnitsExternalIdentifiers({
        organisationalUnit: ous.map(ou => ou.$$meta.permalink).join(','),
        typeIn: 'INSTITUTION_NUMBER,COMPANY_NUMBER'
      });

      externalIdentifiers.forEach(externalIdentifier => {
        const ou = ous.find(o => o.$$meta.permalink === externalIdentifier.organisationalUnit.href);
        if (externalIdentifier.type === 'INSTITUTION_NUMBER') {
          ou.$$institutionNumber = externalIdentifier.value;
        }
        if (externalIdentifier.type === 'COMPANY_NUMBER') {
          ou.$$companyNumber = externalIdentifier.value;
        }
      });
    };

    const getGoverningInstitutionInfo = async () => {

      let governingInstitution;

      const goveringInstitutionRelations = await apiQueries.getOrganisationalUnitsRelations({
        type: 'GOVERNS',
        //status: 'ACTIVE',
        startDateBefore: $scope.model.referenceDate,
        endDateAfter: $scope.model.referenceDate,
        to: $scope.school.$$meta.permalink,
        expand: 'results.from'
      });

      if (goveringInstitutionRelations.length > 0) {
        governingInstitution = goveringInstitutionRelations[0].from.$$expanded;
        attachContactDetails([governingInstitution]);
        attachExternalIdentifiers([governingInstitution]);
      }

      $scope.$applyAsync(() => {
        $scope.school.governingInstitution = governingInstitution;
      });
    };

    const getCLBsInfo = async () => {
      const clbs = await apiQueries.getClbs($scope.school.$$meta.permalink, $scope.model.referenceDate);// await relationGetters.getSchoolEntityClbs($scope.school.$$meta.permalink, $scope.model.referenceDate);

      if (clbs.length > 0) {
        attachContactDetails(clbs);
        attachExternalIdentifiers(clbs);
      }

      $scope.$applyAsync(() => {
        $scope.school.clbs = clbs;
      });
    };

    // edit and remove school
    $scope.editSchool = () => {
      vskoModalService.open({
        template: require('./editSchool.html'),
        controller: require('./editSchoolController'),
        animation: false,
        resolve: {
          school: $scope.school
        }
      }).result.then((data) => {
        if (data !== false) {
          if (data === 'deleted') {
            $scope.closeAside();
          } else {
            $scope.getRelatedData(ALL_RELATIONS);
            $scope.model.hasBeenModified = true;
          }
        }
      });
    };

    // edit contact details for school entity
    $scope.editContactDetails = () => {
      vskoModalService.open({
        template: require('./editContactDetails.html'),
        controller: require('./editContactDetailsController'),
        animation: false,
        resolve: {
          school: $scope.school
        }
      });
    };

    // edit relation between school and governing institution
    $scope.editSchoolGoverningInstitution = () => {
      vskoModalService.open({
        template: require('./editSchoolGoverningInstitution.html'),
        controller: require('./editSchoolGoverningInstitutionController'),
        animation: false,
        resolve: {
          school: $scope.school
        }
      }).result.then((data) => {
        if (data !== false) {
          getGoverningInstitutionInfo();
        }
      });
    };

    $scope.togglePopover = (which) => {
      if (!$scope.model.popoverIsOpen[which.key]) {
        Object.keys($scope.model.popoverIsOpen).forEach(popover => $scope.model.popoverIsOpen[popover] = false);
        $scope.model.popover = which;
      }

      $scope.model.popoverIsOpen[which.key] = !$scope.model.popoverIsOpen[which.key];
    };

    $scope.toggleHistorialSchoolRelations = () => {
      $scope.$applyAsync(() => {
        $scope.model.schoolDeadline = $scope.model.schoolDeadline ? null : $scope.model.referenceDate;

        for (let key of Object.keys($scope.model.plDeadlines)) {
          $scope.model.plDeadlines[key] = $scope.model.schoolDeadline;
        }
      });
    };

    $scope.toggleHistorialPlRelations = (plRelation) => {
      $scope.model.plDeadlines[plRelation.key] = $scope.model.plDeadlines[plRelation.key] ? null : $scope.model.referenceDate;

      // if pl is a campus, then toggle historial flag in all related buildings to the campus.
      if ('organisationalunit' in plRelation) {
        for (let i of $scope.relatedBuildings[plRelation.physicallocation.$$expanded.key]) {
          let buildingRel = $scope.relatedBuildings[plRelation.physicallocation.$$expanded.key][i];
          $scope.model.plDeadlines[buildingRel.key] = $scope.model.plDeadlines[plRelation.key];
        }
      }
    };

    // add, edit and remove campuses
    $scope.addCampus = () => {
      vskoModalService.open({
        template: require('./pl/campus/addCampus.html'),
        controller: require('./pl/campus/addCampusController'),
        animation: false,
        resolve: {
          school: $scope.school
        }
      }).result.then((data) => {
        if (data !== false) {
          $scope.getRelatedData(ALL_RELATIONS);
          $scope.model.hasBeenModified = true;
        }
      });
    };

    $scope.editCampusRelation = (location) => {
      vskoModalService.open({
        template: require('./pl/editPhysicalLocationRelation.html'),
        controller: require('./pl/campus/editCampusRelationController'),
        animation: false,
        resolve: {
          school: $scope.school,
          location: location
        }
      }).result.then((data) => {
        if (data !== false) {
          $scope.getRelatedData(ALL_RELATIONS);
          $scope.model.hasBeenModified = true;
        }
      });
    };

    $scope.editCampus = (location) => {
      vskoModalService.open({
        template: require('./pl/editPhysicalLocation.html'),
        controller: require('./pl/editPhysicalLocationController'),
        animation: false,
        resolve: {
          location: location
        }
      }).result.then((data) => {
        if (data !== false) {
          $scope.getRelatedData(ALL_RELATIONS);
          $scope.model.hasBeenModified = true;
        }
      });
    };

    // add and remove ags from campuses and buildings
    $scope.addAg = (location, educationalProgrammeDetail) => {

      vskoModalService.open({
        template: require('./ag/addAg.html'),
        controller: require('./ag/addAgController'),
        animation: false,
        resolve: {
          school: $scope.school,
          location: location,
          educationalProgrammeDetail: educationalProgrammeDetail
        }
      }).result.then(async (data) => {
        if (!data.cancel) {
          await $scope.getRelatedData(ALL_RELATIONS);

          if (data.addedAbolishedAgs) {
            $scope.model.plDeadlines[location.key] = null;
          }
        }
      });
    };

    $scope.manageAgPeriod = (educationalProgrammeDetailLocation) => {
      vskoModalService.open({
        template: require('./ag/manageAgPeriod.html'),
        controller: require('./ag/manageAgPeriodController'),
        animation: false,
        resolve: {
          school: $scope.school,
          educationalProgrammeDetailLocation: educationalProgrammeDetailLocation
        }
      }).result.then((data) => {
        if (data !== false) {
          $scope.getRelatedData(ALL_RELATIONS);
        }
      });
    };

    $scope.manageExistingRelations = (educationalProgrammeDetailLocation) => {
      vskoModalService.open({
        template: require('./ag/manageExistingRelations.html'),
        controller: require('./ag/manageExistingRelationsController'),
        animation: false,
        resolve: {
          educationalProgrammeDetailLocation: educationalProgrammeDetailLocation
        }
      }).result.then((data) => {
        if (data !== false) {
          $scope.getRelatedData(ALL_RELATIONS);
        }
      });
    };

    // add and remove buildings
    $scope.addBuilding = (location) => {
      vskoModalService.open({
        template: require('./pl/building/addBuilding.html'),
        controller: require('./pl/building/addBuildingController'),
        animation: false,
        resolve: {
          school: $scope.school,
          location: location
        }
      }).result.then((data) => {
        if (data !== false) {
          $scope.getRelatedData(ALL_RELATIONS);
        }
      });
    };

    $scope.editBuilding = (location) => {
      vskoModalService.open({
        template: require('./pl/editPhysicalLocation.html'),
        controller: require('./pl/editPhysicalLocationController'),
        animation: false,
        resolve: {
          location: location
        }
      }).result.then((data) => {
        if (data !== false) {
          $scope.getRelatedData(ALL_RELATIONS);
        }
      });
    };

    $scope.editBuildingRelation = (buildingLocation) => {
      vskoModalService.open({
        template: require('./pl/editPhysicalLocationRelation.html'),
        controller: require('./pl/building/editBuildingRelationController'),
        animation: false,
        resolve: {
          location: buildingLocation
        }
      }).result.then((data) => {
        if (data !== false) {
          $scope.getRelatedData(ALL_RELATIONS);
        }
      });
    };

    // retrieve data for the school detail screen where relations involved the school have the status
    // given by the status variables
    $scope.getSchool = async (status) => {

      try {
        $scope.school = await apiQueries.getOrganisationalUnit('/sam/organisationalunits/' + $scope.ouId, {
        }, {
          include: {
            alias: '$$parent',
            href: '/sam/organisationalunits/relations',
            filters: {
              type: 'IS_PART_OF',
              status: status
            },
            singleton: true,
            reference: 'from',
            expand: {
              property: 'to',
              include: {
                alias: '$$parent',
                href: '/sam/organisationalunits/relations',
                filters: {
                  type: 'IS_PART_OF',
                  status: status
                },
                singleton: true,
                reference: 'from',
                expand: 'to'
              }
            }
          }
        });

        $scope.$applyAsync();
        getGoverningInstitutionInfo();
        getCLBsInfo();
      } catch (error) {
        $log.error('Can\'t get data of organisational unit with key:' + $scope.ouId + '. ' + error);
      }
    };

    $scope.closeAside = () => $uibModalInstance.close($scope.model.hasBeenModified);

    $scope.getLocations = async (status) => {

      $scope.school.$$locations = [];

      try {
        let locationRelations = await apiQueries.getOrganisationalUnit('/sam/organisationalunits/' + $scope.ouId, {}, {
          include: {
            alias: '$$locations',
            href: '/sam/organisationalunits/locations',
            filters: {
              orderBy: 'endDate',
              descending: true,
              status: status
            },
            reference: 'organisationalUnit',
            expand: [{
              property: 'physicalLocation',
              include: {
                alias: '$$buildings',
                href: '/sam/physicallocations/relations',
                filters: {
                  type: 'IS_PART_OF',
                  status: status
                },
                reference: 'to',
                expand: {
                  property: 'from'
                }
              }
            }],
            include: {
              alias: '$$locationExternalIdentifier',
              href: '/sam/organisationalunits/locations/externalIdentifiers',
              filters: {
                type: 'ONDERWIJSKIEZER_ID'
              },
              singleton: true,
              reference: 'location'
            }
          }
        }, {
          //inBatch: '/batch'
        });

        // force to update building before campuses

        let locations = locationRelations.$$locations.filter(location =>
          ['DOMAIN', 'BUILDING'].includes(location.physicalLocation.$$expanded.type));

        const buildingLocations = locationRelations.$$locations.filter(location =>
          location.physicalLocation.$$expanded.type === 'BUILDING');

        locations = _.sortBy(locations, 'physicalLocation.$$expanded.type');

        const educationalProgrammeDetails = await $scope.attachEducationalProgrammeDetails();
        $scope.school.$$epdsForMainstructures =
          await apiQueries.getEducationalProgrammeDetailsForMainstructures({ organisationalUnit: '/sam/organisationalunits/' + $scope.ouId });

        $scope.$applyAsync(() => {
          $scope.school.$$educationalProgrammeDetails = educationalProgrammeDetails;
        });

        if (locations.length === 1) {
          $scope.model.plIsExpanded[locations[0].key] = true;
        }

        for (let location of locations) {

          $scope.$applyAsync(async () => {
            $scope.model.statusCampusMessages[location.key] = 'Loading data. Please wait...';

            if (!(location.key in $scope.model.plDeadlines)) {
              $scope.model.plDeadlines[location.key] = $scope.model.referenceDate;
            }

            const buildings = [];

            for (let building of location.physicalLocation.$$expanded.$$buildings) {
              building.from.$$expanded.$$location = _.find(buildingLocations,
                l => l.physicalLocation.href === building.from.href);

              // show the building if it is also linked to the organisationalunit
              if (building.from.$$expanded.$$location) {
                buildings.push(building);
              }
            }
            location.physicalLocation.$$expanded.$$buildings = buildings;
            $scope.school.$$locations.push(location);

            if ($scope.school.$$educationalProgrammeDetails.length > 0) {
              await $scope.attachEducationalProgrammeDetailsLocations(educationalProgrammeDetails, location, status);
            }
            $scope.model.statusCampusMessages[location.key] = null;
          });
        }

      } catch (error) {
        $log.error('Can\'t get related campuses to organisational unit with key:' + $scope.ouId + '. ' + error);
      }
    };

    $scope.attachEducationalProgrammeDetails = async () => {

      try {
        let educationalProgrammeDetails = await apiQueries.getEducationalProgrammeDetails({
          organisationalUnit: '/sam/organisationalunits/' + $scope.ouId
        });

        for (let educationalProgrammeDetail of educationalProgrammeDetails) {
          educationalProgrammeDetail.ag.$$expanded = await dataService.getAg(educationalProgrammeDetail.ag.href);
          if (educationalProgrammeDetail.ag.$$expanded.stemCategorie) {
            educationalProgrammeDetail.ag.$$expanded.stemCategorie.$$expanded = {
              code: STEM_CATEGORIE_CODES[educationalProgrammeDetail.ag.$$expanded.stemCategorie.href]
            };
          }

          if (educationalProgrammeDetail.buoType) {
            educationalProgrammeDetail.buoType.$$expanded =
              await dataService.getBuoType(educationalProgrammeDetail.buoType.href);
          }
        }

        return educationalProgrammeDetails;
      } catch (error) {
        $log.error('Can\'t get related educational programmes of the school entity' +
          '. Reason: ' + JSON.stringify(error));
      }

      return [];
    };

    $scope.attachEducationalProgrammeDetailsLocations = async (educationalProgrammeDetails, location, status) => {

      if (educationalProgrammeDetails.length === 0) {
        return;
      }

      const period = {
        startDateAfter: location.startDate
      };

      if (location.endDate) {
        period.endDateBefore = location.endDate;
      }

      let educationalProgrammeDetailsLocations = await apiQueries.getEducationalProgrammeDetailsLocations(Object.assign({}, {
        physicalLocation: location.physicalLocation.href,
        'educationalProgrammeDetail.organisationalUnit': $scope.school.$$meta.permalink
      }, period), {
        include: {
          alias: '$$relations',
          href: '/sam/educationalprogrammedetails/locations/relations',
          reference: 'from',
          expand: {
            property: 'to',
            inBatch: '/batch',
            expand: [{
              property: 'educationalProgrammeDetail',
              expand: {
                property: 'organisationalUnit'
              }
            }, {
              property: 'physicalLocation'
            }],
          },
        },
        inBatch: '/batch'
      });

      educationalProgrammeDetailsLocations.forEach(educationalProgrammeDetailLocation => {
        let educationalProgrammeDetail = educationalProgrammeDetails.find(epd =>
          epd.$$meta.permalink === educationalProgrammeDetailLocation.educationalProgrammeDetail.href);
        educationalProgrammeDetailLocation.educationalProgrammeDetail.$$expanded = educationalProgrammeDetail;

        if (!educationalProgrammeDetail.$$locations) {
          educationalProgrammeDetail.$$locations = [];
        }

        educationalProgrammeDetail.$$locations.push(educationalProgrammeDetailLocation);
      });

      if (educationalProgrammeDetailsLocations.length === 0) {
        return;
      }

      try {
        for (let educationalProgrammeDetailLocation of educationalProgrammeDetailsLocations) {
          for (let relation of educationalProgrammeDetailLocation.$$relations) {
            await dataService.attachAvatarInfo(relation.to.$$expanded);
          }
        }

        $scope.$applyAsync(() => {
          epdUtils.sortEducationProgramme(educationalProgrammeDetailsLocations,
            {path: 'educationalProgrammeDetail.$$expanded'});

          location.$$educationalProgrammeDetailsLocations = educationalProgrammeDetailsLocations;
        });
      } catch (error) {
        $log.error('Can\'t get related educational programmes related to location with key:'
          + location.key + '. Reason: ' + JSON.stringify(error));
      }
    };

    $scope.getRelatedData = (status) => {
      $scope.$applyAsync(async () => {
        await $scope.getSchool(NON_OBSOLETE_RELATIONS);
        await $scope.getLocations(status);
      });
    };

    $scope.getRelatedData(ALL_RELATIONS);
  }
];
