module.exports = ['$scope', '$uibModalInstance', 'utils', 'apiQueries',
  async ($scope, $uibModalInstance, utils, apiQueries) => {
    'use strict';

    const controlLists = {
      1: {
        apiLink: 'offer-without-relations',
        name: 'Controllist 1: Onvolledigheid ingericht aanbod'
      },
      2: {
        apiLink: 'offer-for-multiple-governinginstitution',
        name: 'Controllist 2: Aanbod behorend tot meer dan 1 bestuur'
      },
      3: {
        apiLink: 'offer-with-multiple-relations',
        name: 'Controllist 3: Meervoudig gebruik van officieel aanbod'
      },
      4: {
        apiLink: 'unrelated-real-offer',
        name: 'Controllist 4: Controle Reeel aanbod zonder relatie met officieel aanbod'
      },
      5: {
        apiLink: 'not-officially-registrated-addresses',
        name: 'Controllist 5: Controle officieel bestaand campusadres'
      },
      6: {
        apiLink: 'schoolentity-without-governinginstitution',
        name: 'Controllist 6: Schoolentiteiten zonder bestuur'
      },
      7: {
        apiLink: 'campus-without-offer',
        name: 'Controllist 7: Controle op campus zonder aanbod'
      },
      8: {
        apiLink: 'schoolentity-without-campuses',
        name: 'Controllist 8: Controle op SchoolEntiteit zonder campus'
      }
    };

    $scope.model = {
      controlList: {
        value: 1
      },
      statusBar: {
        state: false,
        percentage: 100
      }
    };

    $scope.cancel = () => {
      $uibModalInstance.close(false);
    };

    $scope.close = (newSchoolEntityKey) => $uibModalInstance.close(newSchoolEntityKey);

    $scope.notificationOptions = {
      enabled: false
    };

    const cellToString = (cell) => {
      let value = cell === null ? '' : cell.toString();
      // We remove blanks and check if the column contains
      // other whitespace,`,` or `"`.
      // In that case, we need to quote the column.
      if (value && value.replace(/ /g, '').match(/[\s,"]/)) {
        value = value.replace(/"/g, '""');
      }

      return '="' + value + '"';
    };

    const convertJsonToCSV = (items) => {
      const header = Object.keys(items[0]);
      let csv = items.map(row => header.map(fieldName =>
        cellToString(row[fieldName])).join(';'));
      csv.unshift(header.join(';'));
      csv = csv.join('\r\n');
      return csv;
    };

    const downloadControlList = (controlListFileName, result) => {
      var blob = new Blob(['\ufeff', convertJsonToCSV(result)], {type: 'text/csv;charset=utf-8;'});
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, controlListFileName);
      } else {
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, {data: 'text/csv;charset=utf-8'});
        a.download = controlListFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };

    const generateControlList = async (controlListName, controlListApiLink, controlListFileName) => {
      const start = new Date();
      const results = await apiQueries.getResources('/controllists/' + controlListApiLink, {
        expand: 'NONE'
      });

      if (results.length > 0) {
        await downloadControlList(controlListFileName, results);
        utils.notify($scope, 'info', 'info', controlListName +
          ' bestand is gedownload. Druk op \'Annuleren\' of \'Esc\' om dit dialoogvenster te sluiten.');
      } else {
        utils.notify($scope, 'info', 'info', controlListName + ' - bevat geen data.');
      }

      console.log((new Date() - start) / 1000, "s spent.");
    };

    $scope.download = async () => {
      const controlListName = controlLists[$scope.model.controlList.value].name;
      const controlListApiLink = controlLists[$scope.model.controlList.value].apiLink;
      const controlListFileName = controlListName + '.csv';

      $scope.notificationOptions.enabled = false;
      $scope.model.statusBar.message = controlListName + ' wordt gegenereerd.';
      $scope.model.statusBar.state = true;

      try {
        await generateControlList(controlListName, controlListApiLink, controlListFileName);
      } catch (error) {
        utils.notify($scope, 'danger', 'warning', 'Het was niet mogelijk om de ' +
          controlListName + ' te downloaden.');
        console.error(error);
      } finally {
        $scope.model.statusBar.state = false;
      }
    };
  }
];
