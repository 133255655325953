module.exports = ['$scope', '$uibModalInstance', '$log', 'apiService', 'apiQueries', 'utils',
  'clusterInfo', 'dateUtils', 'errorManager',
  ($scope, $uibModalInstance, $log, apiService, apiQueries, utils, clusterInfo, dateUtils, errorManager) => {
    'use strict';

    const dateManager = apiService.getDateManager();

    $scope.model = {
      clusterName: clusterInfo.cluster.$$displayName,
      startDate: dateUtils.parse(clusterInfo.cluster.startDate),
      endDate: dateUtils.parse(clusterInfo.cluster.endDate),
      dialogTitle: clusterInfo.closeCluster ? 'SchoolentiteitCluster afschaffen' : 'SchoolentiteitCluster bewerken'
    };

    $scope.cancel = () => $uibModalInstance.close(false);
    $scope.close = () => $uibModalInstance.close();

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.actionPermitted = true;

    $scope.enableSave = () =>
      $scope.model.clusterName !== clusterInfo.cluster.$$displayName ||
      dateUtils.toString($scope.model.startDate) !== clusterInfo.cluster.startDate ||
      dateUtils.toString($scope.model.endDate) !== clusterInfo.cluster.endDate;

    $scope.editRelation = async () => {
      $scope.notificationOptions.enabled = false;

      const batch = apiService.createBatch();

      let cluster = _.cloneDeep(clusterInfo.cluster);

      if (clusterInfo.cluster.$$displayName !== $scope.model.clusterName) {
        cluster.names = [{
          type: 'OFFICIAL',
          value: $scope.model.clusterName,
          startDate: dateUtils.toString($scope.model.startDate),
          endDate: dateUtils.toString($scope.model.endDate)
        }];
        batch.put('/sam/organisationalunits/' + cluster.key, cluster);
      }

      try {

        const newStartDate = dateUtils.toString($scope.model.startDate);
        const newEndDate = dateUtils.toString($scope.model.endDate) || undefined;

        if (newStartDate !== clusterInfo.cluster.startDate ||
          newEndDate !== clusterInfo.cluster.endDate) {
          cluster.startDate = newStartDate;
          cluster.endDate = newEndDate;

          batch.put('/sam/organisationalunits/' + cluster.key, cluster);
          await dateManager.manageDatesForCluster(cluster,
            batch.array,
            clusterInfo.cluster.startDate, clusterInfo.cluster.endDate);
        }

        await batch.send('/sam/organisationalunits/batch');
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch.array));
      }
    };

    $scope.deleteRelation = async () => {

      $scope.notificationOptions.enabled = false;
      $scope.actionPermitted = true;
      let deleteBatch = [];

      deleteBatch.push({
        href: clusterInfo.cluster.$$meta.permalink,
        verb: 'DELETE'
      });

      try {
        await dateManager.manageDeletesForCluster(clusterInfo.cluster, deleteBatch);
        await apiQueries.postBatch('/batch', deleteBatch);
        $scope.close();
      } catch (error) {
        $log.error(error);
        $scope.actionPermitted = false;
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, deleteBatch));
      }
    };

    $scope.warn = async () => {
      utils.notify($scope, 'warning', 'warning', 'Opgelet: ben je zeker dat je deze SchoolentiteitCluster inclusief zijn ' +
        'relaties wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden. Selecteer \'Bevestigen\' om de ' +
        'cluster met zijn relaties definitief te verwijderen.');
      $scope.warned = true;
    };
  }
];
