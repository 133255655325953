module.exports = ['utils', (utils) => {
  'use strict';

  return (resources, noFinishEarlierThan) => {

    // if no deadline for endDate is set or resources is empty then no filter applied
    if (!noFinishEarlierThan || !resources) {
      return resources;
    }

    return resources.filter(resource =>
      !resource.endDate || !utils.abolished(resource, noFinishEarlierThan)
    );
  };
}];
