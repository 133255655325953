module.exports = ['$scope', '$uibModalInstance', '$log', 'apiQueries', 'school', 'uuid',
  'dateUtils', 'errorManager', 'utils',
  ($scope, $uibModalInstance, $log, apiQueries, school, uuid, dateUtils, errorManager, utils) => {

    'use strict';

    // modal controller

    let existingGoverningInstitutionRelations = {};

    $scope.model = {
      newRelation: {
        startDate: dateUtils.parse(school.startDate),
        endDate: null
      },
      governingInstitutions: {},
      governingInstitutionRelations: [],
      newRelations: {},
      editable: {},
      deleted: {}
    };

    $scope.today = dateUtils.getNow();

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => $uibModalInstance.close(false);

    $scope.close = () => $uibModalInstance.close();

    const getExistingGoverningInstitutionRelations = async () => {
      try {
        let governingInstitutionRelations = await apiQueries.getOrganisationalUnitsRelations({
          to: '/sam/organisationalunits/' + school.key,
          type: 'GOVERNS'/*,
          status: 'ACTIVE,FUTURE'*/
        }, {
          expand: {
            property: 'from',
            include: {
              alias: '$$contactDetails',
              href: '/sam/organisationalunits/contactdetails',
              filters: {
                type: 'MAIL'
              },
              reference: 'organisationalUnit'
            }
          }
        });

        $scope.model.governingInstitutions = {};

        governingInstitutionRelations = governingInstitutionRelations.map(governingInstitutionRelation => {
          governingInstitutionRelation.startDate = dateUtils.parse(governingInstitutionRelation.startDate);
          governingInstitutionRelation.endDate = dateUtils.parse(governingInstitutionRelation.endDate);

          governingInstitutionRelation.from.$$expanded.$$future =
            dateUtils.isAfter(governingInstitutionRelation.from.startDate, $scope.today);

          $scope.model.governingInstitutions[governingInstitutionRelation.key] =
            governingInstitutionRelation.from.$$expanded;

          existingGoverningInstitutionRelations[governingInstitutionRelation.key] =
            _.cloneDeep(governingInstitutionRelation);

          return governingInstitutionRelation;
        });

        $scope.$applyAsync(() => {
          $scope.model.governingInstitutionRelations = governingInstitutionRelations;
        });
      } catch (error) {
        $log.error('Can\'t get governings.');
        $log.error(error);
      }
    };

    getExistingGoverningInstitutionRelations();

    $scope.add = async () => {
      let newRelation = {
        key: uuid.v4(),
        from: {
          href: $scope.model.newRelation.governingInstitution.$$meta.permalink,
          $$expanded: _.cloneDeep($scope.model.newRelation.governingInstitution)
        },
        to: {href: '/sam/organisationalunits/' + school.key},
        type: 'GOVERNS',
        startDate: $scope.model.newRelation.startDate,
        endDate: $scope.model.newRelation.endDate
      };

      $scope.model.governingInstitutions[newRelation.key] = _.cloneDeep($scope.model.newRelation.governingInstitution);

      $scope.model.newRelation = {
        startDate: dateUtils.parse(school.startDate),
        endDate: null
      };

      $scope.model.governingInstitution = undefined;
      $scope.model.governingInstitutionRelations.push(newRelation);
      $scope.model.newRelations[newRelation.key] = newRelation;
    };

    $scope.editGoverningRelation = (governingRelation) => {
      $scope.model.editable[governingRelation.key] = !$scope.model.editable[governingRelation.key];
    };

    $scope.deleteGoverningRelation = (governingRelation) => {
      $scope.$applyAsync(() => {
        $scope.model.deleted[governingRelation.key] = !$scope.model.deleted[governingRelation.key];
      });
    };

    // selectable input for governingInstitutions
    $scope.refreshGoverningInstitutions = async (gov) => {
      let params = {
        'names.value': gov,
        type: 'GOVERNINGINSTITUTION'/*,
        status: 'ACTIVE,FUTURE'*/
      };

      const governingInstitutions = await apiQueries.getOrganisationalUnits(params, {
        include: {
          alias: '$$contactDetails',
          href: '/sam/organisationalunits/contactdetails',
          filters: {
            type: 'MAIL'
          },
          reference: 'organisationalUnit'
        }});

      governingInstitutions.forEach(governingInstitution => {
        governingInstitution.$$future = dateUtils.isAfter(governingInstitution.startDate, $scope.today);
      });

      return governingInstitutions.length === 0 ? [{$$displayName: 'No results'}] : governingInstitutions;
    };

    $scope.onNewGoverningInstituionSelect = async (selectedGoverningInstitution) => {

      if (selectedGoverningInstitution.$$displayName === 'No results') {
        return;
      }

      $scope.$applyAsync(() => {
        $scope.model.newRelation.governingInstitution = selectedGoverningInstitution;
      });
    };

    $scope.onUpdateGoverningInstituionSelect = async (selectedGoverningInstitution, governingRelation) => {

      if (selectedGoverningInstitution.$$displayName === 'No results') {
        return;
      }

      $scope.$applyAsync(() => {
        governingRelation.from = {
          href: selectedGoverningInstitution.$$meta.permalink,
          $$expanded: selectedGoverningInstitution
        };

      });
    };

    $scope.showStartDate = (gov) => {
      console.log('startDate', gov);
      return dateUtils.isAfter(gov.startDate, dateUtils.getNow());
    };

    $scope.showEndDate = (gov) => {
      return gov.endDate !== undefined;
    };

    $scope.save = async () => {

      let governingInstitutionsToBeUpdated = $scope.model.governingInstitutionRelations.filter(
        governingInstitutionRelation => {
          if ($scope.model.newRelations[governingInstitutionRelation.key]) {
            return !$scope.model.deleted[governingInstitutionRelation.key];
          }

          return $scope.model.deleted[governingInstitutionRelation.key] ||
            !_.isEqual(governingInstitutionRelation,
              existingGoverningInstitutionRelations[governingInstitutionRelation.key]);
        }
      );

      let batch = governingInstitutionsToBeUpdated.map(govRelation => {
        let body = _.cloneDeep(govRelation);
        body.startDate = dateUtils.toString(body.startDate);
        body.endDate = dateUtils.toString(body.endDate);
        errorManager.validatePeriod(body);

        return {
          href: '/sam/organisationalunits/relations/' + body.key,
          body: body,
          verb: $scope.model.deleted[body.key] ? 'DELETE' : 'PUT'
        };
      });

      try {
        await apiQueries.postBatch('/sam/organisationalunits/relations/batch',
          batch);
        $scope.close();
      } catch (error) {
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      }
    };

    $scope.enableSave = () => {

      const governingInstitutionsToBeUpdated = $scope.model.governingInstitutionRelations.filter(
        governingInstitutionRelation => {
          if ($scope.model.newRelations[governingInstitutionRelation.key]) {
            return !$scope.model.deleted[governingInstitutionRelation.key];
          }

          return $scope.model.deleted[governingInstitutionRelation.key] ||
            !_.isEqual(governingInstitutionRelation,
              existingGoverningInstitutionRelations[governingInstitutionRelation.key]);
        }
      );

      return governingInstitutionsToBeUpdated.length > 0;
    };
  }
];
