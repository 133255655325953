/*
 * Controller for setting the page's title
 */
module.exports = ['$scope', 'settings', 'apiQueries', ($scope, settings, apiQueries) => {
  'use strict';

  /* $scope.projectName = settings.projectName;
  $scope.projectVersion = settings.projectVersion;
  $scope.sprintNumber = settings.sprintNumber; */

  $scope.getUser = async () => {
    const user = await apiQueries.getUser();
    console.log('user is', user);
    $scope.user = user;
  };
  // $scope.getUser();
}];
