module.exports = ['$scope', '$log', '$uibModalInstance', 'utils',
  'settings', 'apiQueries', 'school', 'uuid', 'dateUtils', 'errorManager',
  async ($scope, $log, $uibModalInstance, utils,
    settings, apiQueries, school, uuid, dateUtils, errorManager) => {

    'use strict';

    $scope.model = {
      externalIds: '',
      startDate: dateUtils.parse(school.startDate),
      campusForm: {}
    };

    $scope.navModel = {
      active: 0
    };

    $scope.getLength = (obj) => Object.keys(obj).length;

    [0, 1].forEach(tabId => {
      $scope.model[tabId] = {
        nextPage: 1,
        searchValues: {
          street: '',
          zipCode: '',
          city: '',
          name: '',
          onderwijskiezer: ''
        },
        alreadyRelated: {},
        totalItems: 1,
        offset: 0,
        locations: [],
        hideSelected: true,
        singleSelect: tabId === 1,
        statusMessage: 'Loading data. Please wait...',
        itemsPerPage: settings.paginationSettings.itemsPerModal
      };
    });

    $scope.model[0].checkbox = {};

    $scope.model[2] = {
      newCampus: {}
    };

    $scope.datepickerOptions = {
      minDate: dateUtils.parse(school.startDate),
      maxDate: dateUtils.parse(school.endDate)
    };

    $scope.model[0].selectedData = [];
    $scope.model[1].selectedItem = {value: null};

    // each time startDate changes, shown data is updated
    $scope.$watch('model.startDate', () => {
      $scope.model[0].checkbox = {};
      $scope.model[0].selectedData = [];
      $scope.model[0].alreadyRelated = {};

      $scope.model[1].selectedItem.value = null;
      $scope.model[1].alreadyRelated = {};

      school.$$locations.forEach(relatedCampus => {
        const overlap = dateUtils.isOverlapping(
          {startDate: dateUtils.toString($scope.model.startDate), endDate: null},
          {startDate: relatedCampus.startDate, endDate: relatedCampus.endDate}
        );

        $scope.model[0].alreadyRelated[relatedCampus.physicalLocation.$$expanded.key] = overlap;
        $scope.model[0].checkbox[relatedCampus.physicalLocation.$$expanded.key] = overlap;
      });

      _.each($scope.model[1].locations, schoolLocation => {
        $scope.model[1].alreadyRelated[schoolLocation.key] =
          school.$$locations.some(location =>
            dateUtils.isOverlapping(location,
              {startDate: dateUtils.toString($scope.model.startDate), endDate: null}) &&
            utils.similarAddress(schoolLocation.physicalLocation.address,
              location.physicalLocation.$$expanded.address));
      });
    });

    $scope.applyFilter = async () => {
      const tabId = $scope.navModel.active;

      if (tabId === 0) {
        $scope.model[0].selectedData = [];
      } else if (tabId === 1) {
        $scope.model[1].selectedItem.value = null;
      }
      $scope.model[tabId].nextPage = 1;
      $scope.model[tabId].locations = [];

      $scope.getMore();
    };

    $scope.getMore = async () => {
      const tabId = $scope.navModel.active;

      if (tabId === 0) {
        await $scope.getMoreCampuses();
      } else if (tabId === 1) {
        await $scope.getMoreLocations();
      }
    };

    $scope.toggleCampusCheckbox = campus => {
      const model = $scope.model;

      if (model[0].checkbox[campus.key]) {
        model[0].selectedData.push(campus.key);
      } else {
        _.pull(model[0].selectedData, campus.key);
      }
    };

    $scope.schoolLocationSelected = () => {
      return 'value' in $scope.model[1].selectedItem && $scope.model[1].selectedItem.value !== null;
    };

    /* Modal controller and notifications */

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => $uibModalInstance.close(false);

    $scope.close = () => $uibModalInstance.close();

    $scope.getMoreCampuses = async () => {
      const limit = $scope.model[0].itemsPerPage,
        offset = ($scope.model[0].nextPage - 1) * $scope.model[0].itemsPerPage;

      let plKeys = [],
        plsWithGivenExternalIdentifier = [],
        plsWithGivenName = [];

      if ($scope.model[0].searchValues.onderwijskiezer) {
        const locationsByExternalIdentifier = await apiQueries.getOrganisationalUnitsLocationsExternalIdentifiers({
          value: $scope.model[0].searchValues.onderwijskiezer,
          type: 'ONDERWIJSKIEZER_ID'
        }, {
          expand: 'location'
        });

        locationsByExternalIdentifier.forEach(externalId =>
          plsWithGivenExternalIdentifier.push(externalId.location.$$expanded.physicalLocation.href.split('/').pop()));
      }

      if ($scope.model[0].searchValues.name) {
        const locationsByName = await apiQueries.getOrganisationalUnitsLocations({
          name: $scope.model[0].searchValues.name,
          status: 'ACTIVE,FUTURE'
        });

        locationsByName.forEach(namedLocation =>
          plsWithGivenName.push(namedLocation.location.$$expanded.physicalLocation.href.split('/').pop()));
      }

      if ($scope.model[0].searchValues.onderwijskiezer && $scope.model[0].searchValues.name) {
        plKeys = plsWithGivenExternalIdentifier.filter(value => plsWithGivenName.indexOf(value) !== -1);
      } else {
        plKeys = [... new Set([... plsWithGivenExternalIdentifier, ... plsWithGivenName])];
      }

      let params = {
        type: 'DOMAIN',
        limit: limit,
        offset: offset
      };

      if (plKeys.length > 0) {
        params.keyIn = plKeys.join(',');
      }

      if ($scope.model[0].searchValues.street) {
        params['address.street'] = $scope.model[0].searchValues.street;
      }

      if ($scope.model[0].searchValues.city) {
        params['address.subCity'] = $scope.model[0].searchValues.city;
      }

      if ($scope.model[0].searchValues.zipCode) {
        params['address.zipCode'] = $scope.model[0].searchValues.zipCode;
      }

      try {
        let physicalLocations = await apiQueries.getPhysicalLocations(params);

        if (physicalLocations.length === 0 && $scope.model[0].searchValues.city) {
          delete params['address.subCity'];
          params['address.city'] = $scope.model[0].searchValues.city;
          physicalLocations = await apiQueries.getPhysicalLocations(params);
        }

        let locations = physicalLocations;

        $scope.model[0].totalItems = locations.length;

        $scope.$applyAsync(() => {
          $scope.model[0].locations = $scope.model[0].locations.concat(locations);
          $scope.model[0].statusMessage = $scope.model[0].locations.length === 0
            ? 'No results found.' : 'DONE';
          $scope.model[0].noMore = physicalLocations.length < limit || locations.length === 0;
          $scope.model[0].nextPage++;
        });
      } catch (error) {
        $log.error('Can\'t get existing campuses.');
        $log.error(error);
      }
    };

    const getGoverningInstitution = async () => {

      try {
        const governingInstitutions = await apiQueries.getOrganisationalUnitsRelations({
          to: '/sam/organisationalunits/' + school.key,
          type: 'GOVERNS',
          status: 'ACTIVE,FUTURE'
        }, {
          expand: 'from'
        });

        if (governingInstitutions && governingInstitutions.length > 0) {
          $scope.governingInstitution = governingInstitutions[0];
        }
      } catch (error) {
        $log.error('Can\'t get governing institutions.');
        $log.error(error);
      }
    };

    $scope.getMoreLocations = async () => {

      const limit = $scope.model[1].itemsPerPage,
        offset = ($scope.model[1].nextPage - 1) * $scope.model[1].itemsPerPage;

      let plKeys = [],
        plsUnderTheSameGoverningInstitution = [],
        plsWithGivenExternalIdentifier = [],
        plsWithGivenName = [];

      if ($scope.governingInstitution) {
        const schoolRelationsWithTheSameGoverningInstitution = await apiQueries.getOrganisationalUnitsRelations({
          type: 'GOVERNS',
          from: '/sam/organisationalunits/' + $scope.governingInstitution.from.$$expanded.key,
          'to.type': 'SCHOOL'
        });

        const locationsUnderTheSameGoverningInstitution = await apiQueries.getOrganisationalUnitsLocations({
          organisationalUnitIn: schoolRelationsWithTheSameGoverningInstitution.map(schoolRelation =>
            schoolRelation.to.href.split('/').pop()).join(','),
          status: 'ACTIVE,FUTURE'
        });

        locationsUnderTheSameGoverningInstitution.forEach(location =>
          plsUnderTheSameGoverningInstitution.push(location.physicalLocation.href.split('/').pop()));
      }

      if ($scope.model[1].searchValues.onderwijskiezer) {
        const locationsByExternalIdentifier = await apiQueries.getOrganisationalUnitsLocationsExternalIdentifiers({
          value: $scope.model[1].searchValues.onderwijskiezer,
          type: 'ONDERWIJSKIEZER_ID'
        }, {
          expand: 'location'
        });

        locationsByExternalIdentifier.forEach(externalId =>
          plsWithGivenExternalIdentifier.push(externalId.location.$$expanded.physicalLocation.href.split('/').pop()));
      }

      if ($scope.model[1].searchValues.name) {
        const locationsByName = await apiQueries.getOrganisationalUnitsLocations({
          name: $scope.model[1].searchValues.name,
          status: 'ACTIVE,FUTURE'
        });

        locationsByName.forEach(namedLocation =>
          plsWithGivenName.push(namedLocation.location.$$expanded.physicalLocation.href.split('/').pop()));
      }

      if ($scope.model[1].searchValues.onderwijskiezer && $scope.model[0].searchValues.name) {
        plKeys = plsWithGivenExternalIdentifier.filter(value => plsWithGivenName.indexOf(value) !== -1);
      } else {
        plKeys = [... new Set([... plsWithGivenExternalIdentifier, ... plsWithGivenName])];
      }

      if (plKeys.length > 0 && $scope.governingInstitution) {
        plKeys = plsUnderTheSameGoverningInstitution.filter(value => plKeys.indexOf(value) !== -1);
      } else {
        plKeys = [... new Set([... plKeys, ... plsUnderTheSameGoverningInstitution])];
      }

      let params = {
        type: 'DOMAIN',
        limit: limit,
        offset: offset
      };

      if (plKeys.length > 0) {
        params.keyIn = plKeys.join(',');
      }

      if ($scope.model[1].searchValues.street) {
        params['address.street'] = $scope.model[1].searchValues.street;
      }

      if ($scope.model[1].searchValues.city) {
        params['address.city'] = $scope.model[1].searchValues.city;
        params['address.subCity'] = $scope.model[1].searchValues.city;
      }

      if ($scope.model[1].searchValues.zipCode) {
        params['address.zipCode'] = $scope.model[1].searchValues.zipCode;
      }

      let options = {
        include: [],
        inBatch: '/batch'
      };

      options.include.push({
        alias: '$$locations',
        href: '/sam/organisationalunits/locations',
        filters: {
          status: 'ACTIVE,FUTURE',
          type: 'VESTIGINGSPLAATS'
        },
        reference: 'physicalLocation',
        expand: 'organisationalUnit'
      });

      try {

        const physicalLocations = await apiQueries.getPhysicalLocations(params, options);

        let locations = [];

        _.each(physicalLocations, physicalLocation =>
          _.each(physicalLocation.$$locations, location => {
            location.physicalLocation = _.omit(physicalLocation, '$$locations');
            locations.push(location);
          }));

        locations = _.uniqBy(locations, 'key');

        $scope.model[1].totalItems = locations.length;
        locations = _.map(locations, location => {
          location.organisationalUnit = location.organisationalUnit.$$expanded;
          return location;
        });

        _.each(locations, schoolLocation => {
          $scope.model[1].alreadyRelated[schoolLocation.key] =
            school.$$locations.some(location =>
              dateUtils.isOverlapping(location,
                {startDate: dateUtils.toString($scope.model.startDate), endDate: null}) &&
              utils.similarAddress(schoolLocation.physicalLocation.address,
                location.physicalLocation.$$expanded.address));
        });

        $scope.$applyAsync(() => {
          $scope.model[1].locations = $scope.model[1].locations.concat(locations);
          $scope.model[1].statusMessage = $scope.model[1].locations.length === 0
            ? 'No results found.' : 'DONE';
          $scope.model[1].noMore = physicalLocations.length < limit || locations.length === 0;
          $scope.model[1].nextPage++;
        });
      } catch (error) {
        $log.error('Can\'t get physical locations.');
        $log.error(error);
      }
    };

    $scope.addExistingCampuses = async () => {
      const data = [];

      try {

        $scope.model[0].selectedData.map(campusKey => {
          const relKey = uuid.v4();

          const datum = {
            href: '/sam/organisationalunits/locations/' + relKey,
            verb: 'PUT',
            body: {
              key: relKey,
              organisationalUnit: {href: '/sam/organisationalunits/' + school.key},
              physicalLocation: {href: '/sam/physicallocations/' + campusKey},
              startDate: dateUtils.toString($scope.model.startDate),
              endDate: school.endDate,
              type: 'CAMPUS'
            }
          };

          errorManager.validatePeriod(datum.body);

          data.push(datum);

          // create an external indentifiers
          if ($scope.model.externalIds) {
            $scope.model.externalIds.split(',').forEach(externalId => {
              const externalIdentifierKey = uuid.v4();
              data.push({
                href: '/sam/organisationalunits/locations/externalidentifiers/' + externalIdentifierKey,
                verb: 'PUT',
                body: {
                  key: externalIdentifierKey,
                  type: 'ONDERWIJSKIEZER_ID',
                  value: parseInt(externalId, 10),
                  location: {href: '/sam/organisationalunits/locations/' + relKey}
                }
              });
            });
          }
        });


        await apiQueries.postBatch('/sam/organisationalunits/locations/batch', data);
        $scope.close();
      } catch (error) {
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, data));
      }
    };

    $scope.addNewCampus = async (physicalLocation) => {
      $scope.notificationOptions.enabled = false;
      const batch = [];

      const addCampus = async (exisingPhysicalLocation, campusName) => {
        // link campus to school entity
        const ouLocation = {
          key: uuid.v4(),
          organisationalUnit: {href: '/sam/organisationalunits/' + school.key},
          physicalLocation: {href: '/sam/physicallocations/' + exisingPhysicalLocation.key},
          name: campusName,
          startDate: utils.dateToIso($scope.model.startDate),
          endDate: school.endDate,
          type: 'CAMPUS'
        };

        batch.push({
          href: '/sam/organisationalunits/locations/' + ouLocation.key,
          verb: 'PUT',
          body: ouLocation
        });

        errorManager.validatePeriod(ouLocation);

        // create an external indentifiers
        if ($scope.model.externalIds) {
          $scope.model.externalIds.split(',').forEach(externalId => {
            const externalIdentifierKey = uuid.v4();
            batch.push({
              href: '/sam/organisationalunits/locations/externalidentifiers/' + externalIdentifierKey,
              verb: 'PUT',
              body: {
                key: externalIdentifierKey,
                type: 'ONDERWIJSKIEZER_ID',
                value: parseInt(externalId, 10),
                location: {href: '/sam/organisationalunits/locations/' + ouLocation.key}
              }
            });
          });
        }

        return apiQueries.postBatch('/batch', batch);
      };

      try {
        if (physicalLocation.key) {
          // the physicalLocation comes from an official school and is an existing phisicalLocation
          await addCampus(physicalLocation);
        } else {
          await utils.adjustAddress(physicalLocation.address);
          const existingPhysicalLocation = await utils.findExistingPhysicalLocation(physicalLocation.address);
          const campusName = $scope.model[2].newCampus.name;
          await addCampus(existingPhysicalLocation || await utils.createPhysicalLocation(physicalLocation.address), campusName);
        }
        $scope.close();

      } catch (error) {
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      }
    };

    await getGoverningInstitution();
    await $scope.getMoreCampuses();
    await $scope.getMoreLocations();
  }];
