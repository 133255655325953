const { getRelatedSchoolEntities } = require("@kathondvla/sam-utils/epd-utils");
const { syncMsRelsWithEpds } = require("./helpers");

const STEM_CATEGORIE_CODES = {
  '/sam/commons/stemcategorieen/a77f1eff-abf9-11eb-a294-bab24c68247b': 'S', // STEM,
  '/sam/commons/stemcategorieen/a7885c69-abf9-11eb-a294-bab24c68247b': 'ZS', // Zorg-Stem
  '/sam/commons/stemcategorieen/a78c8f95-abf9-11eb-a294-bab24c68247b': 'LS', // Lichte Stem
  '/sam/commons/stemcategorieen/a78d9873-abf9-11eb-a294-bab24c68247b': 'NS' // Niet Stem
};

module.exports = ['$scope', '$uibModalInstance', 'school', 'location', 'educationalProgrammeDetail',
  '$log', 'apiQueries', '$timeout', 'uuid', 'settings', 'errorManager', 'utils', 'epdUtils', 'dateUtils',
  async ($scope, $uibModalInstance, school, location, educationalProgrammeDetail, $log, apiQueries,
    $timeout, uuid, settings, errorManager, utils, epdUtils, dateUtils) => {
    'use strict';

    const ONE_DAY = 60 * 60 * 24;
    const MAX_AGS_TO_BE_ADDED_AT_ONCE = 50;

    $scope.selectedAG = educationalProgrammeDetail ? educationalProgrammeDetail.ag.$$expanded : null;
    $scope.selectedBuoType = educationalProgrammeDetail && educationalProgrammeDetail.buoType ?
      educationalProgrammeDetail.buoType : null;
    $scope.location = location;

    $scope.model = [];

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => $uibModalInstance.close({
      cancel: true
    });

    let addedAbolishedAgs = false;

    $scope.close = () => $uibModalInstance.close({
      addedAbolishedAgs: addedAbolishedAgs,
      cancel: false
    });

    $scope.forms = {
      startDateForm: {}
    };

    let filterTextTimeout,
      filterBuoTypeTimeout;
    [0, 1].forEach(tabId => {
      $scope.model[tabId] = {
        nextPage: 1,
        loading: false,
        noMore: true,
        statusMessage: 'Loading data. Please wait...',
        itemsPerPage: tabId === 0 ? 10 : settings.paginationSettings.itemsPerModal,
        searchValue: '',
        agNumber: '',
        totalItems: 1,
        checkboxes: {},
        selectedItems: [],
        items: [],
        hideSelected: true,
        alreadyAttached: {},
        filter: {value: ''},
        officialLocations: []
      };

      $scope.$watch('model[' + tabId + '].searchValue', (newValue, oldValue) => {
        if (newValue !== oldValue && newValue === '' || newValue.length >= 4) {
          if (filterTextTimeout) {
            $timeout.cancel(filterTextTimeout);
          }

          $scope.model[tabId].searchValue = newValue;
          filterTextTimeout = $timeout(async () => {
            $scope.model[tabId].checkboxes = _.extend({}, $scope.model[tabId].alreadyAttached);

            $scope.model[tabId].items = [];
            $scope.model[tabId].statusMessage = 'Loading data. Please wait...';
            $scope.model[tabId].noMore = false;
            $scope.model[tabId].nextPage = 1;

            await $scope.getMore();
          }, 250); // delay 250 ms
        }
      });

      $scope.$watch('model[' + tabId + '].agNumber', (newValue, oldValue) => {
        if (newValue !== oldValue && newValue === '' || newValue.length >= 4) {
          if (filterTextTimeout) {
            $timeout.cancel(filterTextTimeout);
          }

          $scope.model[tabId].agNumber = newValue;
          filterTextTimeout = $timeout(async () => {
            $scope.model[tabId].checkboxes = _.extend({}, $scope.model[tabId].alreadyAttached);

            $scope.model[tabId].items = [];
            $scope.model[tabId].statusMessage = 'Loading data. Please wait...';
            $scope.model[tabId].noMore = false;
            $scope.model[tabId].nextPage = 1;

            await $scope.getMore();
          }, 250); // delay 250 ms
        }
      });
    });

    $scope.$watch('model[1].buoType', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        if (filterBuoTypeTimeout) {
          $timeout.cancel(filterBuoTypeTimeout);
        }

        filterBuoTypeTimeout = $timeout(() => {
          //$scope.$applyAsync(() => {
          $scope.model[1].nextPage = 1;
          $scope.model[1].items = [];
          $scope.getMore();
          //});
        }, 250); // delay 250 ms
      }
    });

    $scope.navModel = {
      activeTab: 0,
      startDate: dateUtils.parse(dateUtils.getLast([dateUtils.getStartOfSchoolYear(), location.startDate]))
    };

    $scope.startDate = dateUtils.toString($scope.navModel.startDate);
    $scope.dayAfterStartDate = dateUtils.getNextDay($scope.startDate);

    $scope.datepickerOptions = {
      minDate: dateUtils.parse(location.startDate),
      maxDate: dateUtils.parse(location.endDate),
      format: 'yyyy-dd-MM'
    };

    $scope.model[0].locationCheckboxes = {};

    const initModel = () => {
      [0, 1].forEach(tabId => {
        $scope.model[tabId].nextPage = 1;
        $scope.model[tabId].selectedItems = [];
        $scope.model[tabId].checkboxes = {};
        $scope.model[tabId].alreadyAttached = {};
        $scope.model[tabId].items = [];
        $scope.model[tabId].statusMessage = 'Loading data. Please wait...';
        $scope.model[tabId].noMore = true;
      });
    };

    const getGoverningInstitutions = async () => {
      try {
        const governingInstitutions = await apiQueries.getOrganisationalUnitsRelations({
          type: 'GOVERNS',
          to: '/sam/organisationalunits/' + school.key
        });

        $scope.governingInstitutions = governingInstitutions;
        $scope.$apply();
      } catch (error) {
        $log.error('Can\'t get governing institutions.', error);
      }
    };

    await getGoverningInstitutions();

    const updateItems = (items, noMore, noStatusMessage) => {
      const model = $scope.model[0];
      $scope.$applyAsync(() => {
        model.noMore = noMore;
        model.nextPage++;
        model.items =
          model.items.concat(items);
        for(let item of model.items) {
          model.locationCheckboxes[item.key] =
                item.$$educationalProgrammeDetails.every(epd =>
                  epd.$$locations.every(loc =>
                    model.alreadyAttached[loc.key] || model.checkboxes[loc.key]));
        }

        if (!noStatusMessage) {
          model.statusMessage = model.items.length === 0
            ? 'No results found.' : 'DONE';
        }
      });
    };

    const getEducationalProgrammeDetailsLocations = async (loc) => {
      const epdLocs =  await apiQueries.getEducationalProgrammeDetailsLocations({
        'educationalProgrammeDetail.organisationalUnit': loc.organisationalUnit.href,
        physicalLocation: loc.physicalLocation.href,
        startDateBefore: $scope.startDate,
        endDateAfter: $scope.dayAfterStartDate
      }, {
        caching: {timeout: ONE_DAY},
        expand: [{
          property: 'educationalProgrammeDetail',
          expand: [{
            property: 'buoType',
            required: false
          }, {
            property: 'ag',
            required: false,
            expand: [{
              property: 'mainstructure'
            }, {
              property: 'buoType',
              required: false
            }, {
              property: 'buoOpleidingsvormCodes',
              required: false
            }, {
              property: 'leerjaar',
              required: false
            }, {
              property: 'graad',
              required: false
            }, {
              property: 'structuuronderdeel',
              required: false
            }, {
              property: 'onderwijsvorm',
              required: false
            }, {
              property: 'buoOpleidingsvorm',
              required: false
            }, {
              property: 'buoFase',
              required: false
            }, {
              property: 'buoOpleiding',
              required: false
            }, {
              property: 'leerweg',
              required: false
            }, {
              property: 'stelselSo',
              required: false
            }, {
              property: 'finaliteit',
              required: false
            }]
          }]
        }],
        inBatch: '/batch'
      });
      return epdLocs
        .filter((epdLoc) => epdLoc.educationalProgrammeDetail.$$expanded.ag)
        .map(epdLoc => {
          if (epdLoc.educationalProgrammeDetail.$$expanded.ag.$$expanded.stemCategorie) {
            epdLoc.educationalProgrammeDetail.$$expanded.ag.$$expanded.stemCategorie.$$expanded = {
              code: STEM_CATEGORIE_CODES[epdLoc.educationalProgrammeDetail.$$expanded.ag.$$expanded.stemCategorie.href]
            };
          }
          return epdLoc;
        });
    };

    const getEducationalProgrammeDetails = async (loc) => {
      const educationalProgrammeDetailsLocations = await getEducationalProgrammeDetailsLocations(loc);

      let educationalProgrammeDetails = educationalProgrammeDetailsLocations.map(
        epdl => epdl.educationalProgrammeDetail.$$expanded);

      educationalProgrammeDetails = educationalProgrammeDetails.filter(epd => {
        const selectedBuoTypeHref = $scope.selectedBuoType ? $scope.selectedBuoType.href : undefined;
        const epdBuoTypeHref = epd.buoType ? epd.buoType.href : undefined;

        return (!$scope.selectedAG ||
            epd.ag.href === $scope.selectedAG.$$meta.permalink &&
            selectedBuoTypeHref === epdBuoTypeHref) &&
          ($scope.model[0].agNumber === '' || epd.ag.$$expanded.code == $scope.model[0].agNumber);
      });

      educationalProgrammeDetails = educationalProgrammeDetails.filter(epd => {
        epd.$$locations = educationalProgrammeDetailsLocations.filter(
          epdl => epdl.educationalProgrammeDetail.$$expanded.key === epd.key);

        return epd.$$locations && epd.$$locations.length > 0;
      });

      return educationalProgrammeDetails;
    };

    const sortOfficialOffers = (officialLocationsWithEducationalProgrammeDetails) => {
      const model = $scope.model[0];

      officialLocationsWithEducationalProgrammeDetails =
        officialLocationsWithEducationalProgrammeDetails.filter(officialLocation =>
          officialLocation.$$educationalProgrammeDetails &&
          (!model.searchValue ||
            utils.textPartialMatch(officialLocation.organisationalUnit.$$displayName, model.searchValue)) &&
          officialLocation.$$educationalProgrammeDetails.length > 0);

      officialLocationsWithEducationalProgrammeDetails.forEach(officialLocation => {
        epdUtils.sortEducationProgramme(officialLocation.$$educationalProgrammeDetails);
      });
    };

    const updateOfficialOffers = async (officialOffers, func, page, stopChecker) => {
      if (!stopChecker) {
        await updateItems(officialOffers, true);
        return;
      }
      const model = $scope.model[0],
        limit = model.itemsPerPage;

      if ((model.searchValue || $scope.selectedAG) &&
        officialOffers.length < limit && stopChecker.length === limit) {
        await updateItems(officialOffers, false, true);
        await func(page + 1);
        return;
      }
      
      updateItems(officialOffers,
        stopChecker.length < limit || officialOffers.length === 0
      );
    };

    const getOfficialLocationsAtSameAddress = async (page) => {
      try {
        let params = {
          type: 'VESTIGINGSPLAATS',
          physicalLocation: location.physicalLocation.href,
          startDateBefore: $scope.startDate,
          endDateAfter: $scope.dayAfterStartDate
        };

        let vestigingsplaatsen = await apiQueries.getOrganisationalUnitsLocations(_.pickBy(params), {
          expand: {
            property: 'organisationalUnit'
          }
        });

        let officialOffers = [];

        for (let loc of vestigingsplaatsen) {

          const educationalProgrammeDetails = await getEducationalProgrammeDetails(loc);

          if (educationalProgrammeDetails.length > 0) {
            officialOffers.push(
              Object.assign({}, loc, {
                organisationalUnit: loc.organisationalUnit.$$expanded,
                physicalLocation: location.physicalLocation.$$expanded,
                $$educationalProgrammeDetails: educationalProgrammeDetails
              }),
            );
          }
        }

        sortOfficialOffers(officialOffers);
        await updateOfficialOffers(officialOffers, getOfficialLocationsAtSameAddress, page, [location.physicalLocation.$$expanded]);

      } catch(error) {
        $log.error('Can\'t get ags for physicallocations.');
        $log.error(error);
      }
    };

    $scope.model[0].loading = true;
    await getOfficialLocationsAtSameAddress(1);
    $scope.model[0].loading = false;

    const generateComposedKey = (keyArray) =>
      keyArray.join('-');

    const getOfficialLocationWithSameGoverningInstitutions = async (page) => {
      const model = $scope.model[0],
        limit = model.itemsPerPage,
        offset = (page - 1) * model.itemsPerPage;

      if (!$scope.governingInstitutions ||
              $scope.governingInstitutions.length === 0) {
        $scope.$applyAsync(() => {
          model.items = [];
          model.statusMessage = 'No results found.';
          model.noMore = true;
        });

        return;
      }

      try {
        let params = {
          type: 'GOVERNS',
          fromIn: $scope.governingInstitutions.map(governingInstitution =>
            governingInstitution.from.href.split('/').pop()),
          'to.type': 'SCHOOL',
          startDateBefore: $scope.startDate,
          endDateAfter: $scope.dayAfterStartDate,
          limit: limit,
          offset: offset
        };

        let schoolRelations = await apiQueries.getOrganisationalUnitsRelations(_.pickBy(params), {
          expand: {
            property: 'to',
            include: [{
              alias: '$$locations',
              href: '/sam/organisationalunits/locations',
              reference: 'organisationalUnit',
              expand: {
                property: 'physicalLocation'
              },
              filters: {
                startDateBefore: $scope.startDate,
                endDateAfter: $scope.dayAfterStartDate,
                type: 'VESTIGINGSPLAATS',
              }
            }]
          }
        });

        let officialOffers = [];

        for (const schoolRelation of schoolRelations) {
          for(let loc of schoolRelation.to.$$expanded.$$locations) {

            const educationalProgrammeDetails = await getEducationalProgrammeDetails(loc);

            if (educationalProgrammeDetails.length > 0) {
              officialOffers.push(
                Object.assign({}, loc, {
                  organisationalUnit: schoolRelation.to.$$expanded,
                  physicalLocation: loc.physicalLocation.$$expanded,
                  $$educationalProgrammeDetails: educationalProgrammeDetails
                }),
              );
            }
          }
        }

        sortOfficialOffers(officialOffers);
        await updateOfficialOffers(officialOffers, getOfficialLocationWithSameGoverningInstitutions, page, schoolRelations);

      } catch(error) {
        $log.error('Can\'t get ags for physicallocations.', error);
        $log.error(error);
      }
    };

    /**
     * Related institutions means here: all institutions that have at least one physical location at the same address as this campus.
     * Normally related institutions means institutions that have links throught there offer and have a CAN_ACT_AS relation as a consequence.
     * But we don't base ourselves here on offer because we are creating offer here and therefore use this alternative definition of related instituitons.
     */
    const getAllOfficialLocationsOfRelatedInsititutions = async (page) => {

      try {
        const allOfficalLocationsAtSameAddress = await apiQueries.getOrganisationalUnitsLocations({
          type: 'VESTIGINGSPLAATS',
          startDateBefore: $scope.startDate,
          endDateAfter: $scope.dayAfterStartDate,
          physicalLocation: location.physicalLocation.href
        });

        const schoolHrefs = allOfficalLocationsAtSameAddress.map((loc) => loc.organisationalUnit.href);
        if (schoolHrefs.length === 0) {
          // make sure to stop here, because if the schoolHrefs param is undefined you will start getting all offer of ALL official schools
          $scope.$applyAsync(() => {
            $scope.model[0].items = [];
            $scope.model[0].statusMessage = 'No results found.';
            $scope.model[0].noMore = true;
          });

          return;
        }

        const allVplsOfSchoolsThatHaveOneVplAtSameAddressAsCampus = await apiQueries.getOrganisationalUnitsLocations({
          type: 'VESTIGINGSPLAATS',
          startDateBefore: $scope.startDate,
          endDateAfter: $scope.dayAfterStartDate,
          organisationalUnit: schoolHrefs.join(',')
        }, {
          expand: [{
            property: 'physicalLocation'
          }, {
            property: 'organisationalUnit'
          }]
        });

        let officialOffers = [];

        for (let loc of allVplsOfSchoolsThatHaveOneVplAtSameAddressAsCampus) {

          const educationalProgrammeDetails = await getEducationalProgrammeDetails(loc);

          if (educationalProgrammeDetails.length > 0) {
            officialOffers.push(
              Object.assign({}, loc, {
                organisationalUnit: loc.organisationalUnit.$$expanded,
                physicalLocation: loc.physicalLocation.$$expanded,
                $$educationalProgrammeDetails: educationalProgrammeDetails
              }),
            );
          }
        }

        sortOfficialOffers(officialOffers);
        await updateOfficialOffers(officialOffers, getAllOfficialLocationsOfRelatedInsititutions, page);

      } catch(error) {
        $log.error('Can\'t get ags for physicallocations.');
        $log.error(error);
      }
    };

    const getAgsAtOfficialLocations = async (filterValue) => {
      const model = $scope.model[0];

      model.noMore = model.nextPage === 1;

      model.loading = true;
      switch (filterValue) {
      // same governing institution = all official schoollocations of all institutionnumber(s)
      // who belong to the same governing institution as the schoolentity
      case 'zelfdeInstellingnummer':
        await getAllOfficialLocationsOfRelatedInsititutions(model.nextPage);
        break;
      // same institution(s) = all official schoollocations of all institutionnumber(s) who have
      // an official schoollocation with the same adress as the campus/building
      case 'zelfdeBestuur':
        await getOfficialLocationWithSameGoverningInstitutions(model.nextPage);
        break;
      // same address = all oficial schoollocations with same adress as the campus/building
      default:
        await getOfficialLocationsAtSameAddress(model.nextPage);
      }

      model.loading = false;
    };

    $scope.$watch('model[0].filter.value', async (newValue, oldValue) => {
      if (newValue !== oldValue) {

        const model = $scope.model[0];

        model.items = [];
        model.statusMessage = 'Loading data. Please wait...';
        model.noMore = false;
        model.nextPage = 1;

        await getAgsAtOfficialLocations(newValue);
      }
    });

    $scope.getMore = async () => {
      if ($scope.navModel.activeTab === 0) {
        await getAgsAtOfficialLocations($scope.model[0].filter.value);
      } else {
        await $scope.getAgs();
      }
    };

    $scope.model[0].filter.value = 'zelfdeAdres';

    const loadExistingEducationalProgrammes = async () => {

      let model0 = $scope.model[0],
        model1 = $scope.model[1];

      if (!location.$$educationalProgrammeDetailsLocations) {
        return;
      }

      for (const epdl of location.$$educationalProgrammeDetailsLocations) {
        // if epdl is active on startDate and has relations with official schools
        if (epdl.$$relations && dateUtils.isOverlapping(epdl, {
          startDate: $scope.startDate,
          endDate: null})) {

          for (const relation of epdl.$$relations) {

            // if the relation is active and its period overlaps the new period
            // then that relation can not be added, so checked

            if (dateUtils.isOverlapping({
              startDate: $scope.startDate,
              endDate: null},
            relation
            )) {
              model0.checkboxes[relation.to.$$expanded.key] = true;
              model0.alreadyAttached[relation.to.$$expanded.key] = true;
            }
          }
        }
      }

      for (const epd of school.$$educationalProgrammeDetails) {
        if (!epd.$$locations) {
          continue;
        }

        for(const edpLoc of epd.$$locations) {
          if (dateUtils.isOverlapping({
            startDate: $scope.startDate,
            endDate: null},
          edpLoc)) {
            const composedAGKey = generateComposedKey([
              edpLoc.physicalLocation.href.split('/').pop(),
              epd.ag.$$expanded.key,
              epd.buoType ? epd.buoType.$$expanded.key : '']);

            model1.checkboxes[composedAGKey] = true;
            model1.alreadyAttached[composedAGKey] = true;
          }
        }

      }
    };
    await loadExistingEducationalProgrammes();

    // each time startDate changes, shown data is updated
    $scope.$watch('navModel.startDate', async (newValue, oldValue) => {
      if ($scope.forms.startDateForm.$valid && newValue !== oldValue) {

        $scope.startDate = dateUtils.toString($scope.navModel.startDate);
        $scope.dayAfterStartDate = dateUtils.getNextDay($scope.startDate);

        const model = $scope.model[0];
        // reset selected ags after each startDate change
        try {
          model.loading = true;
          initModel();
          await loadExistingEducationalProgrammes();
          await $scope.getMore();
          model.loading = false;
        } catch (error) {
          $log.error('Can\'t existing ag relations.');
          $log.error(error);
        }
      }
    });

    $scope.toggleAgSchoolLocationCheckbox = (item, epd, epdl) => {
      const model = $scope.model[0];

      if (model.checkboxes[epdl.key]) {
        model.selectedItems.push({
          key: epdl.key,
          educationalProgrammeDetail: epd,
          educationalProgrammeDetailLocation: epdl
        });
      } else {
        _.pullAllBy(model.selectedItems, [{key: epdl.key}], 'key');
      }

      model.locationCheckboxes[item.key] =
        item.$$educationalProgrammeDetails.every(programme =>
          programme.$$locations.every(loc =>
            model.alreadyAttached[loc.key] || model.checkboxes[loc.key]));
    };

    $scope.toggleAgCheckbox = ag => {
      const model = $scope.model[1];

      const composedAGKey = generateComposedKey([
        location.physicalLocation.$$expanded.key,
        ag.key,
        model.buoType ? model.buoType.key : ''
      ]);

      if (model.checkboxes[composedAGKey]) {
        model.selectedItems.push(ag);
      } else {
        _.pullAllBy(model.selectedItems, [{key: ag.key}], 'key');
      }
    };

    $scope.toggleAllAgCheckboxes = item => {
      const model = $scope.model[0],
        toggleStatus = model.locationCheckboxes[item.key];

      for(let epd of item.$$educationalProgrammeDetails) {
        for(let epdl of epd.$$locations) {
          if (!model.alreadyAttached[epdl.key]) {
            model.checkboxes[epdl.key] = toggleStatus;
            $scope.toggleAgSchoolLocationCheckbox(item, epd, epdl);
          }
        }
      }
    };

    $scope.getAgs = async () => {

      if ($scope.selectedAG) {
        return;
      }

      let model = $scope.model[1];
      const params = {
        limit: model.itemsPerPage,
        offset: (model.nextPage - 1) * model.itemsPerPage,
        startDateBefore: $scope.startDate,
        endDateAfter: $scope.dayAfterStartDate,
        orderBy: 'code'
      };

      if (model.searchValue !== '') {
        params.nameContains = model.searchValue;
      }

      if (model.agNumber !== '') {
        params.code = model.agNumber;
      }

      let mainstructures;

      if (model.buoType) {
        mainstructures = await apiQueries.getResources('/sam/commons/mainstructures', {
          code: '321'
        }, {caching: {timeout: ONE_DAY}});
      } else {
        mainstructures = await apiQueries.getResources('/sam/commons/mainstructures', {
          codeIn: '111,121,211,221,311,314,316,312,321'
        }, {caching: {timeout: ONE_DAY}});
      }

      params.mainstructureIn = mainstructures.map(ms => ms.key).join(',');

      try {
        const ags = await apiQueries.getResources('/sam/commons/ags',
          params, {
            expand: [{
              property: 'buoOpleidingsvorm',
              required: false
            }, {
              property: 'finaliteit',
              required: false
            }]
          }, {caching: {timeout: ONE_DAY}});
          ags.forEach(ag => {
            if (ag.stemCategorie) {
              ag.stemCategorie.$$expanded = {
                code: STEM_CATEGORIE_CODES[ag.stemCategorie.href]
              };
            }
          });

        $scope.$applyAsync(() => {
          model.items =
            model.items.concat(ags);
          model.statusMessage = model.items.length === 0
            ? 'No results found.' : 'DONE';
          model.noMore =
            ags.length < model.itemsPerPage;
          model.nextPage++;
        });
      } catch (error) {
        $log.error('Can\'t get ags.');
        $log.error(error);
      }
    };
    $scope.getAgs();

    const addEducationalProgrammeDetailsLocationsRelations = async () => {
      const batch = [],
        ouKey = school.key,
        model = $scope.model[0],
        startDate = $scope.startDate;

      const doBatch = (existingProgrammes, existingMsRels) => {
        let groupBatch = [];
        for (let item of model.selectedItems) {

          const officialEducationalProgrammeDetail = item.educationalProgrammeDetail,
            officialEducationalProgrammeDetailLocation = item.educationalProgrammeDetailLocation,
            endDate = dateUtils.getFirst([location.endDate, officialEducationalProgrammeDetailLocation.endDate]);

          if (dateUtils.isBefore(endDate, dateUtils.getNow())) {
            addedAbolishedAgs = true;
          }

          // data imported from Lars don't include buoType info
          let realEducationalProgrammeDetail = _.find(existingProgrammes, ep => {

            const epBuoTypeHref = ep.buoType ? ep.buoType.href : undefined;
            const officialEducationalProgrammeDetailBuoTypeHref =
              officialEducationalProgrammeDetail.buoType ? officialEducationalProgrammeDetail.buoType.href : undefined;

            return ep.ag.href === officialEducationalProgrammeDetail.ag.href &&
              epBuoTypeHref === officialEducationalProgrammeDetailBuoTypeHref &&
            dateUtils.isOverlapping(ep,
              {startDate: startDate,
                endDate: endDate});
          });

          // Create a relation between ou and ag (educationalProgrammeDetails) if not exist
          let realEducationalProgrammeDetailKey;

          if (!realEducationalProgrammeDetail) {
            realEducationalProgrammeDetailKey = uuid.v4();
            realEducationalProgrammeDetail = {
              key: realEducationalProgrammeDetailKey,
              organisationalUnit: {href: '/sam/organisationalunits/' + ouKey},

              ag: {href: officialEducationalProgrammeDetail.ag.href, $$expanded: officialEducationalProgrammeDetail.ag.$$expanded},
              buoType:
                officialEducationalProgrammeDetail.buoType ?
                  {href: officialEducationalProgrammeDetail.buoType.href} : undefined,
              startDate: startDate,
              endDate: endDate
            };

            errorManager.validatePeriod(realEducationalProgrammeDetail);

            groupBatch.push({
              href: '/sam/educationalprogrammedetails/' + realEducationalProgrammeDetailKey,
              verb: 'PUT',
              body: realEducationalProgrammeDetail
            });
            existingProgrammes.push(realEducationalProgrammeDetail);
          } else {
            realEducationalProgrammeDetailKey = realEducationalProgrammeDetail.key;

            if (!dateUtils.isCovering(realEducationalProgrammeDetail, {
              startDate: startDate,
              endDate: endDate
            })) {
              // check whether new dates for educationalProgrammeDetail is out of date period range defined,
              // if so, then update dates on the existing educationalProgrammeDetail

              realEducationalProgrammeDetail = _.extend({}, realEducationalProgrammeDetail, {
                startDate: utils.minDate([startDate,
                  realEducationalProgrammeDetail.startDate]),
                endDate: endDate
              });

              errorManager.validatePeriod(realEducationalProgrammeDetail);

              groupBatch.push({
                href: '/sam/educationalprogrammedetails/' + realEducationalProgrammeDetailKey,
                verb: 'PUT',
                body: realEducationalProgrammeDetail
              });
            }
          }
          // denormalized direct relation with mainstructures needs to be kept in sync with indirect relations (epds pointing to ags and ag has a mainstructure)
          syncMsRelsWithEpds(existingProgrammes, existingMsRels, groupBatch);

          // Create a relation between ou, ag and pl (educationalProgrammeDetailsLocations) if not exist
          // any valid for the period

          let realEducationalProgrammeDetailLocation;

          if (realEducationalProgrammeDetail) {
            realEducationalProgrammeDetailLocation =
              _.find(realEducationalProgrammeDetail.$$locations, loc =>
                loc.physicalLocation.href === location.physicalLocation.href &&
                dateUtils.isOverlapping(loc,
                  {startDate: startDate, endDate: null})
              );
          }

          if (!realEducationalProgrammeDetailLocation) {

            realEducationalProgrammeDetailLocation = {
              key: uuid.v4(),
              educationalProgrammeDetail: {href: '/sam/educationalprogrammedetails/' + realEducationalProgrammeDetailKey},
              physicalLocation: {href: location.physicalLocation.href},
              startDate: startDate,
              endDate: endDate
            };

            groupBatch.push({
              href: '/sam/educationalprogrammedetails/locations/' + realEducationalProgrammeDetailLocation.key,
              verb: 'PUT',
              body: realEducationalProgrammeDetailLocation
            });

            if (!('$$locations' in realEducationalProgrammeDetail)) {
              realEducationalProgrammeDetail.$$locations = [];
            }

            realEducationalProgrammeDetail.$$locations.push(realEducationalProgrammeDetailLocation);
          }

          if ($scope.navModel.activeTab === 0) {
            // create educationalProgrammeDetailsLocationsRelations
            // check if the relation exist

            const relation = {
              key: uuid.v4(),
              from: {
                href: '/sam/educationalprogrammedetails/locations/' + realEducationalProgrammeDetailLocation.key},
              to: {
                href: '/sam/educationalprogrammedetails/locations/' + officialEducationalProgrammeDetailLocation.key},
              startDate: startDate,
              endDate:
                dateUtils.getFirst([endDate,
                  realEducationalProgrammeDetailLocation.endDate])
            };

            errorManager.validatePeriod(relation);

            groupBatch.push({
              href: '/sam/educationalprogrammedetails/locations/relations/' + relation.key,
              verb: 'PUT',
              body: relation
            });
          }

          if (groupBatch.length > MAX_AGS_TO_BE_ADDED_AT_ONCE) {
            batch.push(groupBatch);
            groupBatch = [];
          }
        }

        if (groupBatch.length > 0) {
          batch.push(groupBatch);
        }

        return apiQueries.postBatch('/sam/educationalprogrammedetails/batch', batch);
      };

      const existingProgrammes = _.cloneDeep(school.$$educationalProgrammeDetails); //[];
      const existingMsRels = school.$$epdsForMainstructures;

      try {
        await doBatch(existingProgrammes, existingMsRels);
        $scope.close();
      } catch (fault) {
        $log.error(fault);
        if (!fault.body) {
          utils.notify($scope, 'danger', 'warning', 'Het opslaan van deze lange lijst van geselecteerde ag\'s duurt ' +
           'langer dan 30 sec. Mogelijks is deze actie niet volledig gelukt. Sluit dit venster af door op F5 ' +
           '(Refresh) te drukken en controleer of de lijst volledig is.');
        } else {
          utils.notify($scope, 'danger', 'warning', await errorManager.handleError(fault, batch));
        }
      }
    };

    const addEducationalProgrammeDetail = async () => {
      const batch = [],
        ouKey = school.key,
        model = $scope.model[1],
        startDate = $scope.startDate;

      addedAbolishedAgs = false;

      const doBatch = (existingProgrammes, existingMsRels) => {
        model.selectedItems.forEach(ag => {

          let realEducationalProgrammeDetail = _.find(existingProgrammes, ep =>
            ep.ag.$$expanded.key === ag.key &&
            (
              (!ep.buoType && !model.buoType) ||
              (ep.buoType && model.buoType && ep.buoType.href === model.buoType.$$meta.permalink)
            ) &&
            dateUtils.isOverlapping(ep,
              {startDate: startDate, endDate: null})
          );

          if (realEducationalProgrammeDetail) {
            if (!dateUtils.isCovering(realEducationalProgrammeDetail, {
              startDate: startDate
            })) {
              realEducationalProgrammeDetail = _.extend({}, realEducationalProgrammeDetail, {
                startDate: dateUtils.getFirst([startDate,
                  realEducationalProgrammeDetail.startDate])
              });

              batch.push({
                href: '/sam/educationalprogrammedetails/' + realEducationalProgrammeDetail.key,
                verb: 'PUT',
                body: realEducationalProgrammeDetail
              });
            }
          } else {
            let buoType = null;
            if (model.buoType) {
              buoType = { href: '/sam/commons/buotypes/' + model.buoType.key };
            } else if (ag.buoType) {
              buoType = ag.buoType;
            }
            realEducationalProgrammeDetail = {
              key: uuid.v4(),
              organisationalUnit: {href: '/sam/organisationalunits/' + ouKey},
              buoType,
              ag: {href: '/sam/commons/ags/' + ag.key, $$expanded: ag},
              startDate: startDate,
              endDate: school.endDate
            };

            errorManager.validatePeriod(realEducationalProgrammeDetail);

            batch.push({
              href: '/sam/educationalprogrammedetails/' + realEducationalProgrammeDetail.key,
              verb: 'PUT',
              body: realEducationalProgrammeDetail
            });
            existingProgrammes.push(realEducationalProgrammeDetail);
          }
          // denormalized direct relation with mainstructures needs to be kept in sync with indirect relations (epds pointing to ags and ag has a mainstructure)
          syncMsRelsWithEpds(existingProgrammes, existingMsRels, batch);

          let realEducationalProgrammeDetailLocation = {
            key: uuid.v4(),
            educationalProgrammeDetail: {href: '/sam/educationalprogrammedetails/' + realEducationalProgrammeDetail.key},
            physicalLocation: {href: location.physicalLocation.href},
            startDate: startDate
          };

          batch.push({
            href: '/sam/educationalprogrammedetails/locations/' + realEducationalProgrammeDetailLocation.key,
            verb: 'PUT',
            body: realEducationalProgrammeDetailLocation
          });
        });

        return apiQueries.postBatch('/sam/educationalprogrammedetails/batch', batch);
      };

      const existingProgrammes = [];

      // add first the educationalProgrammeDetails for that location
      for (const loc of school.$$locations) {
        if (!loc.$$educationalProgrammeDetailsLocations) {
          continue;
        }
        for (const epdl of loc.$$educationalProgrammeDetailsLocations) {
          if (loc.key === location.key) {
            existingProgrammes.unshift(epdl.educationalProgrammeDetail.$$expanded);
          } else {
            existingProgrammes.push(epdl.educationalProgrammeDetail.$$expanded);
          }
        }
      }

      for (const epd of school.$$educationalProgrammeDetails) {
        if (!epd.$$locations) {
          existingProgrammes.push(epd);
        }
      }

      try {
        await doBatch(existingProgrammes, school.$$epdsForMainstructures);
        $scope.close();
      } catch (fault) {
        $log.error(fault);
        if (!fault.body) {
          utils.notify($scope, 'danger', 'warning', 'Het opslaan van deze lange lijst van geselecteerde ag\'s duurt ' +
           'langer dan 30 sec. Mogelijks is deze actie niet volledig gelukt. Sluit dit venster af door op F5 ' +
           '(Refresh) te drukken en controleer of de lijst volledig is.');
        } else {
          utils.notify($scope, 'danger', 'warning', await errorManager.handleError(fault, existingProgrammes));
        }
      }
    };

    // selectable inputs for addresses
    $scope.refreshBuoType = async (buotype) => {
      const buotypes = await apiQueries.getResources('/sam/commons/buotypes', {
      }, {caching: {timeout: ONE_DAY}});

      return buotypes.length === 0 ? [{display: buotype}] :
        buotypes.map(b => {
          return Object.assign({display: 'Type ' + b.code}, b);
        });
    };

    $scope.onBuoTypeSelect = ($item) => {
      $scope.model[1].buoType = $item;
    };

    $scope.save = async () => {

      $scope.model.saving = true;
      if ($scope.navModel.activeTab === 0) {
        await addEducationalProgrammeDetailsLocationsRelations();
      } else {
        await addEducationalProgrammeDetail();
      }
      $scope.model.saving = false;
    };
  }
];
