module.exports = ['$scope', '$uibModalInstance', '$log', 'apiService', 'uuid',
  'utils', 'clusterInfo', 'dateUtils', 'errorManager',
  ($scope, $uibModalInstance, $log, apiService, uuid,
    utils, clusterInfo, dateUtils, errorManager) => {
    'use strict';

    let existingParentships = clusterInfo.schoolEntities.map(schoolEntity => schoolEntity.$$activeParent)
      .filter(parentRelation => parentRelation);

    $scope.model = {
      startDate: dateUtils.parse(dateUtils.getLast([
        utils.getFirstOfSeptemberOfCurrentYear(),
        ... existingParentships.map(parentship =>
          dateUtils.getNextDay(dateUtils.getNextDay(parentship.startDate)))
      ])),
      samePreviousCluster: true,
      conflictingClusterMovements: [],
      clusterMovements: [],
      warn: false
    };

    $scope.cancel = () => $uibModalInstance.close(false);
    $scope.close = () => $uibModalInstance.close();

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.validate = () => {
      $scope.notificationOptions.enabled = false;
      const startDate = dateUtils.toString($scope.model.startDate);

      $scope.model.cluster = {
        key: uuid.v4(),
        type: 'SCHOOLENTITY_CLUSTER',
        names: [{
          type: 'OFFICIAL',
          value: $scope.model.clusterName,
          startDate: startDate
        }],
        startDate: startDate
      };

      $scope.model.clusterRelationToUpdate = utils.getClusterMovementsRequiered(clusterInfo.schoolEntities, startDate);

      if ($scope.model.clusterRelationToUpdate.length === 0) {
        $scope.save();
        return;
      }

      const conflictingClusters =
        utils.getConflictingClusters($scope.model.clusterRelationToUpdate, startDate, $scope.model.cluster);

      const notification = errorManager.getClusteringErrorMessage(clusterInfo.schoolEntities,
        $scope.model.clusterRelationToUpdate,
        conflictingClusters);

      utils.notify($scope, ... notification);

      if ($scope.model.conflictingClusterMovements.length === 0) {
        $scope.model.warn = true;
      }
    };

    $scope.save = async (deleteConflicting = false) => {
      $scope.notificationOptions.enabled = false;
      const startDate = dateUtils.toString($scope.model.startDate);

      const batch = apiService.createBatch();

      batch.put('/sam/organisationalunits/' + $scope.model.cluster.key, $scope.model.cluster);

      clusterInfo.schoolEntities.forEach(schoolEntity => {
        utils.moveToCluster(
          batch,
          schoolEntity,
          $scope.model.cluster,
          startDate);
      });

      try {

        const previousClusters = clusterInfo.schoolEntities.map(
          schoolEntity => {
            const activeParent = utils.getActiveParent(schoolEntity, startDate);
            return activeParent ? activeParent.to.$$expanded : null;
          });

        const samePreviousCluster =
          previousClusters.every(previousCluster => previousCluster &&
            previousClusters[0] && previousCluster.key === previousClusters[0].key);

        if (samePreviousCluster) {
          if (deleteConflicting) {
            $scope.model.clusterRelationToUpdate.forEach(clusterMovement =>
              batch.delete(clusterMovement.$$meta.permalink)
            );
          }

          utils.moveToCluster(
            batch,
            $scope.model.cluster,
            previousClusters[0],
            startDate);
        }
        await batch.send('/sam/organisationalunits/batch');
        $scope.close();
      } catch (error) {
        $log.error(String(error));
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch.array));
      }
    };
  }
];
