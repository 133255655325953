/* eslint-disable no-undef */
module.exports = ['$scope', '$uibModalInstance', '$log', 'apiQueries', 'apiService',
  'location', 'errorManager', 'utils',
  ($scope, $uibModalInstance, $log, apiQueries, apiService, location, errorManager, utils) => {
    'use strict';

    const classUtils = apiService.getClassUtils();

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => $uibModalInstance.close(false);

    $scope.close = () => $uibModalInstance.close();

    // init default data for new physicalLocation
    $scope.initPl = async () => {
      $scope.model = angular.copy(location.physicalLocation.$$expanded);

      try {
        // get the list of school entities and institutions affected by this physicalLocation updates
        let organisationalUnitLocations = await apiQueries.getOrganisationalUnitsLocations({
          physicalLocation: '/sam/physicallocations/' + $scope.model.key,
          'typeIn': 'CAMPUS,VESTIGINGSPLAATS,SEAT'
        }, {
          expand: 'organisationalUnit'
        });

        $scope.$applyAsync(() => {
          organisationalUnitLocations = organisationalUnitLocations.filter(loc => loc.key !== location.key);
          $scope.affectedSchoolEntities =
            Array.from(new Set(organisationalUnitLocations.map(loc => loc.organisationalUnit.$$expanded.$$displayName + ' [' + loc.organisationalUnit.$$expanded.type + ']')));
        });
      } catch (error) {
        $log.error(error);
      }
    };

    $scope.forms = {
      plEdition: {}
    };

    $scope.isValidPl = () => $scope.forms.plEdition.$valid;

    $scope.save = async () => {
      try {
        $scope.model.loading = true;

        const pl = _.pickBy($scope.model);

        await utils.adjustAddress(pl.address);

        await apiQueries.postBatch('/batch', [{
          href: $scope.model.$$meta.permalink,
          verb: 'PUT',
          body: pl
        }]);
        $log.info('physicalLocation updated!');
        $scope.close();
      } catch (error1) {

        if (error1 instanceof apiService.SriClientError) {
          $log.error('Can\'t update physical location.', error1);

          let errorArray = error1.body.filter(body => body.status === 409);
          errorArray = errorArray.map(body => body.body.errors);
          errorArray = _.flatten(errorArray);
          const duplicateAddress = errorArray.find(e => e.code === 'duplicate.address');

          if (duplicateAddress && duplicateAddress.hrefs && duplicateAddress.hrefs.length > 0) {
            $log.error(duplicateAddress.hrefs[0]);

            const newPhysicalLocationHref = duplicateAddress.hrefs[0];
            const oldPhysicalLocationHref = location.physicalLocation.href;

            let schoolEntityBatch = [];

            // update location
            schoolEntityBatch.push({
              href: location.$$meta.permalink,
              verb: 'PUT',
              body: _.assignIn(location, {
                physicalLocation: {
                  href: newPhysicalLocationHref
                }
              })
            });

            // update all related /educationalprogrammedetails/locations
            for (let epdl of location.$$educationalProgrammeDetailsLocations) {
              schoolEntityBatch.push({
                href: epdl.$$meta.permalink,
                verb: 'PUT',
                body: _.assignIn(epdl, {
                  physicalLocation: {
                    href: newPhysicalLocationHref
                  }
                })
              });
            }

            // let clazzBatch = [];

            const relatedClassLocations = await classUtils.getClassLocationsAtCampus({ ...location, physicalLocation: { href: oldPhysicalLocationHref } });

            for (let relatedClazz of relatedClassLocations) {
              schoolEntityBatch.push({
                href: relatedClazz.$$meta.permalink,
                verb: 'PUT',
                body: _.assignIn(relatedClazz, {
                  physicalLocation: {
                    href: newPhysicalLocationHref
                  }
                })
              });
            }

            // clazzBatch = _.uniqBy(clazzBatch, 'href');

            try {
              await apiQueries.postBatch('/batch', schoolEntityBatch);// [schoolEntityBatch, clazzBatch]
              $log.info('physicalLocation updated!');
              $scope.close();
            } catch (error2) {
              $log.error('Can\'t update physical location.', error2);
              utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error2, schoolEntityBatch));
            }
          } else {
            utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error1));
          }
        }
      } finally {
        $scope.model.loading = false;
      }
    };
  }
];
