const contactDetails = angular.module('contactDetails', []);

contactDetails.directive('contactDetails', ['contactDetailUtils', (contactDetailUtils) => {
  'use strict';
  return {
    restrict: 'E',
    template: require('./contactDetails.html'),
    transclude: true,
    scope: {
      model: '=ngModel',
      location: '=location',
      addContact: '=addContactFunction'
    },
    link: ($scope) => {

      const empty = '';

      $scope.addEmail = () => {
        $scope.addContact($scope.location, 'EMAIL', $scope.model.email);
        $scope.model.email = empty;
      };

      $scope.removeEmail = (key) => {
        let index = $scope.model.emails.findIndex(email => email.key === key);

        if (index > -1) {
          $scope.model.emails.splice(index, 1);
        }
      };

      $scope.$watch('model.phone', (newPhone, oldPhone) => {
        if (newPhone && newPhone !== oldPhone) {
          try {
            contactDetailUtils.formatPhoneNumber(newPhone, true);
            $scope.phoneForm.$invalid = false;
          } catch (error) {
            $scope.phoneForm.$invalid = true;
          }
        }
      });

      $scope.addPhone = () => {
        $scope.addContact($scope.location, 'PHONE', contactDetailUtils.formatPhoneNumber($scope.model.phone, false));
        $scope.model.phone = empty;
      };

      $scope.removePhone = (key) => {
        let index = $scope.model.phones.findIndex(phone => phone.key === key);

        if (index > -1) {
          $scope.model.phones.splice(index, 1);
        }
      };

      $scope.addWebsite = () => {
        $scope.addContact($scope.location, 'WEBSITE', $scope.model.website);
        $scope.model.website = empty;
      };

      $scope.removeWebsite = (key) => {
        let index = $scope.model.websites.findIndex(website => website.key === key);

        if (index > -1) {
          $scope.model.websites.splice(index, 1);
        }
      };
    }
  };
}]);
