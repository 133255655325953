const sleep = ms => new Promise(r => setTimeout(r, ms));

module.exports = ['$scope', '$uibModalInstance', 'utils', '$log', 'apiQueries', 'uuid', 'school', 'errorManager', 'contactDetailUtils',
  async ($scope, $uibModalInstance, utils, $log, apiQueries, uuid, school, errorManager, contactDetailUtils) => {
    'use strict';

    let emails = {},
      phones = {},
      websites = {};

    $scope.school = school;

    $scope.model = {
      lastContactDetailPriority: 0,
      contactDetails: {}
    };

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.cancel = () => {
      if ($scope.notificationOptions.enabled) {
        $scope.notificationOptions.enabled = false;
        $scope.warning = false;
      } else {
        $uibModalInstance.close(false);
      }
    };

    $scope.close = () => $uibModalInstance.close();

    const getContactList = (key, type) => {
      const contactLists = $scope.model.contactDetails[key];

      switch (type) {
      case 'EMAIL':
        return contactLists.emails;
      case 'PHONE':
        return contactLists.phones;
      default:
        return contactLists.websites;
      }
    };

    $scope.addContact = (key, type, value) => {

      const contactList = getContactList(key, type);
      if (!contactList.find(contact => contact.value[type.toLowerCase()] === value)) {
        $scope.model.lastContactDetailPriority += 1;

        let contact = {
          key: uuid.v4(),
          organisationalUnit: {href: '/sam/organisationalunits/' + school.key},
          type: type,
          value: {},
          priority: $scope.model.lastContactDetailPriority
        };

        contact.value[type.toLowerCase()] = type === 'PHONE' ? contactDetailUtils.formatPhoneNumber(value) : value;
        if (key !== school.key) {
          contact.physicalLocation = {href: '/sam/physicallocations/' + key};
        }

        contactList.push(contact);
      }
    };

    const getContactDetails = async () => {
      return apiQueries.getOrganisationalUnitsContactDetails({
        organisationalUnit: '/sam/organisationalunits/' + school.key,
        orderBy: 'priority',
        typeIn: 'EMAIL,PHONE,WEBSITE'
      });
    };

    const initContactDetails = () => {

      $scope.model.contactDetails[school.key] = {
        emails: [],
        phones: [],
        websites: []
      };

      emails[school.key] = [];
      phones[school.key] = [];
      websites[school.key] = [];

      for (let location of school.$$locations) {
        $scope.model.contactDetails[location.physicalLocation.$$expanded.key] = {
          emails: [],
          phones: [],
          websites: []
        };
        emails[location.physicalLocation.$$expanded.key] = [];
        phones[location.physicalLocation.$$expanded.key] = [];
        websites[location.physicalLocation.$$expanded.key] = [];
      }
    };

    $scope.loadContactDetails = async () => {
      let cds = await getContactDetails();

      console.log(cds);
      let count = 1;
      while (count <= 3 && school.$$locations.length === 0) {
        // hacky solution for bad code written in the firstplace, but $$locations might not be loaded yet
        // so I'm giving it here some more time to make sure they are loaded
        // because I don't have the promise available here
        console.log(`The locations are not loaded (yet?). We will try to wait ${count} seconds to see if they are still coming...`);
        await sleep(count * 1000);
        count++;
      }

      initContactDetails();

      if (cds) {
        cds.forEach(cd => {

          const key = cd.physicalLocation ?
            cd.physicalLocation.href.split('/').pop() : cd.organisationalUnit.href.split('/').pop();

          if ($scope.model.contactDetails[key]) {
            if (cd.type === 'EMAIL') {
              $scope.model.contactDetails[key].emails.push(cd);
              emails[key].push(cd);
            }
            if (cd.type === 'PHONE') {
              $scope.model.contactDetails[key].phones.push(cd);
              phones[key].push(cd);
            }
            if (cd.type === 'WEBSITE') {
              $scope.model.contactDetails[key].websites.push(cd);
              websites[key].push(cd);
            }
  
            if (cd.physicalLocation && school.$$locations.length === 1) {
              $scope.model.plIsExpanded[key] = true;
            }
          }

          if ($scope.model.lastContactDetailPriority < cd.priority) {
            $scope.model.lastContactDetailPriority = cd.priority;
          }
        });
      }
    };

    const contactListChanged = (oldList, newList) =>
      oldList.map(contact => contact.value.email || contact.value.phone || contact.value.website).join() !==
      newList.map(contact => contact.value.email || contact.value.phone || contact.value.website).join();

    const updateBatch = (batch, oldCDs, newCDs) => {
      if (contactListChanged(oldCDs, newCDs)) {
        let deleteOnes = oldCDs.filter(x => !newCDs.includes(x));

        if (deleteOnes.length > 0) {
          deleteOnes.forEach(cd =>
            batch.push({
              href: '/sam/organisationalunits/contactdetails/' + cd.key,
              verb: 'DELETE'
            })
          );
        }

        if (newCDs.length > 0) {
          const sortedPriorities = newCDs.map(newCD => newCD.priority).sort((a, b) => a - b);
          newCDs.forEach((cd, newPos) => {
              cd.priority = sortedPriorities[newPos];

              batch.push({
                href: '/sam/organisationalunits/contactdetails/' + cd.key,
                verb: 'PUT',
                body: cd
              });
          });
        }
      }
    };

    $scope.save = async () => {
      const batch = [];

      Object.keys($scope.model.contactDetails).forEach(key => {
        const contactLists = $scope.model.contactDetails[key];
        updateBatch(batch, emails[key], contactLists.emails, 'EMAIL');
        updateBatch(batch, phones[key], contactLists.phones, 'PHONE');
        updateBatch(batch, websites[key], contactLists.websites, 'WEBSITE');
      });

      try {
        await apiQueries.postBatch('/sam/organisationalunits/contactdetails/batch', batch);
        $scope.close();
      } catch (error) {
        $log.error(error);
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch));
      }
    };

    $scope.enableSave = () => {
      return Object.keys($scope.model.contactDetails).some(key => {
        return contactListChanged(emails[key], $scope.model.contactDetails[key].emails) ||
        contactListChanged(phones[key], $scope.model.contactDetails[key].phones) ||
        contactListChanged(websites[key], $scope.model.contactDetails[key].websites);
      });
    };
  }
];
