module.exports = ['utils', utils => {
  'use strict';
  return (resource, noFinishEarlierThan) => {
    // if no deadline for endDate is set then no filter applied
    if (!noFinishEarlierThan) {
      return false;
    }

    return utils.abolished(resource, noFinishEarlierThan);
  };
}];
