module.exports = ['$translateProvider', function ($translateProvider) {
  /*$translateProvider.translations('en', {
    TITLE: 'Hello',
    FOO: 'This is a paragraph'
  });

  $translateProvider.translations('de', {
    TITLE: 'Hallo',
    FOO: 'Dies ist ein Absatz'
  });

  $translateProvider.preferredLanguage('en');
  $translateProvider.useSanitizeValueStrategy('sanitize');
  $translateProvider.preferredLanguage('de');*/

  $translateProvider.useStaticFilesLoader({
    prefix: '/i18n/',
    suffix: '.json'
  });
  $translateProvider.useSanitizeValueStrategy(null);
  $translateProvider.preferredLanguage('nl-be');
}];
