const { create } = require("lodash");
const { log } = require("logrocket");

module.exports = ['$scope', '$uibModalInstance', '$log', 'apiQueries', '$timeout',
  'uuid', 'settings', 'utils', 'errorManager', 'addressUtils', 'contactDetailUtils', 'dateUtils',
  ($scope, $uibModalInstance, $log, apiQueries, $timeout,
    uuid, settings, utils, errorManager, addressUtils, contactDetailUtils, dateUtils) => {

    'use strict';

    $scope.warning = false;

    $scope.newSchoolEntityKey = uuid.v4();

    $scope.physicalLocationModel = {
      activeTab: 0,
      itemsPerPage: settings.paginationSettings.itemsPerModal,
      nextPage: 1,
      totalItems: 1,
      noMore: false,
      searchValues: {
        name: '',
        street: '',
        zipCode: '',
        city: '',
        onderwijskiezer: ''
      },
      singleSelect: true,
      selectedItem: {value: null},
      locations: [],
      newCampus: {},
      campusForm: {},
      statusMessage: 'Loading data. Please wait...'
    };

    $scope.governingModel = {
      itemsPerPage: settings.paginationSettings.itemsPerModal,
      currentPage: 1,
      totalItems: 1,
      selectedItem: {value: null},
      governings: [],
      searchValues: '',
      statusMessage: 'Loading data. Please wait...'
    };

    $scope.contactDetails = {
      lastContactDetailPriority: 0,
      emails: [],
      phones: [],
      websites: []
    };

    let filterTextTimeout;

    $scope.$watch('physicalLocationModel.activeTab', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        $scope.physicalLocationModel.selectedItem.value = null;
      }
    });

    $scope.$watch('governingModel.searchValue', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        if (filterTextTimeout) {
          $timeout.cancel(filterTextTimeout);
        }

        $scope.governingModel.searchValues = newValue;
        filterTextTimeout = $timeout(() => {
          $scope.governingModel.selectedItem = {value: null};
          $scope.getGovernings();
        }, 250); // delay 250 ms
      }
    });

    const getContactList = (type) => {
      switch (type) {
      case 'EMAIL':
        return $scope.contactDetails.emails;
      case 'PHONE':
        return $scope.contactDetails.phones;
      default:
        return $scope.contactDetails.websites;
      }
    };

    $scope.addContact = (key, type, value) => {

      const contactList = getContactList(type);
      if (!contactList.find(contact => contact.value[type.toLowerCase()] === value)) {
        $scope.contactDetails.lastContactDetailPriority += 1;

        let contact = {
          key: uuid.v4(),
          organisationalUnit: {href: '/sam/organisationalunits/' + key},
          type: type,
          value: {},
          priority: $scope.contactDetails.lastContactDetailPriority
        };

        contact.value[type.toLowerCase()] = type === 'PHONE' ? contactDetailUtils.formatPhoneNumber(value) : value;

        contactList.push(contact);
      }
    };

    $scope.model = {
      step: 0,
      contactDetails: {
        emails: [],
        phones: [],
        websites: []
      }
    };

    $scope.schoolModel = {
      name: '',
      externalIds: '',
      startDate: new Date(new Date().getUTCFullYear(), 8, 1)
    };

    $scope.cancel = () => {
      if ($scope.notificationOptions.enabled) {
        $scope.notificationOptions.enabled = false;
        $scope.warning = false;
      } else {
        $uibModalInstance.close(false);
      }
    };

    $scope.close = (schoolKey) => $uibModalInstance.close(schoolKey);

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.proceed = () => {
      if (!$scope.warning) {
        $scope.model.step++;
      }
      $scope.notificationOptions.enabled = false;
      $scope.warning = false;
    };

    $scope.getGovernings = async () => {
      $scope.governingModel.selectedItem.value = null;
      let params = {
        limit: $scope.governingModel.itemsPerPage,
        offset: ($scope.governingModel.currentPage - 1) * $scope.governingModel.itemsPerPage,
        type: 'GOVERNINGINSTITUTION',
        status: 'ACTIVE,FUTURE'
      };

      if ($scope.governingModel.searchValues) {
        params['names.value'] = $scope.governingModel.searchValues;
      }

      try {
        const governingInstitutions = await apiQueries.getOrganisationalUnits(params);

        $scope.$applyAsync(() => {
          $scope.governingModel.totalItems = governingInstitutions.length;
          $scope.governingModel.governings = governingInstitutions;

          $scope.governingModel.statusMessage = governingInstitutions.length === 0 ? 'No results found.' : 'DONE';
        });

      } catch (error) {
        $log.error('Can\'t get governings.');
        $log.error(error);
      }
    };

    $scope.getFilteredLocations = async () => {
      $scope.physicalLocationModel.selectedItem.value = null;
      $scope.physicalLocationModel.nextPage = 1;
      $scope.physicalLocationModel.locations = [];
      $scope.getMoreLocations();
    };

    $scope.getMoreLocations = async () => {

      const limit = $scope.physicalLocationModel.itemsPerPage,
        offset = ($scope.physicalLocationModel.nextPage - 1) * $scope.physicalLocationModel.itemsPerPage;

      let referenceDate = dateUtils.getNow(),
        locationKey,
        seKeys = [],
        physicalLocations = [],
        addressFilter = _.pickBy(_.pick($scope.physicalLocationModel.searchValues, ['street', 'zipCode', 'city']));

      try {

        if ($scope.physicalLocationModel.searchValues.name || $scope.physicalLocationModel.searchValues.onderwijsniveau) {
          seKeys = await apiQueries.filterSchoolsByNameOrEducationalLevel(['SCHOOL'], 
            $scope.physicalLocationModel.searchValues, 
            referenceDate);

          if (seKeys.length === 0) {
            $scope.$applyAsync(() => {
              $scope.physicalLocationModel.locations = [];
              $scope.physicalLocationModel.statusMessage = 'No results found.';
              $scope.physicalLocationModel.noMore = true;
            });
            return;
          }
        }

        if ($scope.physicalLocationModel.searchValues.onderwijskiezer) {
          locationKey = await apiQueries.filterLocationsByExternalIdentifiers($scope.physicalLocationModel.searchValues.onderwijskiezer);
        }

        if (Object.keys(addressFilter).length > 0) {
          physicalLocations = await apiQueries.filterLocationsByAddress('DOMAIN',
            addressFilter,
            $scope.physicalLocationModel.searchValues);

          if (physicalLocations.length === 0) {
            $scope.$applyAsync(() => {
              $scope.physicalLocationModel.locations = [];
              $scope.physicalLocationModel.statusMessage = 'No results found.';
              $scope.physicalLocationModel.noMore = true;
            });

            return;
          }
        }

        let locations = await apiQueries.filterLocations('SCHOOL',
          seKeys, physicalLocations,
          locationKey,
          addressFilter,
          referenceDate);

        locations = _.uniqBy(locations, 'href');

        $scope.physicalLocationModel.totalItems = locations.length;

        locations = locations.slice(offset, offset + limit);

        const locationHrefs = locations.map(location => location.href);
        locations = await apiQueries.getAllHrefs(locationHrefs, '/sam/organisationalunits/locations/batch', {
          expand: 'results.organisationalUnit,results.physicalLocation'
        });

        locations = locations.map(location => {
          location.organisationalUnit = location.$$expanded.organisationalUnit.$$expanded;
          location.physicalLocation = location.$$expanded.physicalLocation.$$expanded;
          return location;
        });

        $scope.$applyAsync(() => {
          $scope.physicalLocationModel.locations = $scope.physicalLocationModel.locations.concat(locations);
          $scope.physicalLocationModel.statusMessage = $scope.physicalLocationModel.locations.length === 0
            ? 'No results found.' : 'DONE';
          $scope.physicalLocationModel.noMore = locations.length < limit || locations.length === 0;
          $scope.physicalLocationModel.nextPage++;
        });
      } catch (error) {
        $log.error('Can\'t get physical locations.');
        $log.error(error);
      }
    };

    const addContactDetails = (batch, contactList) => {

      const sortedPriorities = contactList.map(newCD => newCD.priority).sort((a, b) => a - b);
      contactList.forEach((cd, pos) => {
        cd.priority = sortedPriorities[pos];

        batch.push({
          href: '/sam/organisationalunits/contactdetails/' + cd.key,
          verb: 'PUT',
          body: cd
        });
      });
    };

    let governingInstitution;

    const doSchoolEntityBatch = async (physicalLocation) => {
      const batch = [],
        startDate = utils.dateToIso($scope.schoolModel.startDate);

      // create a school entity
      const newSchoolEntity = {
        key: $scope.newSchoolEntityKey,
        type: 'SCHOOLENTITY',
        names: [{
          type: 'OFFICIAL',
          value: $scope.schoolModel.name,
          startDate: startDate
        }],
        startDate: startDate
      };

      batch.push({
        href: '/sam/organisationalunits/' + newSchoolEntity.key,
        verb: 'PUT',
        body: newSchoolEntity
      });

      // link campus to school entity
      const newLocation = {
        key: uuid.v4(),
        organisationalUnit: {href: '/sam/organisationalunits/' + newSchoolEntity.key},
        physicalLocation: {
          href: `/sam/physicallocations/${physicalLocation.key}`
        },
        name: $scope.physicalLocationModel.newCampus.name,
        startDate: startDate,
        type: 'CAMPUS'
      };

      batch.push({
        href: '/sam/organisationalunits/locations/' + newLocation.key,
        verb: 'PUT',
        body: newLocation
      });

      // create an external indentifiers
      if ($scope.schoolModel.externalIds) {
        $scope.schoolModel.externalIds.split(',').forEach((externalId) => {
          const externalIdentifierKey = uuid.v4();
          batch.push({
            href: '/sam/organisationalunits/locations/externalidentifiers/' + externalIdentifierKey,
            verb: 'PUT',
            body: {
              key: externalIdentifierKey,
              type: 'ONDERWIJSKIEZER_ID',
              value: parseInt(externalId, 10),
              location: {href: '/sam/organisationalunits/locations/' + newLocation.key}
            }
          });
        });
      }

      // link governing institution of physicallocation to school entity
      if (governingInstitution) {
        const newOrganisationunitrelationKey = uuid.v4();
        const governingRelation = {
          href: '/sam/organisationalunits/relations/' + newOrganisationunitrelationKey,
          verb: 'PUT',
          body: {
            key: newOrganisationunitrelationKey,
            from: {href: '/sam/organisationalunits/' + governingInstitution.key},
            to: {href: '/sam/organisationalunits/' + newSchoolEntity.key},
            type: 'GOVERNS',
            startDate: startDate
          }
        };

        batch.push(governingRelation);
      }

      addContactDetails(batch, $scope.contactDetails.emails);
      addContactDetails(batch, $scope.contactDetails.phones);
      addContactDetails(batch, $scope.contactDetails.websites);

      await apiQueries.postBatch('/sam/organisationalunits/batch', batch);
      $scope.close($scope.newSchoolEntityKey);
    };

    $scope.addSchoolEntity = async (physicalLocation, school, governing) => {
      if ($scope.warning) {
        await doSchoolEntityBatch(physicalLocation);
      } else {
        try {
          governingInstitution = governing;

          if (!governing && school) {
            const governingInstitutionRelations = await apiQueries.getOrganisationalUnitsRelations({
              to: '/sam/organisationalunits/' + school.key,
              type: 'GOVERNS',
              status: 'ACTIVE,FUTURE'
            }, {
              expand: 'from'
            });

            governingInstitution =
              governingInstitutionRelations.length > 0 ? governingInstitutionRelations[0].from.$$expanded : undefined;
          }

          if (!governingInstitution) {
            $scope.warning = true;
            utils.notify($scope, 'warning', 'warning', 'Het bestuur is ongekend. Selecteer \'Bevestigen\' om de ' +
            'schoolentiteit zonder relatie met een bestuur toch aan te maken.');
          } else {
            await doSchoolEntityBatch(physicalLocation);
          }

        } catch (fault) {
          console.error(fault);
          utils.notify($scope, 'danger', 'warning', await errorManager.handleError(fault));
        }
      }
    };

    $scope.addSchoolWithLosseLocation = async (pendingPhysicalLocationWithoutKey, governing) => {
      await utils.adjustAddress(pendingPhysicalLocationWithoutKey.address);
      if (governing || $scope.warning) {
        const existingPhysicalLocation = await utils.findExistingPhysicalLocation(pendingPhysicalLocationWithoutKey.address);
        governingInstitution = governing;
        await doSchoolEntityBatch(existingPhysicalLocation || await utils.createPhysicalLocation(pendingPhysicalLocationWithoutKey.address));
      } else {
        $scope.warning = true;
        utils.notify($scope, 'warning', 'warning', 'Het bestuur is ongekend. Selecteer \'Bevestigen\' om de ' +
        'schoolentiteit zonder relatie met een bestuur toch aan te maken.');
      }
    };
  }
];