const fetchOauthInterceptor = require('@kathondvla/fetch-oauth-interceptor');

module.exports = ['settings', 'dateUtils', 'epdUtils',
  (settings, dateUtils, epdUtils) => {
    'use strict';

    const ONE_DAY = 60 * 60 * 24;

    let that = {};

    fetchOauthInterceptor.interceptFactory({
      urlPatterns: [new RegExp(settings.apisAndUrls.samenscholingApi, 'g')],
      oauth: settings.oauth,
      onUserChanged: () => {
        location.reload();
      }
    });

    let api = require('@kathondvla/sri-client/node-sri-client')({
      baseUrl: settings.apisAndUrls.samenscholingApi,
      logging: false,
      caching: {
        initialise: [{
          timeout: ONE_DAY,
          hrefs: [
            '/sam/commons/buotypes',
            '/sam/commons/mainstructures',
            '/sam/commons/ags'
          ]
        }]
      }
    });

    const Batch = require('@kathondvla/sri-client/batch');

    let dateManager = require('@kathondvla/sam-utils/date-manager')(
      api, dateUtils
    );

    let classUtils = require('@kathondvla/sam-utils/class-utils')(
      api, dateUtils
    );

    let relationGetters = require('@kathondvla/sam-utils/relation-getters')(
      api, epdUtils
    );

    that.SriClientError = require('@kathondvla/sri-client/sri-client-error');

    that.createBatch = () => new Batch(api);

    that.getApi = () => api;

    that.getDateManager = () => dateManager;

    that.getClassUtils = () => classUtils;

    that.getRelationGetters = () => relationGetters;

    return that;
  }
];
