module.exports = ['$scope', '$uibModalInstance', '$log', 'apiQueries', 'apiService',
  'utils', 'clusterInfo', 'dateUtils', 'errorManager',
  ($scope, $uibModalInstance, $log, apiQueries, apiService,
    utils, clusterInfo, dateUtils, errorManager) => {
    'use strict';

    let existingParentships = clusterInfo.schoolEntities.map(schoolEntity => schoolEntity.$$activeParent)
      .filter(parentRelation => parentRelation);

    $scope.model = {
      startDate: dateUtils.parse(dateUtils.getLast([
        utils.getFirstOfSeptemberOfCurrentYear(),
        ... existingParentships.map(parentship =>
          dateUtils.getNextDay(dateUtils.getNextDay(parentship.startDate)),
        ... clusterInfo.schoolEntities.map(schoolEntity => schoolEntity.startDate))
      ])),
      clusters: [],
      conflictingClusterMovements: [],
      clusterMovements: [],
      warn: false
    };

    $scope.cancel = () => $uibModalInstance.close(false);
    $scope.close = () => $uibModalInstance.close();

    $scope.notificationOptions = {
      enabled: false
    };

    $scope.refreshClusters = async (clusterName) => {

      $scope.model.isLoading = true;
      $scope.notificationOptions.enabled = false;

      const schoolEntities = await apiQueries.getOrganisationalUnits({
        type: 'SCHOOLENTITY_CLUSTER',
        'names.value': clusterName,
        endDateAfter: utils.dateToIso($scope.model.startDate)
      });

      let clusters = [];

      if (schoolEntities.length > 0) {
        const schoolEntityHrefs = schoolEntities.map(cluster => cluster.$$meta.permalink);

        const clusterRelations = await apiQueries.getOrganisationalUnitsRelations({
          to: schoolEntityHrefs.join(','),
          endDateAfter: utils.dateToIso($scope.model.startDate),
          type: 'IS_PART_OF',
          'from.type': 'SCHOOLENTITY'
        }, {
          inBatch: '/sam/organisationalunits/relations/batch'
        });

        if (clusterRelations.length > 0) {
          clusters = schoolEntities.filter(schoolEntity =>
            clusterRelations.find(clusterRelation => clusterRelation.to.href === schoolEntity.$$meta.permalink));
          const clusterHrefs = clusters.map(cluster => cluster.$$meta.permalink);

          const clusterLocations = await apiQueries.getOrganisationalUnitsLocations({
            organisationalUnit: clusterHrefs.join(','),
            endDateAfter: dateUtils.toString($scope.model.startDate),
            expand: 'results.physicalLocation'
          }, {
            inBatch: '/sam/organisationalunits/locations/batch'
          });

          const clusterChildren = await apiQueries.getOrganisationalUnitsRelations({
            to: clusterHrefs.join(','),
            endDateAfter: utils.dateToIso($scope.model.startDate),
            type: 'IS_PART_OF',
            'from.type': 'SCHOOLENTITY'
          }, {
            inBatch: '/sam/organisationalunits/relations/batch'
          });

          let childLocations = [];

          if (clusterChildren.length > 0) {
            childLocations = await apiQueries.getOrganisationalUnitsLocations({
              organisationalUnit: clusterChildren.map(child => child.from.href).join(','),
              endDateAfter: dateUtils.toString($scope.model.startDate),
              expand: 'results.physicalLocation'
            }, {
              inBatch: '/sam/organisationalunits/locations/batch'
            });
          }

          clusters.forEach(cluster => {
            const clusterCityLocations = _.filter(clusterLocations, clusterLocation =>
              clusterLocation.organisationalUnit.href === cluster.$$meta.permalink).
              map(clusterLocation => clusterLocation.physicalLocation.$$expanded.address.city);

            const clusterChildHrefs = _.filter(clusterChildren, clusterChild =>
              clusterChild.to.href === cluster.$$meta.permalink).map(clusterChild => clusterChild.from.href);

            const childCityLocations = [];

            for (const clusterChildHref of clusterChildHrefs) {
              childCityLocations.push(_.filter(childLocations, childLocation =>
                childLocation.organisationalUnit.href === clusterChildHref).
                map(childLocation => childLocation.physicalLocation.$$expanded.address.city));
            }

            cluster.$$linkedLocationCities = [
              ... new Set([... new Set(clusterCityLocations), ... new Set(childCityLocations.flat())])
            ];

            cluster.$$linkedLocationCities = cluster.$$linkedLocationCities.join('-');
          });
        }
      }

      $scope.model.isLoading = false;

      if (clusters.length === 0) {
        utils.notify($scope, 'danger', 'danger',
          'Er is geen cluster die overeenkomt met de opgegeven naam. ' +
          'Corrigeer de naam of maak een nieuwe cluster via de knop \'Groeperen\'.');
      }
      return clusters;
    };

    $scope.onClusterSelect = async (selectedCluster) => {
      $scope.$applyAsync(() => {
        $scope.model.cluster = selectedCluster;
        $scope.model.startDate = dateUtils.parse(dateUtils.getLast([selectedCluster.startDate,
          ... clusterInfo.schoolEntities.map(schoolEntity => schoolEntity.startDate)]));
      });
    };

    $scope.validate = () => {
      $scope.notificationOptions.enabled = false;
      const startDate = dateUtils.toString($scope.model.startDate);
      $scope.model.clusterRelationToUpdate = utils.getClusterMovementsRequiered(clusterInfo.schoolEntities, startDate);

      if ($scope.model.clusterRelationToUpdate.length === 0) {
        $scope.save();
        return;
      }

      const conflictingClusters =
        utils.getConflictingClusters($scope.model.clusterRelationToUpdate, startDate, $scope.model.cluster);

      const notification = errorManager.getClusteringErrorMessage(clusterInfo.schoolEntities,
        $scope.model.clusterRelationToUpdate,
        conflictingClusters);

      utils.notify($scope, ... notification);

      if (conflictingClusters.length === 0) {
        $scope.model.warn = true;
      }
    };

    $scope.save = async (deleteConflicting = false) => {
      $scope.notificationOptions.enabled = false;
      const startDate = dateUtils.toString($scope.model.startDate);

      const batch = apiService.createBatch();

      try {
        clusterInfo.schoolEntities.forEach(schoolEntity => {
          utils.moveToCluster(
            batch,
            schoolEntity,
            $scope.model.cluster,
            startDate);
        });

        if (deleteConflicting) {
          $scope.model.clusterRelationToUpdate.forEach(clusterMovement =>
            batch.delete(clusterMovement.$$meta.permalink)
          );
        }

        await batch.send('/sam/organisationalunits/batch');
        $scope.close();
      } catch (error) {
        $log.error(String(error));
        utils.notify($scope, 'danger', 'warning', await errorManager.handleError(error, batch.array));
      }
    };
  }
];
