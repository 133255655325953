const pager = angular.module('pager', []);

pager.directive('pager', [() => {
  'use strict';
  return {
    restrict: 'E',
    template: require('./pager.html'),
    transclude: true,
    scope: {
      model: '=ngModel',
      loadFunction: '='
    },
    link: ($scope) => {
      $scope.previous = async () => {
        $scope.model.currentPage--;
        $scope.loadFunction.call();
      };
      $scope.next = async () => {
        $scope.model.currentPage++;
        $scope.loadFunction.call();
      };
    }
  };
}]);
